import React, {memo} from "react"
import "./index.less"

const VipSwiperItem = ({children, boldText, thinText}) => {
    return (
        <div className="suit-vip-swiper">
            {children}
            <div className="suit-vip-swiper-bold">
                {boldText}
            </div>
            <div className="suit-vip-swiper-thin">
                {thinText}
            </div>
        </div>
    )
}
export default memo(VipSwiperItem)
