import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom"
import {Image, Toast} from "antd-mobile";
import dayjs from "dayjs"
import Layout from "@/layout";
import Avatar from "@/components/avatar"
import {ApiCheersInfo} from "@/api/chat"
import {useUser} from "@/hooks/useUser"
import {generateSessionKey} from "@/plugin/easemob/Func"
import BackImgWineRight from "../assets/Icon-wine-right.png"
import BackImgWineLeft from "../assets/Icon-wine-left.png"
import BackImgWineMain from "../assets/Icon-wine-main.png"
import BackImgMessage from "../assets/Icon-message.png"
import BackImgTop from "../assets/top.png"
import "./index.less"

export default function Matching() {
    const [detail, setDetail] = useState({
        start_time: 0, end_time: 0, remain_num: 0, match_user: {}, uid: 0,
    })

    const {infoUser} = useUser()
    const matchRef = useRef(null)
    const timerRef = useRef(null)
    const history = useHistory()

    const checkMatchingStatus = useCallback(async () => {
        const data = await ApiCheersInfo()
        if (data) {
            setDetail(data)
        }
    }, [])

    const checkOpenStatus = useCallback((start_time, end_time) => {
        const nowTime = dayjs().unix().valueOf()
        if (start_time > nowTime || nowTime > end_time) {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            Toast.show({
                content: '营业时间已结束', duration: 2000, afterClose: () => {
                    history.push('/nightPub');
                }
            })
        }
    }, [history])

    useEffect(() => {
        if (!timerRef.current && detail.start_time && detail.end_time) {
            // timerRef.current = setInterval(() => checkOpenStatus(detail.start_time, detail.end_time), 1000)
        }
    }, [checkOpenStatus, detail.end_time, detail.start_time])

    useEffect(() => {
        if (!matchRef.current) {
            matchRef.current = setInterval(() => checkMatchingStatus(), 2000)
        }
    }, [checkMatchingStatus]);

    useEffect(() => {
        if (matchRef.current && detail.uid > 0) {
            clearInterval(matchRef.current)
        }
    }, [detail.uid])

    const handleHome = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.switchTab({url: "/pages/home/index"})
    }

    const goToChat = () => {
        if (detail.uid > 0) {
            window.$wx.miniProgram.navigateTo({url: "/pages/chat/index?targetId=" + detail.uid + "&sessionKey=" + generateSessionKey(infoUser.uid, detail.uid)})
        }
    }

    return (<Layout>
        <div className="pub-matching">
            <div className="pub-matching-top active">
                <Image src={BackImgTop}/>
            </div>
            <div className="pub-matching-tips">
                <div className="pub-matching-tips-title">
                    心动小贴士
                </div>
                <div className="pub-matching-tips-text">
                    偶尔使用一次土味情话，可以很好的活跃氛围，说不定还能加速两人的关系发展~
                </div>
            </div>

            {detail.uid ? (<div className="pub-matching-successful">
                <div className="pub-matching-successful-user">
                    <Avatar
                        isBlur
                        size={68}
                        uid={detail.match_user.uid}
                        avatar={detail.match_user.avatar}
                        isVip={detail.match_user.is_vip > 0}
                    />
                </div>
                <div className="pub-matching-successful-name">
                    {detail.match_user.nickname}
                </div>
                <div className="pub-matching-successful-city">
                    现居{detail.match_user.city}
                </div>
            </div>) : (<div className="pub-matching-main">
                <div className="pub-matching-main-left active">
                    <Image src={BackImgWineLeft}/>
                </div>
                <div className="pub-matching-main-main active">
                    <Image src={BackImgWineMain}/>
                </div>
                <div className="pub-matching-main-right active">
                    <Image src={BackImgWineRight}/>
                </div>
            </div>)}
            {detail.uid > 0 && <div className="pub-matching-btn" onClick={() => goToChat()}>
                <div className="pub-matching-btn-icon">
                    <Image src={BackImgMessage}/>
                </div>
                <div className="pub-matching-btn-text">
                    开启聊天
                </div>
            </div>}

            {detail.uid === 0 && (
                <div className="pub-matching-wait">
                    <div className="pub-matching-wait-title">
                        <div className="pub-matching-wait-title-item">
                            ~正在寻觅有缘人~
                        </div>
                        <div className="pub-matching-wait-title-item">
                            真挚的缘分值得等候
                        </div>
                    </div>
                    <div className="pub-matching-wait-tips">
                        不必在本页面等待，匹配到合适的选时系统会提示您
                    </div>
                </div>
            )}

            <div className="pub-matching-home" onClick={() => handleHome()}>
                <div className="pub-matching-home-text">
                    首页转转
                </div>
            </div>
        </div>
    </Layout>)
}
