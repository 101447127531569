import React, {useState, FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView} from 'antd-mobile'
import "./index.less"

const Place: FC = ({visible, callback, close}) => {
    const [checkvisible, setchechVisible] = useState(false)
    const [value, setValue] = useState(['M'])
    const handleClose = () => {
        close()
    }
    const basicColumns = [
        [
            {label: '图书馆', value: '图书馆'},
            {label: '步行街', value: '步行街'},
            {label: '公园', value: '公园'},
            {label: '课堂', value: '课堂'},
            {label: '餐厅', value: '餐厅'},
            {label: '体育场', value: '体育场'},
            {label: '电影院', value: '电影院'},
        ],

    ]
    const handlePickerChange = (value) => {
        setValue(value)
    }
    const handlePlace = () => {
        callback(value)
        close()
    }
    return (
        <Dialog display={visible} clickMask={() => handleClose()}
                background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="place">
                <div className="place-item">
                    <div className="place-item-title">
                        认识的地点
                    </div>
                    <div className="place-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setchechVisible(false)
                            }}
                            value={value}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="place-item-btn" onClick={() => handlePlace()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Place
