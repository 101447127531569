import React, {FC, useCallback, useEffect, useState} from 'react'
import {Toast} from "antd-mobile"
import Layout from "@/layout"
import {ApiWorkCode} from "@/api/wechat"
import ImageHeader from "../assets/header-work.png"
import ImageQrcodeTips from "../assets/qrcode-work.png"
import ImageFooter from "../assets/footer-work.png"
import '../index.less'

const Page: FC = () => {
    const [qrcode, setQrcode] = useState("")

    const fetchQrcode = useCallback(async () => {
        const data = await ApiWorkCode({
            state: 'app添加',
            page: "/pages/home/index",
            share:{
                media_id: "22FaPaOc4K_9Rxux4I_SAfcaotaXxkdu3eMevZ09_1mIoNuIk4B-9zN3a8DNhOnYp",
                title: '欢迎来到高质量政企白领交友社区'
            }
        })
        setQrcode(data)
    }, [])

    const getWechatObject = useCallback(() => {
        fetchQrcode()
        Toast.show({
            icon: "loading", content: "加载中…", duration: 0,
        })
        window.$wx.ready(() => Toast.clear())
    }, [fetchQrcode])

    useEffect(() => {
        getWechatObject()
    }, [getWechatObject])

    const previewQrcode = () => {
        window.$wx.previewImage({
            current: qrcode, urls: [qrcode]
        })
    }

    return (<Layout
        bgcolor=" linear-gradient(90deg, rgba(255, 255, 255, .15) 6%, transparent 0), linear-gradient(1turn, rgba(255, 255, 255, .15) 6%, transparent 0)">
        <div className="wechat-subscribe">
            <div className="wechat-subscribe-header">
                <img src={ImageHeader} alt=""/>
            </div>
            <div className="wechat-subscribe-box">
                <div className="wechat-subscribe-box-title">
                    心动引力圈客服微信
                </div>
                <div className="wechat-subscribe-box-image" onClick={() => previewQrcode()}>
                    <img src={qrcode} alt=""/>
                </div>
                <div className="wechat-subscribe-box-tips">
                    <img src={ImageQrcodeTips} alt=""/>
                </div>
            </div>
            <div className="wechat-subscribe-tips">
                <img src={ImageFooter} alt=""/>
            </div>
        </div>
    </Layout>)
}

export default Page
