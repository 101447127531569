import {useCallback, useEffect, useReducer} from 'react'
import {useLocation,useHistory} from "react-router-dom";
import url from "url";
import {ApiMatchResult} from "@/api/group";
import {useUser} from "@/hooks/useUser";

const cell = {
    width: 4, height: 4
};

const specialPoint = {
    0: {
        modal: true, direct: "bottom", modalType: "modalWeekUp"
    }, 6: {
        modal: false, direct: "right"
    }, 18: {
        modal: true, direct: "bottom", modalType: 'modalWorkHow'
    }, 39: {
        modal: true, direct: "bottom", modalType: 'modalWorkAttitude'
    }, 48: {
        modal: false, direct: 'left'
    }, 60: {
        modal: true, direct: 'bottom', modalType: 'modalHabit'
    }, 85: {
        modal: true, direct: 'right', modalType: 'modalAfterWork'
    }, 92: {
        modal: true, direct: "right", modalType: "modalCommit"
    },97: {
        modal: true, direct: "stop", modalType: "PageState"
    },
};

export default function useData(initStatus, initPosition) {
    const {isLogin} = useUser(true)
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const history = useHistory()
    const initState = {
        timer: null,
        specialDetail: {},
        currentPoint: localStorage.getItem("searchLove[currentPoint]") || 0,
        currentPosition: initPosition || {
            left: 0, top: 0
        },
        walkStatus: initStatus || false,
        modalWeekUp: false,
        modalWorkHow: false,
        modalWorkAttitude: false,
        modalHabit: false,
        modalAfterWork: false,

        modalCommit:false,

        objectWeekUp: JSON.parse(localStorage.getItem("searchLove[objectWeekUp]")) || [],
        objectWorkHow: JSON.parse(localStorage.getItem("searchLove[objectWorkHow]")) || {
            transport: '', destination: '',
        },
        objectWorkAttitude: JSON.parse(localStorage.getItem("searchLove[objectWorkAttitude]")) || [],
        objectHabit: JSON.parse(localStorage.getItem("searchLove[objectHabit]")) || [],
        objectAfterWork: JSON.parse(localStorage.getItem("searchLove[objectAfterWork]")) || [],

    }

    const reducer = (state, action: { type: string; data: any }) => {
        switch (action.type) {
            case 'specialDetail':
                return {...state, specialDetail: action.data}
            case 'walkStatus':
                return {...state, walkStatus: action.data}
            case 'currentPoint':
                localStorage.setItem("searchLove[currentPoint]", action.data)
                return {...state, currentPoint: action.data}
            case 'currentPosition':
                return {...state, currentPosition: action.data}
            case 'timer':
                return {...state, timer: action.data}
            case 'modalWeekUp':
                return {...state, modalWeekUp: action.data}
            case 'modalWorkHow':
                return {...state, modalWorkHow: action.data}
            case 'modalWorkAttitude':
                return {...state, modalWorkAttitude: action.data}
            case 'modalHabit':
                return {...state, modalHabit: action.data}
            case 'modalAfterWork':
                return {...state, modalAfterWork: action.data}

            case 'modalCommit':
                return {...state,modalCommit:action.data}
            case 'objectWeekUp':
                localStorage.setItem("searchLove[objectWeekUp]", JSON.stringify(action.data))
                return {...state, objectWorkHow: action.data}
            case 'objectWorkHow':
                localStorage.setItem("searchLove[objectWorkHow]", JSON.stringify(action.data))
                return {...state, objectWorkHow: action.data}
            case 'objectWorkAttitude':
                localStorage.setItem("searchLove[objectWorkAttitude]", JSON.stringify(action.data))
                return {...state, objectWorkAttitude: action.data}
            case 'objectHabit':
                localStorage.setItem("searchLove[objectHabit]", JSON.stringify(action.data))
                return {...state, objectHabit: action.data}
            case 'objectAfterWork':
                localStorage.setItem("searchLove[objectAfterWork]", JSON.stringify(action.data))
                return {...state, objectAfterWork: action.data}
            case 'skuCurrent':
                return {...state, skuCurrent: action.data}
            case 'extra':
                return {...state, ...action.data}
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initState)

    const checkModalStatus = useCallback((currPoint) => {
        if (specialPoint.hasOwnProperty(currPoint) && specialPoint[currPoint].modal) {
            return {
                walkStatus: false, [specialPoint[currPoint].modalType]: true
            }
        }
        return {}
    }, [])


    const calculateUserPosition = useCallback((currPoint) => {
        let defaultDirect = 'bottom';
        const tempPosition = {left: 0, top:0};

        for (let i = 0; i < currPoint; i++) {
            if (specialPoint.hasOwnProperty(i)) {
                defaultDirect = specialPoint[i].direct
            }
            switch (defaultDirect) {
                case 'bottom':
                    tempPosition.top = tempPosition.top + cell.height;
                    break;
                case 'right':
                    tempPosition.left = tempPosition.left + cell.width;
                    break;
                case 'left':
                    tempPosition.left = tempPosition.left - cell.width;
                    break;
                default:
                // .... nothing to do
            }
        }
        return {
            left: initPosition.left + tempPosition.left, top: initPosition.top + tempPosition.top,
        }
    }, [initPosition.left, initPosition.top])

    const triggerWalk = useCallback((currPoint) => {
        const userModal = checkModalStatus(currPoint);
        const userPosition = calculateUserPosition(currPoint);

        dispatch({
            type: "extra", data: Object.assign({}, userModal, {currentPosition: userPosition})
        })
    }, [calculateUserPosition, checkModalStatus])

    // 小人移动，页面跟着移动
    const handleScroll = useCallback((positionTop) => {
        const pageHeight = window.innerHeight / 2;
        const currentHeight = (window.innerWidth / 100) * positionTop;
        if (currentHeight > pageHeight) {
            const scrollAmount = cell.height * (window.innerWidth / 100);
            window.scrollBy(0, scrollAmount);
        }
    }, []);

    useEffect(() => {
        if (state.currentPoint === 96) {
            history.push('/searchlove/state')
        }else if(state.currentPoint>=98){
            return
        }
        triggerWalk(state.currentPoint)
        setTimeout(() => {
            if (state.walkStatus) {
                dispatch({type: 'currentPoint', data: parseInt(state.currentPoint) + 1})
            }
        }, 200)
    }, [history, state.currentPoint, state.walkStatus, triggerWalk]);

    useEffect(() => {
        handleScroll(state.currentPosition.top)
    }, [handleScroll, state.currentPosition.top])

    // 获取最终弹窗结果信息

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiMatchResult({group_id: query.group_id})
            dispatch({type: "specialDetail", data: data})
        }
    }, [isLogin, query.group_id])

    const handleCloseModal = (type) => {
        dispatch({
            type: "extra", data: {[type]: false, walkStatus: true}
        })
    }

    const handleItemSubmit = (data) => {
        dispatch(data)
    }

    return {...state, fetchData, handleCloseModal, checkModalStatus, calculateUserPosition, handleItemSubmit, dispatch}
}
