import React, {useState, FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView} from 'antd-mobile'
import "./index.less"

const Pursuit: FC = ({visible, callback, close}) => {
    const [checkvisible, setchechVisible] = useState(false)
    const [value, setValue] = useState(['M'])
    const handleClose = () => {
        close()
    }
    const basicColumns = [
        [
            {label: 'ta追求我', value: 'ta追求我'},
            {label: '我追求ta', value: '我追求ta'},
        ],

    ]
    const handlePickerChange = (value) => {
        setValue(value)
    }
    const handlePursuit = () => {
        callback(value)
        close()
    }
    return (
        <Dialog display={visible} clickMask={() => handleClose()}
                background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="pursuit">
                <div className="pursuit-item">
                    <div className="pursuit-item-title">
                        追求的方式
                    </div>
                    <div className="pursuit-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setchechVisible(false)
                            }}
                            value={value}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="pursuit-item-btn" onClick={() => handlePursuit()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Pursuit
