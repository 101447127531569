import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {Empty, Image, Swiper, Toast} from "antd-mobile"
import {KoubeiFill, SmileOutline} from "antd-mobile-icons"
import url from "url";
import Layout from "@/layout"
import {ApiCheckSelect, ApiIdentityPiece, ApiSaveSelect} from "@/api/group";
import {getImageSrc} from "@/filters/modules/system";
import {useUser} from "@/hooks/useUser";
import {previewImage} from "@/utils/common";
import pieceLogo from "@/assets/piece-logo.png";
import pieceBackground from "../assets/backgroun-result.png";
import "./index.less"

export default function Option() {
    const [options, setOptions] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [selected, setSelected] = useState(0)
    const [allImage, setAllImage] = useState([])
    const [optionKey, setOptionKey] = useState({})

    const configData = useSelector((state) => state.app.configData)

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin, infoUser} = useUser(true)

    const checkSelect = useCallback(async () => {
        if (query.group_id && query.piece_type && isLogin) {
            const data = await ApiCheckSelect({group_id: query.group_id, piece_type: query.piece_type})
            setSelected(data)
            setDisabled(data !== 0)
        }
    }, [isLogin, query.group_id, query.piece_type])

    const getAttrOptions = useCallback(async () => {
        if (query.group_id && query.piece_type && isLogin) {
            Toast.show({
                icon: "loading", content: "加载中…", duration: 0,
            })
            const data = await ApiIdentityPiece({group_id: query.group_id, piece_type: query.piece_type})
            if (query.piece_type === "photo" && data.length > 0) {
                const tempImage = []
                const optionKey = {}
                for (const index in data) {
                    tempImage.push(data[index].option)
                    optionKey[data[index].uid] = index
                }
                setOptionKey(optionKey)
                setAllImage(tempImage)
            }
            setOptions(data)
            Toast.clear()
        }
    }, [isLogin, query.group_id, query.piece_type])

    useEffect(() => {
        checkSelect()
        getAttrOptions()
    }, [checkSelect, getAttrOptions])

    const handleSelect = (select) => {
        if (disabled) {
            return Toast.show({
                icon: <SmileOutline/>, content: "已确认选择", maskClickable: false, duration: 1000,
            })
        }
        if (query.piece_type === "photo") {
            setSelected(options[select].uid)
        } else {
            setSelected(select)
        }
    }

    const handleSubmit = async () => {
        await ApiSaveSelect({
            group_id: query.group_id, question: query.piece_type, select: selected,
        })
        setDisabled(true)
        Toast.show({
            icon: "success", content: "选择成功，即将回到微信", maskClickable: false, duration: 1000, afterClose: () => {
                window.$wx.closeWindow()
            },
        })
    }

    return (<Layout bgcolor="#5a0ba8" refresh={() => getAttrOptions()}>
        <div className="users-attrs">
            <div className="layout-header">
                <div className="layout-header-logo">
                    <img src={pieceLogo} alt=""/>
                </div>
                <div className="layout-header-image">
                    <img src={pieceBackground} alt=""/>
                </div>
            </div>
            <div className="piece-title">
                志同道合，才是话题的开始，请选择你喜欢的类型
            </div>
            {(query.piece_type !== "photo" && options.length > 0) && <div className="attrs-list">
                {options.map((item, index) => (<div
                    key={index}
                    className={"attr-item" + (parseInt(item.uid) === selected ? " active" : "")}
                    onClick={() => handleSelect(parseInt(item.uid))}
                >
                    <div className={"item-num " + (infoUser.sex === 1 ? "num-female" : "num-male")}>
                        {(infoUser.sex === 1 ? "F" : "M") + (index + 1).toString()}
                    </div>
                    <div className="item-text">
                        {item.option + (query.piece_type === 'height' ? "cm" : '')}
                    </div>
                </div>))}
            </div>}
            {(query.piece_type === "photo" && options.length > 0) && <div className="photo-list">
                <Swiper
                    defaultIndex={optionKey.hasOwnProperty(selected) ? optionKey[selected] : 0}
                    arrow="always"
                    autoplay={false}
                    height="50vh"
                    onIndexChange={(val) => handleSelect(val)}
                    indicator={(total, current) => (<div className="custom-indicator">
                        {`${parseInt(current) + 1} / ${total}`}
                    </div>)}
                >
                    {options.map((item, index) => (<Swiper.Item
                        key={index}
                    >
                        <div
                            className="photo-item"
                            onClick={() => previewImage(item.option, allImage, configData.static_url)}
                        >
                            <div className="item-photo">
                                <Image
                                    width="100%"
                                    height="100%"
                                    src={getImageSrc(item.option, configData.static_url)}
                                    fit="cover"
                                />
                            </div>
                            <div
                                className={"item-num " + (infoUser.sex === 1 ? "num-female" : "num-male")}
                            >
                                {(infoUser.sex === 1 ? "F" : "M") + (index + 1).toString()}
                            </div>
                        </div>
                    </Swiper.Item>))}
                </Swiper>
            </div>}
            {options.length === 0 && <Empty
                style={{
                    margin: "20px", background: "#fff", height: "50vh", borderRadius: "10px",
                }}
                image={<KoubeiFill
                    style={{
                        color: "#dddddd", fontSize: 100,
                    }}
                />}
                description="还没有任何选项"
            />}

            {options.length > 0 && <div className="layout-footer">
                <div
                    className={"footer-button " + (disabled ? "selected" : "")}
                    onClick={() => handleSubmit()}
                >
                    {disabled ? "已提交选择" : "确认选择"}
                </div>
            </div>}
        </div>
    </Layout>)
}
