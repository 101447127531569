import React,{useState,FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView } from 'antd-mobile'
import "./index.less"

const Character:FC=({visible, callback, close,sendCharacter})=>{
    const [checkvisible, setchechVisible] = useState(false)
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedArray, setSelectedArray] = useState([]);
    const handleClose=()=>{
        close()
    }
    const basicColumns = [
        [
            { label: '理智型', value: '理智型' },
            { label: '疑虑型', value: '疑虑型' },
            { label: '情绪型', value: '情绪型' },
            { label: '外倾型', value: '外倾型' },
        ],
    ]
    const handlePickerChange = (value) => {
        setSelectedValue(value.join(', ')); //将选中的数组变成字符串
        setSelectedArray(value); //保留选中的数组
    };

    const handleCharacter = () => {
        callback(selectedValue);
        close();
    };

    return(
        <Dialog display={visible} clickMask={() => handleClose()} background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="character">
                <div className="character-item">
                    <div className="character-item-title">
                        你的性格
                    </div>
                    <div className="character-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setchechVisible(false)
                            }}
                            value={selectedArray}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="character-item-btn" onClick={()=>handleCharacter()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Character
