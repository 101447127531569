
import React, { useState, FC, useEffect } from "react";
import Dialog from "@/components/dialog";
import { ApiColleges } from "@/api/school";
import { Input } from "antd-mobile";
import "./index.less";
interface CollegeProps {
    visible: boolean;
    close: () => void;
    callback: (input: string) => void;
}

const College: FC<CollegeProps> = ({ visible, close, callback }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [inputContent, setInputContent] = useState("");
    const [options, setOptions] = useState([]);
    const handleClose = () => {
        close();
        setInputContent("");
        setShowDropdown(false);
    };

    const handleInput = async (event) => {
        const inputValue = event.trim();
            setInputContent(inputValue);
            generateOptions(inputValue);
            setShowDropdown(true)
    };

    useEffect(() => {
        generateOptions();
    }, []);

    const generateOptions = async (inputValue) => {
        // 根据输入内容动态生成不同的选项
        const colleges = await ApiColleges();
        const matchingColleges = Object.entries(colleges)
            .filter(([id, collegeName]) =>
                typeof collegeName === 'string' && collegeName.toLowerCase().includes(inputValue)
            );
        setOptions(matchingColleges);
    };

    const handleOptionTap = (selectedOption) => {
        setInputContent(selectedOption[1]);
    };

    const handleSubmit = () => {
        callback(inputContent);
        close();
    };

    return (
        <Dialog
            display={visible}
            clickMask={() => handleClose()}
            background={
                "linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"
            }
        >
            <div className="college">
                <div className="college-item">
                    <div className="college-item-title">请选择入学院校</div>
                    <div className="college-item-input">
                        <Input
                            type="text"
                            value={inputContent}
                            onChange={handleInput}
                            placeholder="输入内容"
                        />
                        {showDropdown && (
                            <div className="college-item-options">
                                {Object.keys(options).map((key) => (
                                    <div
                                        key={key}
                                        onClick={() => handleOptionTap(options[key])}
                                        className="college-item-options-item"
                                    >
                                        {options[key][1]}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="college-item-btn" onClick={() => handleSubmit()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default College;
