import React, {FC, useState, useEffect} from "react";
import url from "url";
import {useHistory, useLocation} from 'react-router-dom'
import Layout from "@/layout";
import {updateAttrs} from "@/model/user";
import {useUser} from '@/hooks/useUser'
import Warning from '../components/warning'
import Place from './components/place'
import Pursue from "./components/pursuit";
import Personality from "./components/personality";
import Do from './components/things'
import ImageText from "../assets/text.png";
import ImageGo from "../assets/go.png";
import ImageListText from './assets/list-text.png'
import ImageClip from './assets/clip.png'
import './index.less'


const List: FC = () => {
    const [attrForm, setAttrForm] = useState({
        'temp@school@place': "", 'temp@school@pursue': "", 'temp@school@do': "", personality: "",
    })

    const [WarningVisible, setWarningVisible] = useState(false)
    const [PlaceVisible, setPlaceVisible] = useState(false)
    const [PursueVisible, setPursueVisible] = useState(false)
    const [DoVisible, setDoVisible] = useState(false)
    const [PersonalityVisible, setPersonalityVisible] = useState(false)


    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {infoUser, infoAttr, isLogin} = useUser(true)

    const history = useHistory()

    useEffect(() => {
        if (infoAttr.hasOwnProperty('matched')) {
            const tempForm = {
                'temp@school@place': "", 'temp@school@pursue': "", 'temp@school@do': "", personality: "",
            }
            const attrKeys = Object.keys(infoAttr.matched)
            attrKeys.forEach(item => {
                if (tempForm.hasOwnProperty(item)) {
                    tempForm[item] = infoAttr.matched[item].value
                }
            })
            setAttrForm(tempForm)
        }
    }, [infoAttr])

    const go = () => {
        history.push('/school/meet?group_id=' + query.group_id)

    }
    const handlePlace = (place) => {
        setAttrForm({...attrForm, 'temp@school@place': place})
    }
    const handlePursue = (pursuit) => {
        setAttrForm({...attrForm, 'temp@school@pursue': pursuit})
    }
    const handlePersonality = (val) => {
        setAttrForm({...attrForm, personality: val.map(item => item.label)})
    }
    const handleDo = (things) => {
        setAttrForm({...attrForm, 'temp@school@do': things})
    }
    const handleSubmit = () => {
        const attrKeys = Object.keys(attrForm)
        for (const attrKey of attrKeys) {
            if (!attrForm[attrKey]) {
                return setWarningVisible(true);
            }
        }
        updateAttrs(attrForm, "matched")
        history.push('/school/current?group_id=' + query.group_id)
    }


    return (<Layout bgcolor="#FDF9F5">
        <div className="list">
            <div className="list-text animate__animated animate__fadeInRight">
                <img src={ImageText} alt=""/>
            </div>
            <div className="list-go animate__animated animate__fadeInRight" onClick={() => go()}>
                <img src={ImageGo} alt=""/>
            </div>
            <div className="list-black animate__animated animate__fadeInRight">
                <div className="list-black-text">
                    <img src={ImageListText} alt=""/>
                </div>

            </div>
            <div className="list-cont animate__animated animate__fadeInRight">
                <div className="list-cont-item">
                    <div className="list-cont-item-clip">
                        <img src={ImageClip} alt=""/>
                    </div>
                    <div className="list-cont-item-content">
                        <span>你的大学愿望清单里，谈一场恋</span>
                        <span>排在愿望清单前三；</span>
                        <br/>
                        <span>你希望和ta是在
                            <span onClick={() => setPlaceVisible(true)}>
                                {!attrForm['temp@school@place'] ? "【选择】" : attrForm['temp@school@place']}
                            </span>
                            认识,
                            </span>
                        <span>你希望是
                            <span onClick={() => setPursueVisible(true)}>
                                {!attrForm['temp@school@pursue'] ? "【选择追求方式】" : attrForm['temp@school@pursue']}
                            </span>
                            ，ta
                        </span>
                        <span>
                            是个
                            <span onClick={() => setPersonalityVisible(true)}>
                                {!attrForm.personality ? "【选择TA的性格】" : attrForm.personality.join('、')}
                            </span>；
                            </span>
                        <span>你想和ta一起
                            <span onClick={() => setDoVisible(true)}>
                                {!attrForm['temp@school@do'] ? "【选择事物】" : attrForm['temp@school@do']}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="list-btn animate__animated animate__fadeInUp" onClick={() => handleSubmit()}>
                <div className='list-btn-title'>
                    添加完毕
                </div>
                <div className="list-btn-subtitle">
                    FILL IN
                </div>
            </div>
        </div>
        <Place
            visible={PlaceVisible}
            callback={handlePlace}
            close={() => setPlaceVisible(false)}
        />
        <Pursue
            visible={PursueVisible}
            callback={handlePursue}
            close={() => setPursueVisible(false)}
        />
        <Personality
            sex={isLogin ? infoUser.sex : 0}
            visible={PersonalityVisible}
            callback={(val) => handlePersonality(val)}
            close={() => setPersonalityVisible(false)}
        />
        <Do visible={DoVisible} close={() => setDoVisible(false)} callback={handleDo}></Do>
        <Warning
            message={{
                title: "你还没有完成愿望清单", subTitle: "请完成恋爱愿望清单，进入下一环节", btnText: "确定"
            }}
            visible={WarningVisible}
            close={() => setWarningVisible(false)}
        />
    </Layout>)
}
export default List
