import React, {FC, useState, useEffect} from "react";
import url from "url";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom'
import Layout from "@/layout";
import {Image} from "antd-mobile";
import {updateAttrs} from "@/model/user";
import Warning from '../components/warning'
import Emotion from "./components/emotion";
import Job from "./components/job";
import ImageGo from "@/pages/school/assets/go.png";
import {useUser} from '@/hooks/useUser'
import ImageText from "@/pages/school/assets/text.png";
import ImageLogo from './assets/archives-logo.png'
import "./index.less"


const Archives: FC = () => {
    const [attrForm, setAttrForm] = useState({
        marital_status: "", work: []
    })
    const [EmotionVisible, setEmotionVisible] = useState(false)
    const [JobVisible, setJobVisible] = useState(false)
    const [UnfinishedVisible, setUnfinishedVisible] = useState(false)

    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)

    const {infoAttr} = useUser(true)
    const locationInfo = useSelector(state => state.app.locationInfo);


    useEffect(() => {
        if (infoAttr.hasOwnProperty('base')) {
            const tempForm = {
                marital_status: "", work: ''
            }
            const attrKeys = Object.keys(infoAttr.base)
            attrKeys.forEach(item => {
                if (tempForm.hasOwnProperty(item)) {
                    tempForm[item] = infoAttr.base[item].value
                }
            })
            setAttrForm({...tempForm})
        }
    }, [infoAttr])

    const handleEmotion = (emotion) => {
        setAttrForm({...attrForm, marital_status: emotion})
    }
    const go = () => {
        history.push('/school/wish?group_id=' + query.group_id)
    }
    const handleJob = (job) => {
        setAttrForm({...attrForm, work: job})
    }
    const handleSubmit = () => {
        if (!attrForm.marital_status || !attrForm.work) {
            setUnfinishedVisible(true);
        } else {
            updateAttrs(attrForm, "base")
            history.push('/school/profile?group_id=' + query.group_id)
        }
    }

    return (<Layout bgcolor='#FDF9F5'>
        <div className="archives">
            <div className="archives-text">
                <Image src={ImageText}/>
            </div>
            <div className="archives-go animate__animated animate__fadeInRight" onClick={() => go()}>
                <Image src={ImageGo}/>
            </div>
            <div className="archives-logo animate__animated animate__fadeInRight">
                <Image src={ImageLogo}/>
            </div>
            <div className="archives-introduce animate__animated animate__fadeInRight">
                后来你发现，大学时光是人生最美好的时光；关于友情、关于爱情，关于夏天和冬天的故事，都会被存封在回忆的档案里！
            </div>
            <div className="archives-content animate__animated animate__fadeInRight">
                <div className="archives-content-item">
                    <div className="archives-content-item-text">
                        <span>在这儿恋爱只接受互相喜欢，绝</span>
                        <span>不将就！也是你现在憧憬的，现在的你
                            <span onClick={() => setEmotionVisible(true)}>
                                {!attrForm.marital_status ? "【情感状况】" : attrForm.marital_status}
                            </span>
                            ，在{locationInfo.province}-{locationInfo.city}做
                            <span onClick={() => setJobVisible(true)}>
                                {!attrForm.work ? "【选择职业】" : ((typeof attrForm.work) === "object" ? attrForm.work[attrForm.work.length - 1] : attrForm.work)}
                            </span>
                            工作，你在寻找那时的纯粹美好。</span>
                    </div>
                </div>
            </div>
            <div className="archives-btn animate__animated animate__fadeInUp" onClick={() => handleSubmit()}>
                <div className='archives-btn-title'>
                    生成档案
                </div>
                <div className="archives-btn-subtitle">
                    FILES
                </div>
            </div>
        </div>
        <Emotion
            visible={EmotionVisible}
            close={() => setEmotionVisible(false)}
            callback={handleEmotion}
        />
        <Job
            visible={JobVisible}
            close={() => setJobVisible(false)}
            callback={handleJob}
        />
        <Warning
            message={{
                title: "你还没有完成恋爱档案", subTitle: "请完成恋爱档案，进入下一环节", btnText: "继续填写"
            }}
            visible={UnfinishedVisible}
            close={() => setUnfinishedVisible(false)}
        />
    </Layout>)
}
export default Archives
