import fetch from "@/plugin/axios"

export const ApiAssetVip = () => (fetch({
    url: "v5/asset/asset-vip"
}))

export const ApiOpenVip = (params) => (fetch({
    url: "v5/asset/open-vip", params, method: 'POST'
}))

export const ApiAssetRecharge = () => (fetch({
    url: "v5/asset/asset-recharge"
}))

export const ApiRecharge = (params) => (fetch({
    url: "v5/asset/recharge", params, method: 'POST'
}))
