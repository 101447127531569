import React from "react"
import Mask from "@/components/mask"
import "./index.less"


export default function Popup({display = false, radius, close, children, customStyle = {}}) {
    return (
        <div className="popup" style={customStyle}>
            <Mask visible={display} close={() => close()}/>
            <div
                className={`${display ? 'slide-in' : 'slide-out'} popup-view ${radius ? 'popup-radius' : ''}`}
            >
                {children}
            </div>
        </div>
    )
}
