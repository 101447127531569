import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import url from "url";
import {Image, Toast} from "antd-mobile";
import {InformationCircleOutline} from "antd-mobile-icons";
import Layout from "@/layout";
import {ApiDetail} from "@/api/group";
import {useUser} from "@/hooks/useUser";
import {updateAttrs, updateInfo} from "@/model/user";
import {options} from "./options"
import IconMore from "./assets/icon-more.png";
import IconSelected from "./assets/icon-selected.png";
import ImageShopping from "./assets/shopping.png";
import "./index.less"

export default function Shopping() {
    const [gender, setGender] = useState(0)
    const [height, setHeight] = useState({})
    const [form, setForm] = useState({
        character: -1, age: '', height: '',
    })

    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)

    const {infoUser, isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            if (data.is_finish === 1) {
                history.push("/shopping/status?group_id=" + query.group_id)
            }
        }
    }, [history, isLogin, query.group_id])

    useEffect(() => {
        if (Object.keys(infoUser).length) {
            setGender(infoUser.sex)
        }
    }, [infoUser])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSelectGender = (sex) => {
        if (gender !== sex) {
            setGender(sex)
            setForm({...form, character: -1})
        }
    }

    const handleSubmit = () => {
        if (form.character < 0) {
            return Toast.show({
                icon: <InformationCircleOutline/>, content: "您还没选择款式", maskClickable: false, duration: 1000
            })
        }
        if (!form.age) {
            return Toast.show({
                icon: <InformationCircleOutline/>, content: "请选择出厂日期", maskClickable: false, duration: 1000
            })
        }
        if (Object.keys(height).length === 0) {
            return Toast.show({
                icon: <InformationCircleOutline/>, content: "请选择尺码", maskClickable: false, duration: 1000
            })
        }
        updateInfo({sex: gender}, "base")
        updateAttrs({...form, height: height.value}, 'matched')

        history.push("/shopping/confirm?group_id=" + query.group_id)
    }

    return (<Layout bgcolor="#F4F5FC">
        <div className="shopping-start">
            <div className="shopping-start-info">
                <div className="shopping-start-info-content">
                    <div className="shopping-start-info-content-image">
                        <Image src={ImageShopping}/>
                    </div>
                    <div className="shopping-start-info-content-detail">
                        <div className="shopping-start-info-content-detail-price">
                            ￥520-1314
                        </div>
                        <div className="shopping-start-info-content-detail-marketing">
                            活动价：￥0
                        </div>
                    </div>
                </div>
                <div className="shopping-start-info-tips">
                    <div className="shopping-start-info-tips-icon">
                        <Image src={IconMore}/>
                    </div>
                    <div className="shopping-start-info-tips-text">
                        已有1000+人来这里预定了心仪对象
                    </div>
                </div>
            </div>

            <div className="shopping-start-card">
                <div className="shopping-start-card-header">
                    <div className="shopping-start-card-header-label">
                        选择“款式”
                    </div>
                    <div className="shopping-start-card-header-tips">
                        （期望对方的性格）
                    </div>
                </div>
                <div className="shopping-start-card-switch">
                    <div
                        onClick={() => handleSelectGender(2)}
                        className={"shopping-start-card-switch-item female " + (gender === 2 ? "active-female" : "")}
                    >
                        女生选这里
                    </div>
                    <div
                        onClick={() => handleSelectGender(1)}
                        className={"shopping-start-card-switch-item male " + (gender === 1 ? "active-male" : "")}
                    >
                        男生选这里
                    </div>
                </div>
                <div className="shopping-start-card-selects">
                    {options.characters[gender === 1 ? "female" : "male"].map((item, index) => (<div
                        key={index}
                        className="shopping-start-card-selects-item"
                        onClick={() => setForm({...form, character: index})}
                    >
                        <div
                            className={"shopping-start-card-selects-item-image " + (form.character === index ? "selected" : "")}
                        >
                            <Image src={item.image} fit="fill"/>
                            {form.character === index &&
                                <Image src={IconSelected} fit="fill" className="item-image-selected"/>}
                        </div>
                        <div className="shopping-start-card-selects-item-label">
                            {item.label}
                        </div>
                    </div>))}
                </div>
            </div>
            <div className="shopping-start-card">
                <div className="shopping-start-card-header">
                    <div className="shopping-start-card-header-label">
                        选择“出厂日期”
                    </div>
                    <div className="shopping-start-card-header-tips">
                        （期望对方的年龄）
                    </div>
                </div>
                <div className="shopping-start-card-options">
                    {options.ages.map((item, index) => (<div
                        key={index}
                        className={"shopping-start-card-options-item " + (form.age === item ? "selected" : "")}
                        onClick={() => setForm({...form, age: item})}
                    >
                        {item}
                    </div>))}
                </div>
            </div>
            <div className="shopping-start-card">
                <div className="shopping-start-card-header">
                    <div className="shopping-start-card-header-label">
                        选择“尺码”
                    </div>
                    <div className="shopping-start-card-header-tips">
                        （期望对方的身高体重）
                    </div>
                </div>
                <div className="shopping-start-card-options">
                    {options.heights.map((item, index) => (<div
                        key={index}
                        className={"shopping-start-card-options-item " + (height === item ? "selected" : "")}
                        onClick={() => setHeight(item)}
                    >
                        {item.label + "(" + item.value + ")"}
                    </div>))}
                </div>
            </div>

            <div className="fix-empty"/>

            <div className="shopping-start-bottom">
                <div className="shopping-start-bottom-price">
                    ￥0
                </div>
                <div
                    className="shopping-start-bottom-button"
                    onClick={() => handleSubmit()}
                >
                    提交订单
                </div>
            </div>
        </div>
    </Layout>)
}
