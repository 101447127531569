import React, {useCallback, useEffect, useState} from "react"
import {Image, ProgressBar, Toast} from "antd-mobile"
import {CheckOutline} from "antd-mobile-icons"
import Layout from "@/layout"
import {ApiQuestions} from "@/api/question"
import {updateAttrs} from "@/model/user"
import ImageBackgroundOption from "../assets/background-option.png";
import "./index.less"

export default function Option() {
    const [current, setCurrent] = useState(0)
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState({})
    const [scoreMap, setScoreMap] = useState({
        EI: {}, SN: {}, TF: {}, JP: {}
    })

    const fetchData = useCallback(async () => {
        const data = await ApiQuestions({scene: "mbti"})
        if (data) {
            setQuestions(data)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handlePrevious = () => {
        setCurrent(current - 1)
    }

    const handleSelect = (question, item) => {
        const tempAnswer = JSON.parse(JSON.stringify(answers))
        const tempScoreMap = JSON.parse(JSON.stringify(scoreMap[question.vdoing]))
        tempScoreMap[question.id] = item.value
        setScoreMap({...scoreMap, [question.vdoing]: tempScoreMap})

        tempAnswer[current] = item.value
        setAnswers(tempAnswer)
        if (current + 1 < questions.length) {
            setCurrent(current + 1)
        }
    }

    const handleSubmit = () => {
        const tempMbtiCodes = []
        const tempScores = {
            EI: {pros: 0, cons: 0}, SN: {pros: 0, cons: 0}, TF: {pros: 0, cons: 0}, JP: {pros: 0, cons: 0}
        }
        for (const vdoing in scoreMap) {
            for (const questionId in scoreMap[vdoing]) {
                tempScores[vdoing][scoreMap[vdoing][questionId]] += 1
            }
        }

        tempMbtiCodes.push(tempScores.EI.pros > tempScores.EI.cons ? "E" : "I")
        tempMbtiCodes.push(tempScores.SN.pros > tempScores.SN.cons ? "S" : "N")
        tempMbtiCodes.push(tempScores.TF.pros > tempScores.TF.cons ? "T" : "F")
        tempMbtiCodes.push(tempScores.JP.pros > tempScores.JP.cons ? "J" : "P")

        updateAttrs({mbti: {code: tempMbtiCodes.join(""), scores: tempScores}}, "base")

        Toast.show({
            icon: <CheckOutline/>, content: "生成报告中", maskClickable: false, duration: 1000, afterClose: () => {
                window.$wx.miniProgram.navigateBack({delta: 1})
            },
        })
    }

    return (<Layout
        bgcolor="radial-gradient(52% 23% at 100% 62%, #CEFDFF 0%, rgba(255,255,255,0.00) 100%), radial-gradient(45% 30% at 4% 34%, #FFE7BE 0%, rgba(255,255,255,0.00) 100%), radial-gradient(59% 40% at 0% 0%, #F2FBE3 0%, rgba(254,251,239,0.00) 100%), radial-gradient(29% 11% at 16% 7%, #FEFBEF 0%, rgba(248,249,252,0.00) 99%), radial-gradient(110% 87% at 100% -21%, #FFEDD2 0%, #F8F9FC 99%)"
    >
        {questions.length > 0 && <div className="mbti-option">
            <div className="mbti-option-header">
                <Image src={ImageBackgroundOption}/>
            </div>
            <div className="mbti-option-card">
                <div className="mbti-option-card-process">
                    <div className="mbti-option-card-process-rate">
                        {(current + 1).toString() + '/' + questions.length}
                    </div>
                    <div className="mbti-option-card-process-line">
                        <ProgressBar
                            percent={questions.length > 0 ? parseInt((current + 1) / questions.length * 100) : 0}
                            style={{
                                '--fill-color': '#FFA638',
                            }}
                        />
                    </div>
                </div>
                <div className="mbti-option-card-question">
                    {questions[current].question}
                </div>
                <div className="mbti-option-card-options">
                    {Object.keys(questions[current].answer).map((item, index) => (<div
                        key={current.toString() + '-' + index.toString()}
                        className={"mbti-option-card-options-item " + (answers[current] === questions[current].answer[item].value ? "active" : "")}
                        onClick={() => handleSelect(questions[current], questions[current].answer[item])}
                    >
                        {questions[current].answer[item].option}
                    </div>))}
                </div>
            </div>

            <div className="mbti-option-bottom">
                {current > 0 && <div className="mbti-option-bottom-btn" onClick={() => handlePrevious()}>
                    返回上一题
                </div>}
                {(Object.keys(answers).length === questions.length && (current + 1) === questions.length) &&
                    <div className="mbti-option-bottom-btn orange" onClick={() => handleSubmit()}>
                        生成报告
                    </div>}
            </div>
        </div>}
    </Layout>)
}
