import React, {useCallback, useEffect, useState} from "react";
import {Image, Toast} from "antd-mobile";
import url from "url";
import Layout from "@/layout";
import {useLocation} from 'react-router-dom'
import {ApiOfficialCode} from "@/api/wechat";
import {ApiDetail} from "@/api/group";
import {useUser} from "@/hooks/useUser";
import {filterTime} from "@/filters/modules/system";
import {checkUserSocialSubscribe} from "@/utils/common";
import ImageLeft from "../result/assets/unmatch-left.png";
import ImageRight from "../result/assets/unmatch-right.png";
import ImageText from "../assets/text.png";
import ImageGo from "../assets/go.png";
import ImageLogo from './assets/logo.png'
import ImageRocket from "./assets/rocket.png"
import "./index.less"

export default function Status() {
    const [detail, setDetail] = useState({
        info: {
            match_time: 0
        }, activity: {
            activity_id: 0
        }
    })
    const [qrcode, setQrcode] = useState("")
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {infoUser, isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            setDetail(data)
        }
    }, [isLogin, query.group_id])

    const fetchQrcode = useCallback(async (activityId) => {
        Toast.show({
            icon: "loading", content: "加载中…", duration: 0,
        })
        const data = await ApiOfficialCode({
            page: encodeURIComponent('pages/activity/school/index'), activity_id: activityId
        })
        Toast.clear()
        setQrcode(data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (checkUserSocialSubscribe(infoUser) === false && detail.activity.activity_id) {
            fetchQrcode(detail.activity.activity_id)
        }
    }, [detail.activity.activity_id, fetchQrcode, infoUser])

    const previewQrcode = () => {
        window.$wx.previewImage({
            current: qrcode, urls: [qrcode]
        })
    }

    const handleBack = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.navigateBack({delta: 1})
    }

    const handleShare = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.navigateTo({url: "/pages/activity/school/index?activityId=" + detail.activity.activity_id})
    }

    const handleHome = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.switchTab({url: "/pages/home/index"})
    }

    return (<Layout bgcolor="#FDF9F5">
        <div className="school-status">
            <div className="school-status-text">
                <Image src={ImageText}/>
            </div>
            <div className="school-status-go animate__animated animate__fadeInRight" onClick={() => handleBack()}>
                <Image src={ImageGo}/>
            </div>
            <div className="school-status-logo">
                <div className="school-status-logo-img">
                    <Image src={ImageLogo}/>
                    {
                        checkUserSocialSubscribe(infoUser) &&
                        <div className="school-status-logo-text">
                            <div className="school-status-logo-text-up">匹配时间</div>
                            <div className="school-status-logo-text-down">{filterTime(detail.info.match_time)}</div>
                        </div>
                    }
                </div>
            </div>
            <div className="school-status-content">
                <div className="school-status-content-top">
                    <div className="school-status-content-left">
                        <Image src={ImageLeft}/>
                    </div>
                    <div className="school-status-content-main">
                        根据您的档案
                    </div>
                    <div className="school-status-content-right">
                        <Image src={ImageRight}/>
                    </div>
                </div>
                <div className="school-status-content-center">
                    为您<span className="school-status-content-center-focus">匹配同城、同期</span>的校友
                </div>
            </div>

            {checkUserSocialSubscribe(infoUser) === false ? <div className="school-status-public">
                <div className="school-status-public-tips">
                    匹配结果将为您推送至公众号服务消息
                </div>
                <div className="school-status-public-main">
                    请及时关注微信公众号【心动引力圈】
                </div>
                <div className="school-status-public-qrcode">
                    <div className="school-status-public-qrcode-image" onClick={() => previewQrcode()}>
                        <Image src={qrcode}/>
                    </div>
                </div>
                <div className="school-status-public-bottom">
                    长按识别二维码关注公众号
                </div>
            </div> : <div className="school-status-progress">
                <div className="school-status-progress-rate">
                    初始匹配成功率60%
                </div>
                <div className="school-status-progress-detail">
                    <div className="school-status-progress-detail-line"/>
                    <div className="school-status-progress-detail-rocket rocket-flying">
                        <Image src={ImageRocket}/>
                    </div>
                </div>
                <div className="school-status-progress-tips">
                    分享三位好友匹配成功率提升至<span className="school-status-progress-tips-focus">99.99%</span>
                </div>
                <div className="school-status-progress-btns">
                    <div className="school-status-progress-btns-share" onClick={() => handleShare()}>
                        <div className="school-status-progress-btns-share-title">
                            分享给好友
                        </div>
                        <div className="school-status-progress-btns-share-subtitle">
                            点击首页分享按钮即可分享
                        </div>
                    </div>
                    <div className="school-status-progress-btns-home" onClick={() => handleHome()}>
                        去首页看看》
                    </div>
                </div>
            </div>}
        </div>
    </Layout>)
}
