import fetch from "@/plugin/axios"

export const ApiInit = () => (
    fetch({
        url: "v5/info/init"
    })
)

export const ApiIpLocation = () => (
    fetch({
        url: "v5/info/ip-location"
    })
)

export const ApiPositions = (params) => (
    fetch({
        url: `v5/info/positions?parent_id=${params}`,
    })
)
