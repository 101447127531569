import React, {FC, memo} from "react"
import {RootStateOrAny, useSelector} from 'react-redux'
import {getImageSrc} from "@/filters/modules/system"
import IconVipFlag from "@/assets/icon/vip-flag.png"
import './index.less'


const ComAvatar: FC = ({avatar, size = 60, uid = 0, isVip = false, goProfile = true, isBlur = false}) => {
    const configData = useSelector((state: RootStateOrAny) => state.app.configData)

    const handleClick = (e) => {
        if (goProfile && uid) {
            e.stopPropagation()
            window.$wx.miniProgram.navigateTo({
                url: '/pages/profile/index?uid=' + uid
            })
        }
    }

    return (<div className="avatar" style={{width: `${size}px`, height: `${size}px`}}>
        <div className={"avatar-view " + (isVip ? "active" : "")} onClick={(e) => handleClick(e)}>
            {Object.keys(configData).length > 0 && avatar && <img
                className="avatar-view-image"
                style={{filter: isBlur ? "blur(2px)" : "blur(0px)"}}
                src={getImageSrc(avatar, configData.static_url)}
                alt=""
            />}
        </div>
        <div
            className="avatar-vip"
            style={{
                display: isVip ? "block" : "none", width: `${size * 0.40}px`, height: `${size * 0.40}px`
            }}
        >
            <img className="avatar-vip-icon" src={IconVipFlag} alt=""/>
        </div>
    </div>)
}

export default memo(ComAvatar)
