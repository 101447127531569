import React from "react"
import {Image} from "antd-mobile"
import Layout from "@/layout";
import ImageMomentHeader from "../assets/moment-header.png"
import "./index.less"

const Guide = () => {
    const tipsConfig = [
        {
            title: '一、遵守法律法规',
            items: [
                "严格遵守《网络信息内容生态治理规定》中的各项规定，包括但不限于网络生态治理的九个方面：政治、军事、宗教、历史、言论、影音、出版、广告、游戏。",
                "不发布违反法律法规的信息，包括但不限于色情、暴力、赌博、恐怖主义等内容。",
                "不传播攻击性、歧视性、不良价值观等言论，尊重他人的人格和权益。",
            ]
        },
        {
            title: '二、共同兴趣爱好的交流',
            items: [
                "鼓励在“心动引力圈”平台上分享和交流共同的兴趣爱好，如文化艺术、科技生活、旅行美食等。",
                "发言应尊重他人的兴趣爱好，不发表针对他人的攻击性言论，以和谐讨论氛围为首要交流目标。",
            ]
        },
        {
            title: '三、促进健康的社交行为',
            items: [
                "用户应保持良好的社交礼仪，不发表恶意的挑衅性言论，尊重他人的意见和观点。",
                "鼓励分享积极健康的恋爱观念和经验，提倡负责任的恋爱行为，反对任何形式的欺诈和滥用行为。",
            ]
        },
        {
            title: '四、营造信任的网络环境',
            items: [
                "“心动引力圈”平台将加强对用户发布内容的审核和管理，保证信息的真实性和可信度。",
                "用户应对自己发布的内容负责，保证信息的真实性和合法性。如发现任何违规行为，平台将采取相应的处理措施。",
                "为了维护信任的网络环境，用户应妥善保护自己的隐私和个人信息，不泄露给他人。同时，也应尊重他人的隐私和个人信息。",
            ]
        },
        {
            title: '五、恋爱氛围的营造',
            items: [
                "“心动引力圈”平台致力于为用户打造一个安全、温馨的恋爱交流环境。用户在发布内容时，请避免出现与性骚扰、言语虐待、肢体接触等与恋爱氛围不符的行为。",
                "我们鼓励多元化的恋爱观念和经验分享，提倡健康、积极的恋爱行为。无论是单身人士还是正在恋爱中的伴侣，都可以在这里找到共同的话题和经验交流。",
                "在这个平台上，每个人都有权利发表自己的观点和看法，但请务必尊重他人的感受和边界。无论是赞美还是批评，请以建设性的方式进行，避免出现恶意攻击或者讽刺的言论。",
                "“心动引力圈”平台将根据社区发展需求和用户反馈，定期更新和完善社区规定，以确保社区的氛围和内容更符合用户的期望。",
                "我们希望每个用户都能在这个平台上感受到心动和喜悦，同时也能遵守这些规范，共同维护一个健康、积极的社区氛围。让我们一起努力，让“心动引力圈”成为一个充满爱与温暖的地方！"
            ]
        }
    ];
    return (
        <Layout bgcolor="radial-gradient(70% 70% at 0% 0%, #F2FBE3 0%, rgba(254, 251, 239, 0) 100%), radial-gradient(51% 51% at 57% 24%, #FEFBEF 0%, rgba(248, 249, 252, 0) 99%), radial-gradient(73% 73% at 100% 1%, #FFEDD2 0%, #F8F9FC 99%)" needLogin={false} needWechat={false}>
            <div className="page-guide-moment">
                <div className="page-guide-moment-header">
                    <Image src={ImageMomentHeader}/>
                </div>
                <div className="page-guide-moment-tips">
                    {
                        tipsConfig.map((item, index) => (
                            <div key={index} className="page-guide-moment-tips-item">
                                <div className="page-guide-moment-tips-item-title">
                                    {item.title}
                                </div>
                                <div className="page-guide-moment-tips-item-infos">
                                    {
                                        item.items.map((sItem, sIndex) => (
                                            <div key={sIndex} className="page-guide-moment-tips-item-infos-item">
                                                <div className="page-guide-moment-tips-item-infos-item-sn">
                                                    {sIndex + 1}、
                                                </div>
                                                <div className="page-guide-moment-tips-item-infos-item-text">
                                                    {sItem}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </Layout>
    )
}
export default Guide
