import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import url from "url";
import Layout from "@/layout";
import {Toast} from "antd-mobile";
import {ApiGetCurrent, ApiSaveSelect} from "@/api/group";
import ChooseTips from "./components/chooseTips"
import StopTips from "./components/stopTips"
import "./index.less"

export default function Option() {
    const [questions, setQuestions] = useState([])
    const [selecteds, setSelecteds] = useState({})
    const [questionConf, setQuestionConf] = useState({})
    const [chooseTipVisibe, setChooseTipVisibe] = useState(false)
    const [stopTipVisibe, setStopTipVisibe] = useState(false)
    const [selectParams, setSelectParams] = useState({"select": "", "select_text": "",})

    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)

    const getQuestion = useCallback(async () => {
        if (query.group_id) {
            Toast.show({
                icon: "loading", content: "加载中…", duration: 0,
            })
            const data = await ApiGetCurrent({group_id: query.group_id})
            if (data.info.status === 1) {
                if (data.is_finish === 1) {
                    Toast.clear()
                    history.push("/spaceship/status?group_id=" + query.group_id)
                }
                if (data.questions.length === Object.keys(data.selecteds).length) {
                    Toast.clear()
                    history.push("/spaceship/status?group_id=" + query.group_id)
                } else {
                    setQuestions(data.questions)
                    setSelecteds(data.selecteds)
                    setQuestionConf(data.current)
                    Toast.clear()
                }
            } else {
                Toast.clear()
                history.push("/spaceship/result?group_id=" + query.group_id)
            }
        }
    }, [history, query.group_id])

    useEffect(() => {
        getQuestion()
    }, [getQuestion])

    const saveSelect = async (is_finish = 0) => {
        await ApiSaveSelect(Object.assign(selectParams, {
            question: questionConf.id,
            question_text: questionConf.question,
            group_id: query.group_id,
            is_finish: is_finish
        }));
        if (is_finish === 1) {
            history.push("/spaceship/status?group_id=" + query.group_id)
        } else {
            setSelectParams({select: "", select_text: ""})
            getQuestion()
        }
    }

    const handleSubmit = async (is_finish = 0) => {
        if (!selectParams.select) {
            return setChooseTipVisibe(true)
        }
        if (is_finish && questions.length > (Object.keys(selecteds).length + 1)) {
            return setStopTipVisibe(true)
        }
        saveSelect(is_finish)
    }

    return (<Layout bgcolor="#441d81">
        <div className="spaceship-option">
            {questions.length > 0 && <div className="spaceship-option-header">
                <div className="spaceship-option-header-title">
                    {"第" + (Object.keys(selecteds).length + 1) + "站"}
                </div>
            </div>}

            {Object.keys(questionConf).length > 0 && <div className="spaceship-option-card">
                <div className="spaceship-option-card-question">
                    {questionConf.question}
                </div>
                <div className="spaceship-option-card-select">
                    {Object.keys(questionConf.answer).map((sn, key) => (<div
                        key={key}
                        className={"spaceship-option-card-select-item" + (selectParams.select === sn ? " active" : "")}
                        onClick={() => setSelectParams({select: sn, select_text: questionConf.answer[sn].option})}
                    >
                        <div className="spaceship-option-card-select-item-no">
                            {sn.toUpperCase()}
                        </div>
                        <div className="spaceship-option-card-select-item-text">
                            {questionConf.answer[sn].option}
                        </div>
                    </div>))}
                </div>
            </div>}

            {questions.length > 0 && <div className="spaceship-option-button">
                {(questions.length > (Object.keys(selecteds).length + 1)) &&
                    <div className="spaceship-option-button-continue" onClick={() => handleSubmit()}>
                        继续前进
                    </div>}
                <div className="spaceship-option-button-stop" onClick={() => handleSubmit(1)}>
                    {(questions.length === (Object.keys(selecteds).length + 1)) ? "结束旅途" : "停止前进"}
                </div>
            </div>}
        </div>
        <ChooseTips visible={chooseTipVisibe} close={() => setChooseTipVisibe(false)}/>
        <StopTips visible={stopTipVisibe} callback={() => saveSelect(1)} close={() => setStopTipVisibe(false)}/>
    </Layout>)
}
