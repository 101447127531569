import {ApiInit, ApiIpLocation} from "@/api/system"
import * as VAL                 from "@/store/constants/app"

export const setLoading = (data) => {
    return {type: VAL.LOADING, data: data}
}

export const setConfigData = (data) => {
    return {type: VAL.CONFIGDATA, data: data}
}

export const getInitData = (): any => {
    return dispatch => {
        (async () => {
            const data = await ApiInit()
            dispatch(setConfigData(data))
        })()
    }
}

export const setLocationInfo = (data) => {
    return {type: VAL.LOCATIONINFO, data: data}
}

export const getLocationData = (): any => {
    return dispatch => {
        (async () => {
            const data = await ApiIpLocation()
            dispatch(setLocationInfo(data))
        })()
    }
}
