import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Image, Toast} from "antd-mobile";
import {CheckOutline} from "antd-mobile-icons";
import copy from "copy-to-clipboard";
import url from "url";
import Layout from "@/layout";
import {ApiDetail} from "@/api/order";
import {filterTime} from "@/filters/modules/system";
import {useUser} from "@/hooks/useUser";
import ImageBackground from "../assets/background.png";
import "./index.less"

export default function Status() {
    const [detail, setDetail] = useState({})

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.activity_id && query.order_union_sn && isLogin) {
            const data = await ApiDetail({activity_id: query.activity_id, order_union_sn: query.order_union_sn})
            setDetail(data)
        }
    }, [isLogin, query.activity_id, query.order_union_sn])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const copyWechat = () => {
        copy(detail.contact_wechat)
        Toast.show({
            icon: <CheckOutline/>, content: "复制成功", maskClickable: false, duration: 1000, afterClose: () => {
                window.$wx.miniProgram.navigateBack({delta: 1})
            },
        })
    }

    return (<Layout bgcolor="#5a0ba8">
        <div className="stranger-status">
            <div className="stranger-status-header">
                <Image src={ImageBackground}/>
            </div>
            <div className="stranger-status-text">
                报名成功
            </div>
            {Object.keys(detail).length > 0 && <div className="stranger-status-card">
                <div className="stranger-status-card-header">
                    <div className="stranger-status-card-header-label">
                        活动时间
                    </div>
                    <div className="stranger-status-card-header-text">
                        {filterTime(detail.time_start_doing)}
                    </div>
                </div>
                <div className="stranger-status-card-label">
                    请注意！！！！
                </div>
                <div className="stranger-status-card-tips">
                    请添加客服微信，场次安排成功后，会邀您进入活动
                </div>

                <div className="stranger-status-card-wechat">
                    <div className="stranger-status-card-wechat-text">
                        {detail.contact_wechat}
                    </div>
                    <div className="stranger-status-card-wechat-btn" onClick={() => copyWechat()}>
                        复制微信号
                    </div>
                </div>
            </div>}
        </div>
    </Layout>)
}
