import {ApiAttr} from "@/api/user"
import * as VAL from "@/store/constants/user"

export const setTokenInfo = (data) => {
    return {type: VAL.INFOTOKEN, data: data}
}

export const setUserInfo = (data) => {
    return {type: VAL.INFOUSER, data: data}
}

export const setUserAttr = (data) => {
    return {type: VAL.INFOATTR, data: data}
}

export const setIsLogin = (data) => {
    return {type: VAL.ISLOGIN, data: data}
}

export const setLogout = () => {
    return {type: VAL.LOGOUT, data: {}}
}

export const getUserAttr = () => {
    return dispatch => {
        (async () => {
            const data = await ApiAttr()
            if (data) {
                dispatch(setUserAttr(data))
            }
        })()
    }
}
