import * as VAL from "@/store/constants/user"

const localStorage = window.localStorage

const STATE = {
    infoToken: JSON.parse(localStorage.getItem("token-info")) || {},
    infoUser: JSON.parse(localStorage.getItem("user-info")) || {},
    infoAttr: JSON.parse(localStorage.getItem("attr-info")) || {},
    isLogin: false,
}

type ACTION = {
    type: string, data: any
}

export default function user(state = STATE, action: ACTION) {
    switch (action.type) {
        case VAL.INFOTOKEN:
            localStorage.setItem("token-info", JSON.stringify(action.data))
            return {...state, infoToken: action.data}
        case VAL.INFOUSER:
            localStorage.setItem("user-info", JSON.stringify(action.data))
            return {...state, infoUser: action.data}
        case VAL.INFOATTR:
            localStorage.setItem("attr-info", JSON.stringify(action.data))
            return {...state, infoAttr: action.data}
        case VAL.ISLOGIN:
            return {...state, isLogin: action.data}
        case VAL.LOGOUT:
            localStorage.removeItem("token-info")
            return {...state, infoToken: action.data}
        default:
            return state
    }
}
