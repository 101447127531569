import React from "react"
import Dialog from "@/components/dialog"
import "./index.less"


export default function ChooseTips({visible, close}) {
    const handleSubmit = () => {
        close()
    }

    return (<Dialog display={visible} clickMask={() => handleSubmit()}>
        <div className="choose-tips">
            <div className="choose-tips-title">
                您还没选择心动选项
            </div>

            <div className="choose-tips-content">
                请遵从内心选择心动选项，有缘人就在前方
            </div>

            <div className="choose-tips-button" onClick={() => handleSubmit()}>
                继续答题
            </div>
        </div>
    </Dialog>)
}
