import React, {FC} from "react";
import Dialog from "@/components/dialog";
import "./index.less"

const Warning: FC = ({message, visible, close}) => {
    const handleClose = () => {
        close()
    }
    return (<Dialog display={visible} clickMask={() => handleClose()}
                    background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
        <div className="warning">
            <div className="warning-item">
                <div className="warning-item-title">
                    {message.title}
                </div>
                <div className="warning-item-cont">
                    {message.subTitle}
                </div>
                <div className="warning-item-btn" onClick={() => handleClose()}>
                    {message.btnText}
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Warning
