import {Button, Image, Input, Toast} from "antd-mobile"
import React, {useState} from "react"
import Layout from "@/layout"
import {isValidIDCard} from "@/utils/common"
import {ApiCertificate} from "@/api/user"
import IconIdCard from "../assets/IdCard.png"
import "./index.less"

const Certification = () => {
    const [agreed, setAgreed] = useState(false)
    const [form, setForm] = useState({
        type: 0, detail: {
            realname: '', idcard: ''
        }
    })

    const handleSubmit = async () => {
        if (!agreed) {
            return Toast.show({
                content: '请勾选用户信息授权协议', duration: 2000
            })
        }
        if (!form.detail.realname) {
            return Toast.show({
                content: '姓名不能为空', duration: 2000
            })
        }
        if (!form.detail.idcard) {
            return Toast.show({
                content: '身份证号不能为空', duration: 2000
            })
        }
        if (!isValidIDCard(form.detail.idcard)) {
            return Toast.show({
                content: '身份证号格式不正确', duration: 2000
            })
        }
        const data = await ApiCertificate(form)
        if (data) {
            return Toast.show({
                icon: 'success',
                content: '提交认证信息成功',
                afterClose: () => {
                    window.$wx.miniProgram.navigateBack({delta: 1})
                }
            })
        }
    }

    return (<Layout>
        <div className="idCard">
            <div className="idCard-header">
                <div className="idCard-header-top">
                    <div className="idCard-header-top-left">
                        <div className="idCard-header-top-left-icon">
                            <Image src={IconIdCard}></Image>
                        </div>
                        <div className="idCard-header-top-left-text">
                            实名认证
                        </div>
                    </div>
                    <div className="idCard-header-top-right">
                        仅作审核，腾讯云加密存储
                    </div>
                </div>
                <div className="idCard-header-bottom">
                    认证声明：为保障真实、安全社交环境，所有用户须进行实名认证。
                </div>
            </div>
            <div className="idCard-form">
                <div className="idCard-form-item">
                    <div className="idCard-form-item-label">真实姓名</div>
                    <div className="idCard-form-item-value">
                        <Input
                            placeholder="输入您的真实姓名"
                            value={form.detail.realname}
                            onChange={(e) => setForm({
                                ...form, detail: {
                                    ...form.detail, realname: e.trim()
                                }
                            })}
                        />
                    </div>
                </div>
                <div className="idCard-form-item">
                    <div className="idCard-form-item-label">身份证号</div>
                    <div className="idCard-form-item-value">
                        <Input
                            placeholder="请输入身份证"
                            value={form.detail.idcard}
                            onChange={(e) => setForm({
                                ...form, detail: {
                                    ...form.detail, idcard: e.trim()
                                }
                            })}
                        />
                    </div>
                </div>

            </div>
            <div className="idCard-bottom">
                <div className="idCard-bottom-action">
                    <Button
                        className="idCard-bottom-action-btn"
                        disabled={!form.detail.idcard || !form.detail.realname}
                        onClick={() => handleSubmit()}
                    >
                        立即认证
                    </Button>
                </div>

                <div className="idCard-bottom-agreement">
                    <div
                        className="idCard-bottom-agreement-radio"
                        onClick={() => setAgreed(!agreed)}
                    >
                        {agreed && <div className="idCard-bottom-agreement-radio-active"></div>}
                    </div>
                    <div className="idCard-bottom-agreement-text">
                        我已查看并同意 <a href="/protocol.html">《用户信息授权协议》</a>
                    </div>
                </div>
            </div>
        </div>
    </Layout>)
}

export default Certification

