import fetch from "@/plugin/axios"

export const ApiLogin = (params) => (fetch({
    url: "v5/user/login", method: "POST", params,
}))

export const ApiDirtyLogin = (params) => (fetch({
    url: "v5/user/dirty-login", method: "GET", params
}))

export const ApiInfo = () => (fetch({
    url: "v5/user/info",
}))

export const ApiAttr = () => (fetch({
    url: "v5/user/attrs"
}))

export const ApiSaveData = (params) => (fetch({
    url: "v5/user/save-data", method: "POST", params
}))

export const ApiSaveInfo = (params) => (fetch({
    url: "v5/user/save-info", method: "POST", params
}))

export const ApiCertificate = (params) => (fetch({
    url: "v5/user/certificate", method: "POST", params
}))

