import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {Image, Button} from "antd-mobile"
import dayjs from "dayjs"
import Layout from "@/layout";
import {ApiCheersInfo, ApiCheersJoin} from "@/api/chat"
import {filterTime} from "@/filters/modules/system"
import StatusCheck from "./components/statusCheck"
import BackImgBtnRight from "./assets/back-btn-right.png"
import BackImgBtnLeft from "./assets/back-btn-left.png"
import BackImgMain from "./assets/main.png"
import IconStart from "./assets/Icon-start.png"
import IconTips from "./assets/Icon-tips.png"
import IconWine from "./assets/Icon-wine.png"
import IconBtn from "./assets/Icon-btn.png"
import "./index.less"

export default function NightPub() {
    const history = useHistory()

    const [statusParams, setStatusParams] = useState({
        visible: false, title: "酒馆已打烊", content: "今日已打烊，请明日再来"
    })
    const [detail, setDetail] = useState({
        start_time: 0, end_time: 0, remain_num: 0
    })
    const [isOpen, setIsOpen] = useState(false)

    const timerRef = useRef(null)

    const checkOpenStatus = useCallback(() => {
        const nowTime = dayjs().unix().valueOf()
        if (detail.start_time <= nowTime && nowTime < detail.end_time) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [detail.end_time, detail.start_time])

    const fetchData = useCallback(() => {
        const data = ApiCheersInfo()
        if (data) {
            setDetail(data)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    useEffect(() => {
        timerRef.current = setInterval(() => checkOpenStatus(), 1000)
    }, [checkOpenStatus]);

    useEffect(() => {
        if (timerRef.current && isOpen) {
            clearInterval(timerRef.current)
        }
    }, [isOpen])

    const handleJoin = useCallback(() => {
        const data = ApiCheersJoin()
        if (data) {
            history.push('/nightPub/matching');
        }
    }, [history])

    return (<Layout>
        <div className="night-pub-start">
            <div className="night-pub-start-main active">
                <Image src={BackImgMain}/>
                <div className="night-pub-start-main-support">
                    <div className="night-pub-start-main-support-left">
                        <div className="night-pub-start-main-support-left-inside"/>
                    </div>
                    <div className="night-pub-start-main-support-right">
                        <div className="night-pub-start-main-support-right-inside"/>
                    </div>
                </div>
            </div>

            <div className="night-pub-start-trade">
                {isOpen ? <div className="night-pub-start-trade-notice">
                    <div className="night-pub-start-trade-notice-left">
                        正在营业
                    </div>
                    <div className="night-pub-start-trade-notice-right">
                        <div className="night-pub-start-trade-notice-right-time">
                            {filterTime(detail.start_time, "HH:mm") + '-' + filterTime(detail.end_time, "HH:mm")}
                        </div>
                        <div className="night-pub-start-trade-notice-right-tips">
                            Open the door to love
                        </div>
                    </div>
                </div> : <div className="night-pub-start-trade-warning">
                    每晚
                    <div className="night-pub-start-trade-warning-time">20:00</div>
                    准时营业
                </div>}
            </div>

            <Button className="night-pub-start-btn">
                <div className="night-pub-start-btn-back">
                    <Image
                        className="night-pub-start-btn-back-left"
                        src={BackImgBtnLeft}
                    />
                    <Image
                        className="night-pub-start-btn-back-right"
                        src={BackImgBtnRight}
                    />
                </div>

                {isOpen ? <div className="night-pub-start-btn-main" onClick={() => handleJoin()}>
                    <div className="night-pub-start-btn-main-icon">
                        <Image src={IconStart}/>
                    </div>
                    <div className="night-pub-start-btn-main-txt">
                        轻触屏幕，立即开聊
                    </div>
                </div> : <div className="night-pub-start-btn-main">
                    <div className="night-pub-start-btn-main-icon">
                        <Image src={IconBtn}/>
                    </div>
                    <div className="night-pub-start-btn-main-txt">
                        预约卡座
                    </div>
                </div>}
            </Button>

            {isOpen ? <div className="night-pub-start-residue">
                <div className="night-pub-start-residue-text">今日剩余：{detail.remain_num}</div>
                <div className="night-pub-start-residue-icon">
                    <Image src={IconWine}/>
                </div>
            </div> : <div className="night-pub-start-tips">
                <div className="night-pub-start-tips-icon">
                    <Image src={IconTips}/>
                </div>
                <div className="night-pub-start-tips-text">玩法介绍</div>
            </div>}

            <StatusCheck
                visible={statusParams.visible}
                close={() => setStatusParams({...statusParams, visible: false})}
                title={statusParams.title}
                content={statusParams.content}
            />
        </div>

    </Layout>)
}
