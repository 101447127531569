import React, {FC, useState} from "react";
import {Image} from 'antd-mobile'
import Dialog from "@/components/dialog";
import ImageCommit from "../assets/commit-title.png";
import ImageService from '../assets/service-title.png'
import ImageSeal from "../assets/seal.png";
import {updateInfo} from "@/model/user";
import ImageSingle from '../assets/single.png'
import ImageUnSingle from '../assets/unsingle.png'
import ImageAgree from '../assets/agree.png'
import ImageUnAgree from '../assets/unagree.png'
import './index.less'

const Commitment:FC=({visible,callback})=>{

    const [success,setSuccess]=useState(false)
    const handleSubmit = (data) => {
        updateInfo(data, "base")
        if (data.hasOwnProperty("is_single") && data.is_single===1){
            setSuccess(true)
        }else{
            callback()
        }
        if (data.hasOwnProperty("accept_service")){
            callback()
        }
    }

    return(<Dialog display={visible} background={"linear-gradient(143deg, #FFB6BD -1%, #B2EBBD 43%, #A8C0F0 93%)"}>
        {   !success&&
            <div className='commitment'>
                <div className="commitment-item">
                    <div className='commitment-item-logo'>
                        <Image src={ImageCommit} />
                    </div>
                    <div className='commitment-item-content'>
                        <div className='commitment-item-content-title'>
                            本人在此做出单身承诺，现在婚姻状况未婚单身，如有不实愿意承担一切法律责任。
                        </div>
                        <div className='commitment-item-content-seal'>
                            <Image src={ImageSeal} />
                        </div>
                    </div>
                    <div className='commitment-item-single'>
                        <div className='commitment-item-single-btn' onClick={() => handleSubmit({is_single:1})}>
                            <Image src={ImageSingle} alt="" fit='cover'/>
                        </div>
                        <div className='commitment-item-single-btn unsingle' onClick={() => handleSubmit({is_single:2})}>
                            <Image src={ImageUnSingle} alt="" fit='cover'/>
                        </div>
                    </div>
                </div>
            </div>
        }
        { success&&
            <div className='commitment'>
                <div className="commitment-item">
                    <div className='commitment-item-logo'>
                        <Image src={ImageService} />
                    </div>
                    <div className='commitment-item-content'>
                        <div className='commitment-item-content-title'>
                            您是否愿意接受线下婚恋红娘服务；如一对一推荐、情感咨询等
                        </div>
                    </div>
                    <div className='commitment-item-single'>
                        <div className='commitment-item-single-btn' onClick={() => handleSubmit({accept_service:1})}>
                            <Image src={ImageAgree} alt="" fit='cover'/>
                        </div>
                        <div className='commitment-item-single-btn unsingle' onClick={() => handleSubmit({accept_service:2})}>
                            <Image src={ImageUnAgree} alt="" fit='cover'/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </Dialog>)
}

export default Commitment
