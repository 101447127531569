import React,{useState,FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView } from 'antd-mobile'
import "./index.less"

const Year:FC=({visible, callback, close,sendYear})=>{
    const [checkvisible, setcheckVisible] = useState(false)
    const [value, setValue] = useState('')
    const handleClose=()=>{
        close()
    }
    const startYear = 2000;
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        const label = year + '级';
        const value = label;
        years.push({ label, value });
    }

    const basicColumns = [years];
    const handlePickerChange=(value)=>{
        // console.log(value)
        setValue(value)
    }
    const handleYear=()=>{
        sendYear(value)
        close()
    }
    return(
        <Dialog display={visible} clickMask={() => handleClose()} background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="year">
                <div className="year-item">
                    <div className="year-item-title">
                        入学年份
                    </div>
                    <div className="year-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setcheckVisible(false)
                            }}
                            value={value}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="year-item-btn" onClick={()=>handleYear()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Year
