import React, {memo, useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Image, Swiper, Toast} from "antd-mobile"
import Popup from "@/components/popup"
import {ApiAssetRecharge, ApiRecharge} from "@/api/asset"
import {useUser} from "@/hooks/useUser"
import {calculateDiscount, filterPrice, getImageSrc} from "@/filters/modules/system"
import {invokePayment} from "@/plugin/wechat/official"
import IconCheck from "@/assets/icon/check.png"
import IconCoin from "@/assets/icon/coin.png"
import "./index.less"
import {getUserInfo} from "@/store/actions/getUserInfo";

const CompPaidPoint = ({visible, close}) => {
    const [currentId, setCurrentId] = useState(0)
    const [rechargeOptions, setRechargeOptions] = useState([])

    const {infoUser} = useUser()
    const configData = useSelector((state) => state.app.configData)
    const dispatch = useDispatch()
    const fetchData = useCallback(async () => {
        const data = await ApiAssetRecharge()
        if (data) {
            setRechargeOptions(data)
            setCurrentId(data[1].id)
        }
    }, [])

    useEffect(() => {
        visible && fetchData()
    }, [fetchData, visible])

    const handleOrdered = useCallback(async (args) => {
        const data = await ApiRecharge(args)
        if (data) {
            if (args.paid_type === "wechat") {
                invokePayment(data, () => {
                    dispatch(getUserInfo())
                    close()
                    Toast.show({content: '充值成功', icon: 'success'})
                }, () => {
                    Toast.show({content: '支付取消'})
                })
            }
        }
    }, [close, dispatch])

    const handleSubmit = async (e = {}) => {
        Toast.show({content: '支付中', icon: "loading"})
        handleOrdered({
            id: currentId, paid_type: "wechat"
        })
    }

    const renderSwiperItem = ({backImg, icon, boldText, thinText, cost}: any) => {
        return (<div className="suit-swiper-item">
            <div className="suit-swiper-item-main">
                <div className="suit-swiper-item-main-back">
                    <Image src={backImg} fit="contain"/>
                </div>
                <div className="suit-swiper-item-main-icon">
                    <Image src={icon} fit="contain"/>
                </div>
            </div>
            <div className="suit-swiper-item-bold">
                {boldText}
                <div className="suit-swiper-item-bold-cost">{cost}</div>
            </div>
            <div className="suit-swiper-item-thin">
                {thinText}
            </div>
        </div>)
    }

    // @ts-ignore
    return (<Popup display={visible} radius close={() => close()}>
        <div className="comp-point">
            <div className="header">
                <div className="header-title">
                    <div className="header-title-icon">
                        <Image src={IconCoin} mode="heightFix"/>
                    </div>
                    <div className="header-title-text">
                        心动币余额不足
                    </div>
                </div>
            </div>
            {Object.keys(configData).length > 0 && <div className="privileges">
                <Swiper
                    className="privileges-slider"
                    indicatorDots
                    indicatorColor="rgba(255, 147, 74, 0.3)"
                    indicatorActiveColor="#FF934A"
                    circular
                >
                    {configData.point_effect.map((item, index) => (<Swiper.Item key={index}>
                        {renderSwiperItem({
                            backImg: getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url),
                            icon: getImageSrc(item.icon, configData.static_url),
                            boldText: item.label,
                            thinText: item.tips,
                            cost: item.cost
                        })}
                    </Swiper.Item>))}
                </Swiper>
            </div>}
            <div className="options">
                {rechargeOptions.map((item, index) => (<div
                    key={index}
                    className={"options-item " + (item.id === currentId ? "active" : '')}
                    onClick={() => setCurrentId(item.id)}
                >
                    {item.origin_price > item.price && <div className="options-item-header">
                        限时{calculateDiscount(item.origin_price, item.price)}折
                    </div>}
                    {item.id === currentId && <div className="options-item-check">
                        <Image src={IconCheck} mode="heightFix"/>
                    </div>}
                    <div className="options-item-num">
                        <div className="options-item-num-icon">
                            <Image src={IconCoin} mode="heightFix"/>
                        </div>
                        <div className="options-item-num-text">
                            {item.val_point + item.give_point}
                        </div>
                    </div>
                    <div className="options-item-price">
                        ¥ {filterPrice(item.price)} 元
                    </div>
                </div>))}
            </div>
            <div className="bottom-actions">
                <Button className="bottom-actions-renew" onClick={() => handleSubmit()}>
                    立即开通
                </Button>
            </div>
        </div>
    </Popup>)
}

export default memo(CompPaidPoint)
