import React from "react"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import Index from "@/pages/index"
import Setting from "@/pages/setting"
import Trip from "@/pages/trip"
import TripOption from "@/pages/trip/option"
import TripStatus from "@/pages/trip/status"
import TripResult from "@/pages/trip/result"
import Spaceship from "@/pages/spaceship"
import SpaceshipOption from "@/pages/spaceship/option"
import SpaceshipStatus from "@/pages/spaceship/status"
import SpaceshipResult from "@/pages/spaceship/result"
import StrangerMatch from "@/pages/stranger/match"
import StrangerOption from "@/pages/stranger/option"
import StrangerStatus from "@/pages/stranger/status"
import StrangerResult from "@/pages/stranger/result"
import Shopping from "@/pages/shopping"
import Property from "@/pages/property"
import ShoppingConfirm from "@/pages/shopping/confirm"
import ShoppingStatus from "@/pages/shopping/status"
import ShoppingResult from "@/pages/shopping/result"
import MysterySubscribe from "@/pages/mystery/subscribe"
import MysteryResult from "@/pages/mystery/result"
import PickleResult from "@/pages/pickle/result"
import OfflineVipResult from "@/pages/offlineVip/result"

import PickleGeneral from '@/pages/pickle/popularize/general'
import PickleTtnzyp from '@/pages/pickle/popularize/ttnzyp'
import PickleTtnzypOne from '@/pages/pickle/popularize/ttnzypone'
import PickleTtnzypTwo from '@/pages/pickle/popularize/ttnzyptwo'
import PickleTtnzypThree from '@/pages/pickle/popularize/ttnzypthree'
import PickleAdvertising from '@/pages/pickle/popularize/advertising'


import Loading from "@/pages/loading";

import School from '@/pages/school'
import SchoolMeet from "@/pages/school/meet";
import SchoolWish from '@/pages/school/wish'
import SchoolCurrent from '@/pages/school/current'
import SchoolProfile from '@/pages/school/profile'
import SchoolStatus from '@/pages/school/status'
import SchoolResult from '@/pages/school/result'
import SchoolPopularizeOne from '@/pages/school/popularize/one'
import SchoolPopularizeTwo from '@/pages/school/popularize/two'
import SchoolPopularizeThree from '@/pages/school/popularize/three'

import SearchMap from '@/pages/searchLove/map'
import SearchResult from '@/pages/searchLove/result'
import SearchState from '@/pages/searchLove/state'

import WechatOfficial from '@/pages/wechat/official'
import WechatWork from '@/pages/wechat/work'

import Help from '@/pages/help'
import HelpDetail from '@/pages/help/detail'

import Mbti from '@/pages/mbti'
import MbtiOption from '@/pages/mbti/option'

import CertificateRealname from '@/pages/certificate/realname'
import CertificateEducation from '@/pages/certificate/education'
import CertificateProfession from '@/pages/certificate/profession'

import NightPub from "@/pages/nightPub"
import NightPubMatchIng from "@/pages/nightPub/matching"

import Guide from "@/pages/guide"
import GuideMoment from "@/pages/guide/moment"


export default function BaseRoute() {
    return (<BrowserRouter>
        <Switch>
            <Route exact path="/" component={Index}/>
            <Route exact path="/setting" component={Setting}/>
            <Route exact path="/property" component={Property}/>
            {/* 会员认证 */}
            <Route exact path='/certificate/realname' component={CertificateRealname}/>
            <Route exact path='/certificate/education' component={CertificateEducation}/>
            <Route exact path='/certificate/profession/:channel?' component={CertificateProfession}/>
            {/* 微信关注 */}
            <Route exact path='/wechat/official' component={WechatOfficial}/>
            <Route exact path='/wechat/work' component={WechatWork}/>
            {/* MBTI */}
            <Route exact path='/mbti' component={Mbti}/>
            <Route exact path='/mbti/option' component={MbtiOption}/>
            {/* 帮助中心 */}
            <Route exact path='/help' component={Help}/>
            <Route exact path='/help/:id?' component={HelpDetail}/>
            {/* 遇见号列车 */}
            <Route exact path="/trip" component={Trip}/>
            <Route exact path="/trip/option" component={TripOption}/>
            <Route exact path="/trip/status" component={TripStatus}/>
            <Route exact path="/trip/result" component={TripResult}/>
            {/* 心动号飞船 */}
            <Route exact path="/spaceship" component={Spaceship}/>
            <Route exact path="/spaceship/option" component={SpaceshipOption}/>
            <Route exact path="/spaceship/status" component={SpaceshipStatus}/>
            <Route exact path="/spaceship/result" component={SpaceshipResult}/>
            {/* 爱情买卖 */}
            <Route exact path="/shopping" component={Shopping}/>
            <Route exact path="/shopping/confirm" component={ShoppingConfirm}/>
            <Route exact path="/shopping/status" component={ShoppingStatus}/>
            <Route exact path="/shopping/result" component={ShoppingResult}/>
            {/* 完美陌生人 */}
            <Route exact path="/stranger/option" component={StrangerOption}/>
            <Route exact path="/stranger/status" component={StrangerStatus}/>
            <Route exact path="/stranger/match" component={StrangerMatch}/>
            <Route exact path="/stranger/result" component={StrangerResult}/>
            {/* 盲盒活动 */}
            <Route exact path="/mystery/subscribe" component={MysterySubscribe}/>
            <Route exact path="/mystery/result" component={MysteryResult}/>

            {/* 线上心动速配 */}
            <Route exact path="/pickle/result" component={PickleResult}/>

            {/* 线下vip活动 */}
            <Route exact path="/offlineVip/result" component={OfflineVipResult}/>

            {/*校园活动*/}
            <Route exact path='/school' component={School}/>
            <Route exact path='/school/meet' component={SchoolMeet}/>
            <Route exact path='/school/wish' component={SchoolWish}/>
            <Route exact path='/school/current' component={SchoolCurrent}/>
            <Route exact path='/school/profile' component={SchoolProfile}/>
            <Route exact path='/school/status' component={SchoolStatus}/>
            <Route exact path='/school/result' component={SchoolResult}/>
            <Route exact path='/school/one/:channel?' component={SchoolPopularizeOne}/>
            <Route exact path='/school/two/:channel?' component={SchoolPopularizeTwo}/>
            <Route exact path='/school/three/:channel?' component={SchoolPopularizeThree}/>


            {/*城市搜寻记*/}
            <Route exact path='/searchlove/map' component={SearchMap}/>
            <Route exact path='/searchlove/result' component={SearchResult}/>
            <Route exact path='/searchlove/state' component={SearchState}/>


            <Route exact path='/loading/:channel?' component={Loading}/>

            {/*落地页*/}
            <Route exact path='/pickle/:channel?' component={PickleGeneral}/>
            <Route exact path='/popularize/general/:channel?' component={PickleGeneral}/>
            <Route exact path='/popularize/ttnzyp/:channel?' component={PickleTtnzyp}/>
            <Route exact path='/popularize/ttnzypone/:channel?' component={PickleTtnzypOne}/>
            <Route exact path='/popularize/ttnzyptwo/:channel?' component={PickleTtnzypTwo}/>
            <Route exact path='/popularize/ttnzypthree/:channel?' component={PickleTtnzypThree}/>
            <Route exact path='/popularize/advertising/:channel?' component={PickleAdvertising}/>

            {/* 酒馆 */}
            <Route exact path='/nightPub' component={NightPub}/>
            <Route exact path='/nightPub/matching' component={NightPubMatchIng}/>

            {/* 指导 */}
            <Route exact path='/guide' component={Guide}/>
            <Route exact path='/guide/moment' component={GuideMoment}/>

            {/* 拦截路由 */}
            <Route path="*" exact render={() => (<Redirect to="/"/>)}/>

        </Switch>
    </BrowserRouter>)
}

