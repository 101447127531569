import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {DatePicker, Image, Input, Picker, Toast} from "antd-mobile";
import {InformationCircleOutline} from "antd-mobile-icons";
import url from "url";
import Layout from "@/layout";
import {ApiDetail, ApiSetFinish} from "@/api/group";
import {formatDate, getDate, getDateObject} from "@/filters/modules/system";
import {useUser} from "@/hooks/useUser";
import {updateInfo} from "@/model/user";
import {heightOptions} from "../options";
import ImageConfirmHeader from "../assets/confirm-header.png";
import IconEdit from "../assets/icon-edit.png"
import IconSpeaker from "../assets/icon-speaker.png"
import "./index.less"

export default function Confirm() {
    const [form, setForm] = useState({
        wechat: "", birthday: "", height: 0,
    })
    const [birthdayVisible, setBirthdayVisible] = useState(false)
    const [heightVisible, setHeightVisible] = useState(false)

    const heights = heightOptions()
    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const {infoUser, infoAttr} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            if (data.is_finish === 1) {
                history.push("/shopping/status?group_id=" + query.group_id)
            }
        }
    }, [history, isLogin, query.group_id])

    useEffect(() => {
        let tempForm = {}
        if (Object.keys(infoUser).length) {
            if (infoUser.birthday) {
                tempForm = {wechat: infoUser.phone, birthday: infoUser.birthday, height: infoUser.height}
            } else {
                tempForm = {wechat: infoUser.phone, birthday: getDate(), height: infoUser.height}
            }
        }
        if (Object.keys(infoAttr).length && infoAttr.hasOwnProperty("base") && infoAttr.base.hasOwnProperty("wechat")) {
            tempForm = {...tempForm, wechat: infoAttr.base.wechat.value}
        }
        setForm(tempForm)
    }, [infoAttr, infoUser])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSubmit = async () => {
        if (!form.wechat) {
            return Toast.show({
                icon: <InformationCircleOutline/>,
                content: "请输入您的微信",
                maskClickable: false,
                duration: 1000
            })
        }
        if (!form.birthday) {
            return Toast.show({
                icon: <InformationCircleOutline/>,
                content: "请选择您的生日",
                maskClickable: false,
                duration: 1000
            })
        }
        if (!form.height) {
            return Toast.show({
                icon: <InformationCircleOutline/>,
                content: "请选择您的身高",
                maskClickable: false,
                duration: 1000
            })
        }
        const data = await ApiSetFinish({group_id: query.group_id})
        if (!data) {
            return Toast.show({
                icon: <InformationCircleOutline/>,
                content: "引力消失了～",
                maskClickable: false,
                duration: 1000
            })
        }
        updateInfo(form, "base")
        history.push("/shopping/status?group_id=" + query.group_id)
    }

    return (<Layout bgcolor="linear-gradient(180deg, #9E4FF8 0%, #B53EF0 100%)">
        <div className="shopping-confirm">
            <div className="shopping-confirm-header">
                <Image src={ImageConfirmHeader}/>
            </div>
            <div className="shopping-confirm-card">
                <div className="shopping-confirm-card-item">
                    <div className="shopping-confirm-card-item-label">
                        微信号码
                    </div>
                    <div className="shopping-confirm-card-item-input">
                        <Input
                            className="shopping-confirm-card-item-input-fill"
                            value={form.wechat}
                            placeholder="请输入您的微信号码"
                            onChange={(val) => setForm({...form, wechat: val})}
                        />
                        <div className="shopping-confirm-card-item-input-icon">
                            <Image src={IconEdit}/>
                        </div>
                    </div>
                </div>
                <div className="shopping-confirm-card-item">
                    <div className="shopping-confirm-card-item-label">
                        您的生日
                    </div>
                    <div className="shopping-confirm-card-item-input" onClick={() => setBirthdayVisible(true)}>
                        <div className="shopping-confirm-card-item-input-fill">
                            {form.birthday}
                        </div>
                        <div className="shopping-confirm-card-item-input-icon">
                            <Image src={IconEdit}/>
                        </div>
                    </div>
                    {!!form.birthday && <DatePicker
                        title='时间选择'
                        visible={birthdayVisible}
                        onClose={() => {
                            setBirthdayVisible(false)
                        }}
                        defaultValue={getDateObject(form.birthday)}
                        min={getDateObject("1970-01-01")}
                        max={new Date()}
                        onConfirm={val => setForm({...form, birthday: formatDate(val)})}
                    />}
                </div>
                <div className="shopping-confirm-card-item">
                    <div className="shopping-confirm-card-item-label">
                        您的身高
                    </div>
                    <div className="shopping-confirm-card-item-input" onClick={() => setHeightVisible(true)}>
                        <div className="shopping-confirm-card-item-input-fill">
                            {form.height ? form.height + "cm" : ""}
                        </div>
                        <div className="shopping-confirm-card-item-input-icon">
                            <Image src={IconEdit}/>
                        </div>
                    </div>
                    <Picker
                        columns={heights}
                        visible={heightVisible}
                        onClose={() => {
                            setHeightVisible(false)
                        }}
                        value={[form.height]}
                        onConfirm={v => setForm({...form, height: v[0]})}
                    />
                </div>
                <div className="shopping-confirm-card-tips">
                    <div className="shopping-confirm-card-tips-icon">
                        <Image src={IconSpeaker}/>
                    </div>
                    <div className="shopping-confirm-card-tips-text">
                        所填信息将用于匹配成功后发送给匹配对象
                    </div>
                </div>
            </div>
            <div className="shopping-confirm-bottom">
                <div
                    className="shopping-confirm-bottom-button"
                    onClick={() => handleSubmit()}
                >
                    确认
                </div>
            </div>
        </div>
    </Layout>)
}
