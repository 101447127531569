import React, {useState} from "react"
import {Button, Image, Toast} from "antd-mobile";
import Layout from "@/layout";
import {isValidVerificationCode} from "@/utils/common"
import {ApiCertificate} from "@/api/user"
import IconEducationIcon from "../assets/education-icon.png"
import IconService from "../assets/service.png"
import ImageFile from "../assets/education-file.png"
import ImageFile1 from "../assets/education-file1.png"
import ImageFile2 from "../assets/education-file2.png"
import ImageFile3 from "../assets/education-file3.png"
import "./index.less"

const Education = () => {
    const [agreed, setAgreed] = useState(false)
    const [form, setForm] = useState({
        type: 1, detail: {
            verify_code: ""
        }
    })

    const handleSubmit = async () => {
        if (!agreed) {
            return Toast.show({
                content: '请先勾选用户信息授权协议',
            })
        }
        if (!isValidVerificationCode(form.detail.verify_code)) {
            return Toast.show({
                content: '在线验证码格式不正确',
            })
        }
        const data = await ApiCertificate(form)
        if (data) {
            return Toast.show({
                icon: 'success',
                content: '提交认证信息成功',
                afterClose: () => {
                    window.$wx.miniProgram.navigateBack({delta: 1})
                }
            })
        }
    }

    return (
        <Layout>
            <div className="education">
                <div className="education-title">
                    如何获取学信网在线验证码？
                </div>
                <div className="education-container">
                    <div className="education-container-item">
                        <div className="education-container-item-title">
                            ①访问“中国高等教育学生信息网-学信档案”
                        </div>
                        <div className="education-container-item-subTitle">
                            <div className="education-container-item-subTitle-icon">
                                <Image src={IconEducationIcon}></Image>
                            </div>
                            <div className="education-container-item-subTitle-text">
                                复制学信网查询地址-浏览器打开
                            </div>
                        </div>
                        <div className="education-container-main">
                            <div className="education-container-main-top">
                                <div className="education-container-main-top-left">
                                    <Image src={ImageFile} mode="widthFix"/>
                                </div>
                                <div className="education-container-main-top-right">
                                    <ul>
                                        <li>浏览器打开地址</li>
                                        <li>已有账号-登录</li>
                                        <li>没有账号-注册后登录</li>
                                        <li>点击【在线验证报告】</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="education-container-main-top">
                                <div className="education-container-main-top-left">
                                    <Image src={ImageFile1} mode="widthFix"/>
                                </div>
                                <div className="education-container-main-top-right">
                                    <ul className="education-container-main-top-right-red">
                                        <li>若没有到达此页面，请点击导航栏-【学历查询】-【本人查询】</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="education-container-item">
                        <div className="education-container-item-title">
                            ②进入学信档案信息，点击【在线验证报告】-【教育部学籍在线
                            验证报告】
                        </div>
                        <div className="education-container-file">
                            <div className="education-container-file-img">
                                <Image src={ImageFile2}/>
                            </div>
                        </div>
                    </div>

                    <div className="education-container-item">
                        <div className="education-container-item-title">
                            ③完成申请后复制在线验证码，粘贴并提交
                        </div>
                        <div className="education-container-item-subtitle">
                            （请确保验证码状态为“有效”，否则请点击“延长验证有效期”）
                        </div>
                        <div className="education-container-file">
                            <div className="education-container-file-img">
                                <Image src={ImageFile3} mode="widthFix"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="education-bottom">
                    <div className="education-bottom-item">
                        <div className="education-bottom-item-label">
                            在线验证码
                        </div>
                        <div className="education-bottom-item-value">
                            <input
                                placeholder="输入12-18位编号"
                                min={12}
                                max={18}
                                value={form.detail.verify_code}
                                onChange={(e) => setForm({
                                    ...form, detail: {
                                        verify_code: e.target.value.trim()
                                    }
                                })}
                            />
                        </div>
                    </div>
                    <div className="education-bottom-item">
                        <div className="education-bottom-item-contactWay">
                            <div className="education-bottom-item-contactWay-icon">
                                <Image src={IconService} mode="widthFix"/>
                            </div>
                            <div className="education-bottom-item-contactWay-text">
                                联系客服
                            </div>
                        </div>
                        <div
                            className="education-bottom-item-action"
                        >
                            <Button
                                className="education-bottom-item-action-btn"
                                disabled={!form.detail.verify_code}
                                onClick={() => handleSubmit()}
                            >
                                提交
                            </Button>
                        </div>
                    </div>
                    <div className="education-bottom-agreement">
                        <div className="education-bottom-agreement-radio" onClick={() => setAgreed(!agreed)}>
                            {agreed && <div className="education-bottom-agreement-radio-active"></div>}
                        </div>
                        <div className="education-bottom-agreement-text">
                            我已查看并同意 <a href="/protocol.html">《用户信息授权协议》</a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Education
