import React, {FC, useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import url from "url";
import {birthDetail} from '@/filters/modules/system';
import Layout from "@/layout";
import {updateAttrs, updateInfo} from "@/model/user";
import {useUser} from '@/hooks/useUser'
import Warning from '../components/warning'
import Address from "../components/address";
import Character from "./components/character";
import Hobby from './components/hobby'
import ImageText from "@/pages/school/assets/text.png";
import ImageGo from "@/pages/school/assets/go.png";
import ImageBlackBoard from './assets/blackboard.png'
import ImageClouds from './assets/clouds.png'
import './index.less'


const Classmeet: FC = () => {

    const [infoForm, setInfoForm] = useState({
        province: "", city: "", district: ""
    })
    const [attrForm, setAttrForm] = useState({
        personality: "", hobby: ""
    })
    const {infoUser, infoAttr} = useUser(false)
    const {search} = useLocation()

    const {query} = url.parse(search, true)
    const [AddressVisible, setAddressVisible] = useState(false)
    const [CharacterVisible, setCharacterVisible] = useState(false)
    const [HobbyVisible, setHobbyVisible] = useState(false)

    const [UnfinishedVisible, setUnfinishedVisible] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (infoUser) {
            const userForm = {
                province: "", city: "", district: ""
            }
            const userKeys = Object.keys(infoUser)
            userKeys.forEach((item) => {
                if (userForm.hasOwnProperty(item)) {
                    userForm[item] = infoUser[item]
                }
            })
            setInfoForm(userForm)
        }
        if (infoAttr.hasOwnProperty('base')) {
            const attrTempForm = {
                personality: "", hobby: ""
            }
            const attrKeys = Object.keys(infoAttr.base)
            attrKeys.forEach((item) => {
                if (attrTempForm.hasOwnProperty(item)) {
                    attrTempForm[item] = infoAttr.base[item].value
                }
            })
            setAttrForm(attrTempForm)
        }
    }, [infoUser, infoAttr])

    const go = () => {
        history.push('/school?group_id=' + query.group_id)
    }
    const handleAddress = (address) => {
        setInfoForm({
            ...infoForm, province: address[0] || "", city: address[1] || "", district: address[2] || ""
        });
    }

    const handleCharacter = (character) => {
        setAttrForm({...attrForm, personality: character})
    }

    const handleHobby = (hobby) => {
        attrForm.hobby = hobby.map(item => item.label)
        setAttrForm({...attrForm, hobby: attrForm.hobby})
    }

    const handleSubmit = () => {
        if (!infoForm.province || !infoForm.city || !attrForm.personality || !attrForm.hobby) {
            setUnfinishedVisible(true);
        } else {
            updateInfo(infoForm, "base")
            updateAttrs(attrForm, "base")
            history.push('/school/wish?group_id=' + query.group_id)
        }
    }

    return (<Layout bgcolor="#FDF9F5">
        <div className="meet">
            <div className="meet-text">
                <img src={ImageText} alt=""/>
            </div>
            <div className="meet-go animate__animated animate__fadeInRight" onClick={() => go()}>
                <img src={ImageGo} alt=""/>
            </div>
            <div className="meet-black animate__animated animate__fadeInRight">
                <img src={ImageBlackBoard} alt=""/>
            </div>
            <div className="meet-cont animate__animated animate__fadeInRight">
                <div className="meet-cont-item">
                    <div className="meet-cont-item-img">
                        <img src={ImageClouds} alt=""/>
                    </div>
                    <div className="meet-cont-item-content">
                        <span>大家好，我是<span>{infoUser.realname}</span>， 来自
                            {!infoForm.city && !infoForm.province &&
                                <span onClick={() => setAddressVisible(true)}>【输入位置】</span>}

                            {infoForm.city && infoForm.province && <span onClick={() => setAddressVisible(true)}>
                                    {infoForm.province}-{infoForm.city}
                                </span>}。
                            <span>
                                <br/>
                        </span>
                        </span>
                        <span>我是{birthDetail(infoUser.birthday).constellation}
                            ，我的性格是
                            <span onClick={() => setCharacterVisible(true)}>
                                {!attrForm.personality ? "【请选择】" : attrForm.personality}
                            </span>
                            ，我喜欢
                            <span onClick={() => setHobbyVisible(true)}>
                                {!attrForm.hobby ? "【请选择】" : ((typeof attrForm.hobby) === 'object' ? attrForm.hobby.join("、") : attrForm.hobby)}
                            </span>
                            希望能认识兴趣相投的朋友，希望我的大学时光， 不留遗憾，快乐而美好，谢谢大家
                        </span>
                    </div>
                </div>
            </div>
            <div className="meet-btn animate__animated animate__fadeInUp" onClick={() => handleSubmit()}>
                <div className='meet-btn-title'>
                    介绍完毕
                </div>
                <div className="meet-btn-subtitle">
                    INTRODUCE
                </div>
            </div>
        </div>
        <Address
            visible={AddressVisible}
            close={() => setAddressVisible(false)}
            callback={handleAddress}
        />
        <Character
            visible={CharacterVisible}
            close={() => setCharacterVisible(false)}
            callback={handleCharacter}
        />
        <Hobby
            visible={HobbyVisible}
            close={() => setHobbyVisible(false)}
            callback={(val) => handleHobby(val)}
        />
        <Warning
            message={{
                title: "你还没有完成自我介绍", subTitle: "请完成自我介绍，进入下一环节", btnText: "继续填写"
            }}
            visible={UnfinishedVisible}
            close={() => setUnfinishedVisible(false)}
        />
    </Layout>)
}
export default Classmeet
