import fetch from "@/plugin/axios"

export const ApiOfficialCode = (params) => (fetch({
    url: "v5/wechat/official-code", params
}))

export const ApiJsConfig = (params) => (fetch({
    url: "v5/wechat/js-config", params
}))
export const ApiLandingPage = (params) => (fetch({
    url: "v5/wechat/outside-chain", params
}))

export const ApiWorkCode = (params) => (fetch({
    url: "v5/wechat/work-code", method:'POST',params
}))
