import React from "react"
import {Image, Button} from "antd-mobile"
import Layout from "@/layout";
import guideTop from "./assets/top.png"
import guideCenter from "./assets/center.png"
import guideBottom from "./assets/bottom.png"
import "./index.less"

const Guide = () => {
    return (
        <Layout bgcolor="#fff" needLogin={false} needWechat={false}>
            <div className="page-guide">
                <div className="page-guide-top">
                    <Image src={guideTop}/>
                </div>

                <div className="page-guide-center">
                    <Image src={guideCenter}/>
                </div>

                <div className="page-guide-bottom">
                    <Image src={guideBottom}/>
                </div>

                <div className="page-guide-box">
                    <Button
                        className="page-guide-box-btn"
                        onClick={() => {
                            window.$wx.closeWindow()
                            window.$wx.miniProgram.navigateBack({delta: 1})
                        }}
                    >
                        开启心动之旅
                    </Button>
                </div>
            </div>
        </Layout>
    )
}
export default Guide
