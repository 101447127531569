import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Image, Toast} from "antd-mobile";
import {CheckOutline} from "antd-mobile-icons";
import copy from 'copy-to-clipboard';
import url from "url";
import Layout from "@/layout";
import {useUser} from "@/hooks/useUser";
import {ApiMatchResult} from "@/api/group";
import IconBreakHeart from "../assets/break-heart.png";
import "./index.less"

export default function Result() {
    const [matchWechat, setMatchWechat] = useState([])

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiMatchResult({group_id: query.group_id})
            setMatchWechat(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const copyWechat = (wechat) => {
        copy(wechat)
        Toast.show({
            icon: <CheckOutline/>, content: "复制成功", maskClickable: false, duration: 1000, afterClose: () => {
                window.$wx.miniProgram.navigateBack({delta: 1})
            },
        })
    }

    const handleClick = () => {
        window.$wx.miniProgram.navigateBack({delta: 1})
    }

    return (<Layout bgcolor="#361C4C">
        <div className="trip-result">
            <div className="trip-result-card">
                {matchWechat.length === 0 && <div className="trip-result-card-icon">
                    <Image src={IconBreakHeart}/>
                </div>}
                {matchWechat.length === 0 && <div className="trip-result-card-header">
                    <div className="trip-result-card-header-text">
                        很遗憾，本次活动未能成功匹配
                    </div>
                    <div className="trip-result-card-header-text">
                        或许属于你的Soulmate就在下次活动中
                    </div>
                </div>}

                {matchWechat.length > 0 && <div className="trip-result-card-header">
                    <div className="trip-result-card-header-text">
                        经过漫长的旅行
                    </div>
                    <div className="trip-result-card-header-text">
                        您的Soulmate已来到
                    </div>
                </div>}
                {matchWechat.length > 0 && matchWechat.map((item, index) => (
                    <div key={index} className="trip-result-card-wechat">
                        <div className="trip-result-card-wechat-text">
                            {item.wechat}
                        </div>
                        <div className="trip-result-card-wechat-btn" onClick={() => copyWechat(item.wechat)}>
                            复制
                        </div>
                    </div>))}

                {matchWechat.length > 0 && <div className="trip-result-card-bottom">
                    <div className="trip-result-card-bottom-tips">
                        发送好友申请时请填写暗号 你们的心动暗号是
                    </div>
                    <div className="trip-result-card-bottom-cipher">
                        “心动即可行动”
                    </div>
                </div>}

            </div>

            <div className="trip-result-button">
                <div className="trip-result-button-begin" onClick={() => handleClick()}>
                    看看新活动
                </div>
            </div>
        </div>
    </Layout>)
}
