import React, {FC, useCallback, useEffect, useState} from "react";
import {Image, Toast} from 'antd-mobile'
import {ApiWorkCode} from "@/api/wechat";
import {ApiSpecialResult} from "@/api/activity";
import Layout from "@/layout";
import {useUser} from '@/hooks/useUser'
import {generateSessionKey} from "@/plugin/easemob/Func";
import ImageStateTitle from '../assets/state-title.png'
import ImageStateSubTitle from '../assets/state-subtitle.png'
import ImageStateSteps from '../assets/state-steps.png'
import './index.less'


const State: FC = () => {
    const [qrcode, setQrcode] = useState("")
    const [matched, setMatched] = useState([])
    const {infoUser} = useUser(true)

    const fetchData = useCallback(async () => {
        const data = await ApiSpecialResult({origin_code: "searchLove"})
        setMatched(data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const fetchQrcode = useCallback(async () => {
        if (infoUser.uid && matched.uid) {
            const data = await ApiWorkCode({
                state: '恋爱搜寻记',
                page: "/pages/chat/index?scene=" + encodeURIComponent("targetId=" + matched.uid + "&sessionKey=" + generateSessionKey(infoUser.uid, matched.uid)),
                share: {
                    media_id: "2YAT-_sQ0o9f3U2hD0onLYZRUKyWF0Ua1iJHrKmM0UIl3EibFQtodvtvPvfuCdNMa",
                    title: '戳这里~领取你的匹配对象,快去和ta打招呼吧'
                }
            })
            setQrcode(data)
        }
    }, [infoUser.uid, matched])

    const getWechatObject = useCallback(() => {
        fetchQrcode()
        Toast.show({
            icon: "loading", content: "加载中…", duration: 0,
        })
        window.$wx.ready(() => Toast.clear())
    }, [fetchQrcode])

    useEffect(() => {
        getWechatObject()
    }, [getWechatObject])

    const previewQrcode = () => {
        window.$wx.previewImage({
            current: qrcode, urls: [qrcode]
        })
    }


    return (<Layout>
        <div className="State">
            <div className="State-content">
                <div className="State-content-item">
                    <div className="State-content-item-title">
                        <Image src={ImageStateTitle}></Image>
                    </div>
                    <div className="State-content-item-subtitle">
                        <Image src={ImageStateSubTitle}></Image>
                    </div>
                    <div className="State-content-item-qrcode" onClick={() => previewQrcode()}>
                        <Image src={qrcode}></Image>
                    </div>
                    <div className="State-content-item-steps">
                        <Image src={ImageStateSteps}></Image>
                    </div>
                </div>
            </div>

        </div>
    </Layout>)
}
export default State
