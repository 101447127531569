import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import url from "url";
import {Toast} from "antd-mobile";
import Layout from "@/layout";
import {ApiRankMatch} from "@/api/group";
import {getImageSrc} from "@/filters/modules/system";
import ImageResultTop from "../assets/result-top.png"
import ImageResultText from "../assets/result-text.png"
import ImageResultEach from "../assets/result-each.png"
import "./index.less"

export default function Match() {
    const [userLoses, setUserLoses] = useState([])
    const [userEachs, setUserEachs] = useState([])

    const configData = useSelector((state) => state.app.configData)

    const {search} = useLocation()
    const {query} = url.parse(search, true)

    const getResult = useCallback(async () => {
        if (query.group_id) {
            Toast.show({
                icon: "loading", content: "加载中…", duration: 0,
            })
            const data = await ApiRankMatch({group_id: query.group_id})
            setUserLoses(data.user_lose)
            setUserEachs(data.user_each)
            Toast.clear()
        }
    }, [query.group_id])

    useEffect(() => {
        getResult()
    }, [getResult])

    return (<Layout bgcolor="#5a0ba8" refresh={() => getResult()} needLogin={false}>
        <div className="containner-result">
            <div className="top-image">
                <img src={ImageResultTop} alt=""/>
            </div>
            <div className="main-image">
                <img src={ImageResultText} alt=""/>
            </div>
            {(userEachs.length > 0 && Object.keys(configData).length) && <div className="info-each">
                <div className="info-each-title">
                    恭喜他们成为本场最佳CP
                </div>
                <div className="info-each-list">
                    {userEachs.map((item, index) => (<div className="info-each-list-item" key={index}>
                        <div className="each-item-card">
                            <div className="each-item-card-user">
                                <img
                                    src={getImageSrc(item.info_male.avatar, configData.static_url)}
                                    alt={item.info_male.nickname}
                                />
                            </div>
                            <div className="each-item-card-symbol">
                                <img src={ImageResultEach} alt=""/>
                            </div>
                            <div className="each-item-card-user">
                                <img
                                    src={getImageSrc(item.info_female.avatar, configData.static_url)}
                                    alt={item.info_female.nickname}
                                />
                            </div>
                        </div>
                        <div className="each-item-num">
                            <div className="each-item-num-top">
                                本场共互选
                            </div>
                            <div className="each-item-num-bottom">
                                <div className="each-item-num-bottom-num">
                                    {item.each_nums}
                                </div>
                                <div className="each-item-num-bottom-unit">
                                    次
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>}
            <div className="info-lose">
                <div className="info-lose-title">
                    没有匹配到的朋友不要灰心
                </div>
                <div className="info-lose-title">
                    或许命中注定Ta就在下一场 ......
                </div>
                {userLoses.length > 0 && <div className="info-lose-list">
                    {userLoses.map((item, index) => (<div className="info-lose-list-item" key={index}>
                        <div className="lose-item-user">
                            <img
                                src={getImageSrc(item.avatar, configData.static_url)}
                                alt={item.nickname}
                            />
                        </div>
                        <div className="lose-item-line"/>
                        <div className="lose-item-text">
                            {item.gender === 1 ? "他" : "她"}被选中 {item.num} 次
                        </div>
                    </div>))}

                </div>}
            </div>
        </div>
    </Layout>)
}
