import React, {FC, useState} from "react";
import {Input, Image, Toast} from 'antd-mobile'
import Dialog from "@/components/dialog";
import ImageBus from './assets/bus.png'
import ImageSubway from './assets/subway.png'
import ImageTaxi from './assets/taxi.png'
import ImageWalk from './assets/walk.png'
import ImageBtn from './assets/vehicle-btn.png'
import ImageTravel from './assets/vehicle-travel.png'
import ImageAddress from './assets/vehicle-address.png'
import './index.less'

const Vehicle: FC = ({visible, callback}) => {
    const [form, setForm] = useState({
        mode: '', destination: ''
    })

    const handleClick = (val: number) => {
        setForm({...form, mode: val})
    };

    const handleConfirm = () => {
        if (!form.mode) {
            Toast.show({content: '选择交通工具', duration: 2000})
        }else if(!form.destination){
            Toast.show({content: '请填写商圈地址', duration: 2000})
        }else {
            callback(form);
        }
    };
    return (<Dialog
        display={visible}
        background={"linear-gradient(143deg, #FFB6BD -1%, #B2EBBD 43%, #A8C0F0 93%)"}
    >
        <div className="vehicle">
            <div className="vehicle-item">
                <div className="vehicle-item-title">
                    <Image src={ImageTravel}></Image>
                </div>
                <div className="vehicle-item-way">
                    <div
                        className={`vehicle-item-way-item ${form.mode === 1 ? "selected" : ""}`}
                        onClick={() => handleClick(1)}
                    >
                        <div className="vehicle-item-way-item-text">
                            公交
                        </div>
                        <Image src={ImageBus}/>
                    </div>
                    <div
                        className={`vehicle-item-way-item ${form.mode === 2 ? "selected" : ""}`}
                        onClick={() => handleClick(2)}
                    >
                        <div className="vehicle-item-way-item-text">
                            地铁
                        </div>
                        <Image src={ImageSubway}/>
                    </div>
                    <div
                        className={`vehicle-item-way-item ${form.mode === 3 ? "selected" : ""}`}
                        onClick={() => handleClick(3)}
                    >
                        <div className="vehicle-item-way-item-text">
                            驾车
                        </div>
                        <Image src={ImageTaxi}/>
                    </div>
                    <div
                        className={`vehicle-item-way-item ${form.mode === 4 ? "selected" : ""}`}
                        onClick={() => handleClick(4)}
                    >
                        <div className="vehicle-item-way-item-text">
                            步行
                        </div>
                        <Image src={ImageWalk}/>
                    </div>
                </div>
                <div className="vehicle-item-work">
                    <div className="vehicle-item-work-title">
                        <Image src={ImageAddress}></Image>
                    </div>
                    <div className="vehicle-item-work-input">
                        <Input
                            placeholder='请输入工作地（商圈、区域）'
                            onChange={(val) => setForm({...form, destination: val})}
                        />
                    </div>
                </div>

                <div className="vehicle-item-btn" onClick={() => handleConfirm()}>
                    <Image src={ImageBtn}/>
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Vehicle
