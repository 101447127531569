import store from "@/store/instance"
import * as USER_ACTION from "@/store/actions/user"
import * as USER_API from "@/api/user"
import { getUriJson } from "@/utils/common"
import { getUrlParam, urlDelParam } from "@/filters/modules/system";
import { getUserInfo } from "@/store/actions/getUserInfo";
/**
 * @param callback
 * @returns {Promise<boolean|*>}
 * @constructor
 */
export async function userLogin (callback = null) {
    const tokenInfo = store.getState().user.infoToken
    if (!(tokenInfo instanceof Object) || !tokenInfo.hasOwnProperty("access_token")) {
        const urlParams = getUrlParam()
        let data = {}
        if (urlParams.hasOwnProperty("suid")) {
            data = await USER_API.ApiDirtyLogin({ uid: urlParams.suid })
        } else {
            const params = officialAuth()
            if (params.hasAuth === false) {
                return window.location.href = params.authUrl
            }
            data = await USER_API.ApiLogin(params)
        }
        if (data) {
            store.dispatch(USER_ACTION.setTokenInfo(data))

            if (data.hasOwnProperty("build") && data.build) {
                setTimeout(() => store.dispatch(getUserInfo()), 200)
            }
        }
        if (urlParams.hasOwnProperty("code")) {
            return setTimeout(() => window.location.replace(urlDelParam(window.location.href, "code")), 500)
        }
    }
    if (callback) {
        setTimeout(() => callback(), 1000)
    }
    return true
}


/**
 * 公众号登陆
 * @returns {boolean|{login_type: string, wechat_code: *}}
 */
export function officialAuth () {
    const linkUrl = window.location.href
    const params = getUriJson(linkUrl)
    if (!params.hasOwnProperty("code")) {
        const appid = "wx64a843b498689e84"
        const uri = encodeURIComponent(linkUrl)

        return {
            login_type: "wechat_web",
            hasAuth: false,
            authUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        }
    }

    return {
        wechat_code: params.code, code: params.code, login_type: "wechat_web", hasAuth: true
    }
}

/**
 * @returns {boolean}
 */
export function checkLogin () {
    let loginStatus
    const storeUser = store.getState().user
    if (!Object.keys(storeUser.infoToken).length || !storeUser.infoToken.build) {
        loginStatus = false
    } else {
        loginStatus = !!Object.keys(storeUser.infoToken).length
    }
    store.dispatch(USER_ACTION.setIsLogin(loginStatus))
}


/**
 *
 * @param params
 * @param attrPart
 * @returns {Promise<any>}
 * updateInfo({province:'xxx',city:'xxxx},'base')
 */
export async function updateInfo (params, attrPart) {
    await USER_API.ApiSaveData({ ...params, attr_part: attrPart })
    const infoUser = Object.assign({}, store.getState().user.infoUser, params)
    store.dispatch(USER_ACTION.setUserInfo(infoUser))

    return infoUser
}

/**
 *
 * @param params
 * @returns {Promise<any>}
 */
export async function updateInfoLocal (params) {
    const infoUser = Object.assign({}, store.getState().user.infoUser, params)
    store.dispatch(USER_ACTION.setUserInfo(infoUser))
    return infoUser
}

/**
 * 更新用户属性
 * @param params
 * @param type
 * @returns {Promise<*>}
 *
 */
export async function updateAttrs (params, type) {
    const data = await USER_API.ApiSaveInfo({ ...params, info_type: type })
    const infoAttr = store.getState().user.infoAttr
    const tempAttr = infoAttr
    if (infoAttr !== undefined) {
        tempAttr[type] = Object.assign({}, infoAttr[type], data.user_attr[type])
    } else {
        tempAttr[type] = data.user_attr[type]
    }
    store.dispatch(USER_ACTION.setUserAttr(tempAttr))
}
