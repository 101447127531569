import fetch from "@/plugin/axios"

export const ApiDetail = (params) => (fetch({
    url: "v5/group/detail", method: "GET", params,
}))

export const ApiGetCurrent = (params) => (fetch({
    url: "v5/group/get-current", method: "GET", params,
}))

export const ApiIdentityPiece = (params) => (fetch({
    url: "v5/group/identity-piece", method: "GET", params,
}))

export const ApiCheckSelect = (params) => (fetch({
    url: "v5/group/check-select", method: "GET", params,
}))

export const ApiSaveSelect = (params) => (fetch({
    url: "v5/group/save-select", method: "POST", params,
}))

export const ApiSetFinish = (params) => (fetch({
    url: "v5/group/set-finish", method: "POST", params,
}))

export const ApiRankMatch = (params) => (fetch({
    url: "v5/group/rank-match", method: "GET", params,
}))

export const ApiMatchResult = (params) => (fetch({
    url: "v5/group/match-result", method: "GET", params,
}))
