import React, {FC, useCallback, useEffect, useState} from "react";
import {Image} from 'antd-mobile'
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import url from "url";
import Layout from "@/layout";
import {ApiMatchResult} from "@/api/group";
import {useUser} from "@/hooks/useUser";
import {generateSessionKey} from "@/plugin/easemob/Func";
import {birthDetail, getImageSrc} from "@/filters/modules/system";
import ImageText from '@/pages/school/assets/text.png'
import ImageGo from "@/pages/school/assets/go.png";
import ImageHeart from './assets/result-heart.png'
import ImageResultLogo from './assets/result-logo.png'
import ImageUnmatchedLogo from './assets/unmatch-logo.png'
import ImageLeft from './assets/unmatch-left.png'
import ImageRight from './assets/unmatch-right.png'
import './index.less'

const Result: FC = () => {
    const [matched,setMatched] =useState([])
    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)
    const configData = useSelector((state) => state.app.configData)
    const locationInfo = useSelector(state => state.app.locationInfo);

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiMatchResult({group_id: query.group_id})
            setMatched(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const go = () => {
        history.push('/school/profile?group_id=' + query.group_id)
    }

    // 返回首页
    const handleHome=()=>{
            window.$wx.closeWindow()
            window.$wx.miniProgram.switchTab({url: "/pages/home/index"})
    }

    // 去聊天
    const handleChat = () => {
        matched.forEach((item)=>{
            if (item.info.uid && item.match_uid) {
                window.$wx.miniProgram.navigateTo({url: "/pages/chat/index?targetId=" + item.matched_uid + "&sessionKey=" + generateSessionKey(item.info.uid, item.match_uid)})
            }
        })
    }

   const renderIntro = ()=>{
        let introString = "我来自"
           matched.forEach((item)=>{
           if(item.info.province&&item.info.city){
               introString+=item.info.province+"-"+item.info.city+"，现在"
           }else {
               introString+="--，现在"
           }
           if(item.hasOwnProperty("attr")&&item.attr.base.hasOwnProperty('marital_status')){
               introString+=item.attr.base.marital_status.value+"，在"
           }else {
               introString+="单身，在"
           }
           if (locationInfo.province && locationInfo.city) {
               introString += locationInfo.province + "-" + locationInfo.city + "做"
           } else {
               introString += "-- 做"
           }
           if(item.hasOwnProperty('attr')&& item.attr.base.hasOwnProperty('work')){
               if((typeof item.attr.base.work.value)==="string"){
                   introString+=item.attr.base.work.value+"工作"
               }else {
                   introString += "- 工作"
               }
           }
       })
       return introString
   }

   const rendWish =()=>{
       let result = '';
        matched.forEach(item=>{
          result+= "我希望ta在"+item.attr.matched['temp@school@place'].value+"认识，是"+item.attr.matched['temp@school@pursue'].value+"，期望ta是"+(item.attr.matched.personality.value).join(',')+"的人，想和ta一起在"+item.attr.matched['temp@school@do'].value+"。"
        })
       return result
   }

    return (
        <Layout bgcolor='#FDF9F5'>
            {
                matched.length>0 &&
                <div className="result">
                    {/*背景文字*/}
                    <div className="result-text">
                        <Image src={ImageText} alt=""/>
                    </div>
                    <div className="result-go" onClick={() => go()}>
                        <Image src={ImageGo} alt=""/>
                    </div>
                    {/*主体内容*/}
                    {
                        matched.map((item,index)=>{
                            return (
                                <div className="result-main" key={index}>
                                    <div className="result-main-logo">
                                        <div className="result-main-logo-img">
                                            <Image src={ImageResultLogo} alt=""/>
                                        </div>
                                    </div>
                                    <div className="result-main-infor">
                                        <div className="result-main-infor-left">
                                            <div className="result-main-infor-left-item">
                                                <div className="result-main-infor-left-item-label">
                                                    姓名
                                                </div>
                                                <div className="result-main-infor-left-item-content">
                                                    {item.attr.base.realname.value}
                                                </div>
                                            </div>
                                            <div className="result-main-infor-left-item">
                                                <div className="result-main-infor-left-item-label">
                                                    性别
                                                </div>
                                                <div className="result-main-infor-left-item-content">
                                                    {item.info.sex===1?"男":"女"}
                                                </div>
                                            </div>
                                            <div className="result-main-infor-left-item">
                                                <div className="result-main-infor-left-item-label">
                                                    生日
                                                </div>
                                                <div className="result-main-infor-left-item-content">
                                                    {item.attr.base.birthday.value}-{birthDetail(item.attr.base.birthday.value).constellation}
                                                </div>
                                            </div>
                                            <div className="result-main-infor-left-item">
                                                <div className="result-main-infor-left-item-label">
                                                    院校
                                                </div>
                                                <div className="result-main-infor-left-item-content">
                                                    {item.attr.base.college.value} {item.attr.base.college_year.value}
                                                </div>
                                            </div>
                                            <div className="result-main-infor-left-item">
                                                <div className="result-main-infor-left-item-label">
                                                    喜欢
                                                </div>
                                                <div className="result-main-infor-left-item-content">
                                                    {item && item.attr.base.hobby.value ? ((typeof item.attr.base.hobby.value) === "object" ? item.attr.base.hobby.value.join("，") : item.attr.base.hobby.value) : "你没选择兴趣爱好"}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="result-main-infor-right">
                                            <div className="result-main-infor-right-top">
                                                <Image src={getImageSrc(item.info.avatar, configData.static_url)} alt=""/>
                                            </div>
                                            <div className="result-main-infor-right-center">
                                                人物性格
                                            </div>
                                            <div className="result-main-infor-right-bottom">
                                                【{item.attr.base.personality.value}】
                                            </div>
                                        </div>
                                    </div>
                                    <div className="result-main-intrance">
                                        <div className="result-main-intrance-item">
                                            <div className="result-main-intrance-item-label">
                                                自我介绍
                                            </div>
                                            <div className="result-main-intrance-item-content">
                                                {renderIntro()}
                                            </div>
                                        </div>
                                        <div className="result-main-intrance-item">
                                            <div className="result-main-intrance-item-label">
                                                恋爱愿望清单
                                            </div>
                                            <div className="result-main-intrance-item-content">
                                                {rendWish()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/*按钮*/}
                    <div className="result-btn" onClick={()=>handleChat()}>
                        <div className='result-btn-title'>
                            和ta聊聊
                        </div>
                        <div className="result-btn-subtitle">
                            CHAT
                        </div>
                    </div>
                </div>
            }

            {/*未匹配*/}
            {
                matched.length===0 &&
                <div className="unmatch">
                    {/*背景文字*/}
                    <div className="unmatch-text">
                        <Image src={ImageText} alt=""/>
                    </div>
                    {/*上一步按钮*/}
                    <div className="unmatch-go" onClick={() => go()}>
                        <Image src={ImageGo} alt=""/>
                    </div>
                    {/*上边logo*/}
                    <div className="unmatch-logo">
                        <Image src={ImageUnmatchedLogo} alt=""/>
                    </div>
                    {/*主体内容*/}
                    <div className="unmatch-content">
                        <div className="unmatch-content-top">
                            <div className="unmatch-content-top-left">
                                <Image src={ImageLeft} alt=""/>
                            </div>
                            <div className="unmatch-content-top-main">
                                很遗憾
                            </div>
                            <div className="unmatch-content-top-right">
                                <Image src={ImageRight} alt=""/>
                            </div>
                        </div>
                        <div className="unmatch-content-center">
                            本次活动未能成功匹配
                        </div>
                        <div className="unmatch-content-bottom">
                            <div className="unmatch-content-bottom-item">
                                或许属于你的Soulmate就在下次活动中
                            </div>
                            <div className="unmatch-content-bottom-item">
                                欢迎下次参与
                            </div>
                        </div>
                        <div className="unmatch-content-heart">
                            <Image src={ImageHeart} alt=""/>
                        </div>
                    </div>
                    {/*底部按钮*/}
                    <div className="unmatch-btn" onClick={()=>handleHome()}>
                        <div className='unmatch-btn-title'>
                            去首页看看
                        </div>
                        <div className="unmatch-btn-subtitle">
                            CHAT
                        </div>
                    </div>
                </div>
            }

        </Layout>
    )
}
export default Result
