import fetch from "@/plugin/axios"


// 地区
export const ApiDistricts = (params) => (fetch({
    url: "v5/info/districts", method: "GET", params,
}))


// 职业
export const ApiIndustries = (params) => (fetch({
    url: "v5/info/industries", method: "GET", params,
}))

//大学
export const ApiColleges = () => (fetch({
    url: "v5/info/colleges", method: "GET",
}))
