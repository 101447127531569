import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Image, Toast} from "antd-mobile";
import {CheckOutline} from "antd-mobile-icons";
import copy from "copy-to-clipboard";
import url from "url";
import Layout from "@/layout";
import {ApiMatchResult} from "@/api/group";
import {useUser} from "@/hooks/useUser";
import ImageBackgroundResult from "../assets/backgroun-result.png";
import "./index.less"

export default function Result() {
    const [matchWechat, setMatchWechat] = useState([])

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiMatchResult({group_id: query.group_id})
            setMatchWechat(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const copyWechat = (wechat) => {
        copy(wechat)
        Toast.show({
            icon: <CheckOutline/>, content: "复制成功", maskClickable: false, duration: 1000
        })
    }

    const handleClick = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.switchTab({url: "/pages/home/index"})
    }

    return (<Layout bgcolor="#5a0ba8">
        <div className="stranger-result">
            <div className="stranger-result-header">
                <Image src={ImageBackgroundResult} mode="widthFix"/>
            </div>
            {matchWechat.length > 0 && <div className="stranger-result-content">
                <div className="stranger-result-content-title">
                    恭喜你！成功组成CP
                </div>
                <div className="stranger-result-content-text">
                    <div className="stranger-result-content-text-line">
                        你们通过了十万分之一的概率相遇
                    </div>
                    <div className="stranger-result-content-text-line">
                        你们彼此有互相吸引对方的特质
                    </div>
                    <div className="stranger-result-content-text-line">
                        恭喜你们组成CP
                    </div>
                    <div className="stranger-result-content-text-line">
                        接下来请珍惜这份极小概率的相遇
                    </div>
                    <div className="stranger-result-content-text-line">
                        去认识、去约会、去恋爱....
                    </div>
                </div>

                <div className="stranger-result-content-box">
                    {matchWechat.map((item, index) => (<div key={index} className="stranger-result-content-box-wechat">
                        <div className="stranger-result-content-box-wechat-text">
                            {item.wechat}
                        </div>
                        <div
                            className="stranger-result-content-box-wechat-btn"
                            onClick={() => copyWechat(item.wechat)}
                        >
                            复制
                        </div>
                    </div>))}

                    <div className="stranger-result-content-box-text">
                        发送好友申请时请填写暗号。你们的心动暗号是
                    </div>
                    <div className="stranger-result-content-box-tips">
                        “心动即可行动”
                    </div>
                </div>

                <div className="stranger-result-content-btn" onClick={() => handleClick()}>
                    去首页逛逛
                </div>
            </div>}
            {matchWechat.length === 0 && <div className="stranger-result-content">
                <div className="stranger-result-content-title">
                    很遗憾
                </div>
                <div className="stranger-result-content-title">
                    没有匹配到最佳CP
                </div>
                <div className="stranger-result-content-text">
                    <div className="stranger-result-content-text-line">
                        每个人的时间线不同，相遇节点也不同
                    </div>
                    <div className="stranger-result-content-text-line">
                        不要气馁，不要放弃
                    </div>
                    <div className="stranger-result-content-text-line">
                        即使这次没能收获爱情，也希望你能收获友情
                    </div>
                    <div className="stranger-result-content-text-line">
                        或者属于你的心动频率
                    </div>
                    <div className="stranger-result-content-text-line">
                        就在下一场心动实验
                    </div>
                </div>
                <div className="stranger-result-content-btn">
                    <div className="stranger-result-content-box-btn" onClick={() => handleClick()}>
                        去首页逛逛
                    </div>
                </div>
            </div>}
        </div>
    </Layout>)
}
