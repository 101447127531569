import React, {FC, useCallback, useEffect, useState} from "react"
import {CascaderView} from 'antd-mobile'
import Dialog from "@/components/dialog"
import {ApiDistricts} from '@/api/school'
import {transferCascaderOptions, updateCascaderSelected} from "@/filters/modules/system"
import "./index.less"

const Address: FC = ({visible, callback, close}) => {
    const [options, setOptions] = useState([]);
    const [selects, setSelects] = useState([]);
    const [address, setAddress] = useState([])

    const fetchData = async (pid = 0) => {
        const data = await ApiDistricts({parent_id: pid});
        if (data) {
            return transferCascaderOptions(data);
        }
        return []
    }

    const initData = useCallback(async () => {
        const data = await fetchData();
        setOptions(data)
    }, [])

    useEffect(() => {
        initData()
    }, [initData]);

    const handleChange = async (values, extend) => {
        const currentVal = values[values.length - 1]
        const currentItem = extend.items[extend.items.length - 1]
        let tempOptions = Array.from(options)
        if (currentItem.has_children) {
            if (currentItem.children.length === 0) {
                const childrenOptions = await fetchData(currentVal)
                updateCascaderSelected(tempOptions, currentVal, {children: childrenOptions})
            }
        } else {
            updateCascaderSelected(tempOptions, currentVal, {children: null})
        }
        const tempAddress = []
        for (const pidKey in values) {
            tempAddress.push(extend.items[pidKey].label)
        }
        setSelects(values)
        setAddress(tempAddress)
        setOptions(tempOptions);
    };

    const handleSubmit = () => {
        callback(address);
        close();
    }

    return (<Dialog display={visible} clickMask={() => close()}
                    background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
        <div className="address">
            <div className="address-item">
                <div className="address-item-title">
                    你的位置
                </div>
                <div className="address-item-check">
                    {options.length > 0 && <CascaderView
                        visible={options.length > 0}
                        options={options}
                        value={selects}
                        cols={selects.length}
                        onChange={(val, extend) => handleChange(val, extend)}
                    />}
                </div>
                <div className="address-item-btn" onClick={handleSubmit}>
                    确定
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Address;
