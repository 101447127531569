import React, {useCallback, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import url from "url";
import Layout from "@/layout";
import {ApiDetail} from "@/api/group";
import {useUser} from "@/hooks/useUser";
import "./index.less"

export default function Spaceship() {
    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            if (Object.keys(data.selecteds).length === data.questions.length) {
                history.push("/spaceship/status?group_id=" + query.group_id)
            }
            if (Object.keys(data.selecteds).length > 0 && Object.keys(data.selecteds).length < data.questions.length) {
                history.push("/spaceship/option?group_id=" + query.group_id)
            }
        }
    }, [history, isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (<Layout bgcolor="#441d81">
        <div className="spaceship-start">
            <div className="spaceship-start-card">
                <div className="spaceship-start-card-title">
                    旅行者须知
                </div>
                <div className="spaceship-start-card-content">
                    <div>这是一场关于遇见恋爱的旅程</div>
                    <div>登上心动号飞船</div>
                    <div>你将独自完成这一趟奇妙的遇见之旅</div>
                    <div>心动号飞船会带你经历各个站点</div>
                    <div>青涩→成长→现实→成熟 →初心→理想→遇见</div>
                    <div>每个站点都会需要你与自己进行对话</div>
                    <div>当你抵达终点，你将会得到一名旅客的微信号</div>
                    <div>心动引力圈会根据你们的选择</div>
                    <div>遇见属于你的soulmate</div>
                </div>
            </div>
            <div className="spaceship-start-button">
                <div
                    className="spaceship-start-button-begin"
                    onClick={() => history.push("/spaceship/option?group_id=" + query.group_id)}
                >
                    开始旅行
                </div>
            </div>
        </div>
    </Layout>)
}
