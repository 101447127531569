import React, {FC, useEffect, useState} from "react";
import { Input, Image} from 'antd-mobile'
import {useHistory} from 'react-router-dom'
import Layout from "@/layout";
import {useUser} from "@/hooks/useUser";
import {updateAttrs, updateInfo} from "@/model/user";
import {options} from '../options'
import Birthday from "../components/birthday";
import Warning from '../components/warning'
import ImageBtn from '../assets/basic-btn.png'
import ImageLabel from '../assets/label.png'
import ImageTitle from '../assets/result-title.png'
import ImageWoman from '../assets/result-woman.png'
import ImageMan from '../assets/result-man.png'
import ImageCheckWoman from '../assets/checkWoman.png'
import ImageCheckMan from '../assets/checkMan.png'
import './index.less'


const Basic: FC = () => {

    const [infoForm, setInfoForm] = useState({
        realname: '', birthday: '',  sex: 0
    })
    const [attrForm, setAttrForm] = useState({
        education: "",
    })
    const history = useHistory()

    const {infoUser, infoAttr} = useUser(true)
    const [birthdayVisible, setBirthdayVisible] = useState(false)
    const [warningVisible, setWarningVisible] = useState(false)

    useEffect(() => {
        if (Object.keys(infoUser).length) {
            let infoTempForm = {
                realname: '', birthday: '', sex: 0
            }
            const infoKeys = Object.keys(infoUser)
            infoKeys.forEach((item) => {
                if (infoTempForm.hasOwnProperty(item) && infoUser[item]) {
                    infoTempForm[item] = infoUser[item]
                }
            })
            setInfoForm(infoTempForm)
        }
        if (infoAttr.hasOwnProperty('base')) {
            const attrTempForm = {
                 education: "",
            }
            const attrKeys = Object.keys(infoAttr.base)
            attrKeys.forEach((item) => {
                if (attrTempForm.hasOwnProperty(item)) {
                    attrTempForm[item] = infoAttr.base[item].value
                }
            })
            setAttrForm(attrTempForm)
        }
    }, [infoAttr, infoUser])


    const handleSubmit = () => {
        if (!attrForm.education || !infoForm.realname || !infoForm.sex || !infoForm.birthday ) {
            setWarningVisible(true)
        } else {
            localStorage.setItem("searchLove[currentPoint]", 0)
            updateInfo(infoForm, "base")
            updateAttrs(attrForm, "base")
            history.push('/searchLove/map')
        }
    }

    const handleEducation = (education) => {
        setAttrForm({ ...attrForm, education });
    };


    return (<Layout needCopyRight={false}>
        <div className="basic">
            <div className="basic-label">
                <Image src={ImageLabel} fit="contain"></Image>
            </div>
            {/*基本信息和按钮*/}
            <div className="basic-content">
                <div className="basic-content-info">
                    <div className="basic-content-info-title">
                        <Image src={ImageTitle} fit="contain"></Image>
                    </div>
                    {/*信息表格*/}
                    <div className="basic-content-info-form">
                        {/*表格内容*/}
                        <div className="basic-content-info-form-main">
                            <div className="basic-content-info-form-main-top">
                                <div className="basic-content-info-form-main-top-left">
                                    <div className="basic-content-info-form-main-top-left-item">
                                        <div className="basic-content-info-form-main-top-left-item-lable">
                                            姓名
                                        </div>
                                        <div className="basic-content-info-form-main-top-left-item-value">
                                            <div className="basic-content-info-form-main-top-left-item-value-input">
                                                <Input value={infoForm.realname}
                                                       onChange={(val) => setInfoForm({...infoForm, realname: val})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basic-content-info-form-main-top-left-item" >
                                        <div className="basic-content-info-form-main-top-left-item-lable">
                                            性别
                                        </div>
                                        <div className="basic-content-info-form-main-top-left-item-value">
                                            <div className="basic-content-info-form-main-top-left-item-value-sex">
                                                <div
                                                    onClick={() => setInfoForm({...infoForm, sex: 2})}
                                                    className={`basic-content-info-form-main-top-left-item-value-sex-item sexItem ${infoForm.sex === 2 ? 'active' : ''}`}
                                                >
                                                    <Image src={infoForm.sex === 2 ? ImageCheckWoman : ImageWoman} />
                                                </div>
                                                <div
                                                    onClick={() => setInfoForm({...infoForm, sex: 1})}
                                                    className={`basic-content-info-form-main-top-left-item-value-sex-item ${infoForm.sex === 1 ? 'active' : ''}`}
                                                >
                                                    <Image src={infoForm.sex === 1 ? ImageCheckMan : ImageMan} />
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="basic-content-info-form-main-top-left-item">
                                        <div className="basic-content-info-form-main-top-left-item-lable">
                                            年龄
                                        </div>
                                        <div className="basic-content-info-form-main-top-left-item-value">
                                            <div className="basic-content-info-form-main-top-left-item-value-input"
                                                 onClick={() => setBirthdayVisible(true)}>
                                                {infoForm.birthday}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basic-content-info-form-main-top-left-item">
                                        <div className="basic-content-info-form-main-top-left-item-lable">
                                            学历
                                        </div>

                                        <div className="basic-content-info-form-main-top-left-item-value">
                                            <div className="basic-content-info-form-main-top-left-item-value-education">
                                                {options.education.map((item, index) => (
                                                    <div
                                                        className={`basic-content-info-form-main-top-left-item-value-education-item ${
                                                            attrForm.education === item.value ? "selected" : ""
                                                        }`}
                                                        key={index}
                                                        onClick={() => handleEducation(item.value)}
                                                    >
                                                        {item.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*按钮*/}
                <div className="basic-content-btn" onClick={() => handleSubmit()}>
                    <Image src={ImageBtn}/>
                </div>
            </div>

        </div>

        <Birthday
            visible={birthdayVisible}
            close={() => setBirthdayVisible(false)}
            sendBirthday={(birthday) => setInfoForm({...infoForm, birthday: birthday})}
        />

        <Warning
            message={{
                title: "你还没有完成自我介绍", subTitle: "请完成自我介绍，进入下一环节", btnText: "继续填写"
            }}
            visible={warningVisible}
            close={() => setWarningVisible(false)}
        />
    </Layout>)
}
export default Basic
