import React, {useCallback, useEffect, useReducer, useRef} from "react"
import {RootStateOrAny, useSelector} from "react-redux"
import {Button, CascadePicker, Image, ImageUploader, Input, TextArea, Toast} from "antd-mobile"
import {useHistory, useParams} from 'react-router-dom'
import Layout from "@/layout"
import {getImageSrc} from "@/filters/modules/system"
import {useUser} from "@/hooks/useUser"
import {ApiUpload} from "@/api/file"
import {ApiCertificate} from "@/api/user"
import {ApiPositions} from "@/api/system"
import IconPlus from "@/assets/icon/plus.png"
import IconService from "../assets/service.png"
import IconWork from "../assets/work.png"
import IconRight from "../assets/icon-right.png"
import "./index.less"


const Work = () => {
    const initialState = {
        isShow: 0,
        agreed: false,
        visible: false,
        currentSelect: [],
        currentOption: {
            label: '', value: 0, id: 0, hasChildren: true, children: []
        },
        form: {
            type: 2, remark: "", detail: {
                profession_type: -1, company: "", profession: ""
            }
        }
    }
    const reducer = (prevState, action) => {
        switch (action.type) {
            case "isShow":
                return {...prevState, isShow: action.data}
            case "agreed":
                return {...prevState, agreed: action.data}
            case "visible":
                return {...prevState, visible: action.data}
            case "setDetail":
                return {
                    ...prevState, form: {
                        ...prevState.form, detail: {
                            ...prevState.form.detail, ...action.data
                        }
                    }
                }
            case "form":
                return {...prevState, form: {...prevState.form, ...action.data}}
            case "currentAppend":
                return {
                    ...prevState,
                    currentOption: action.data.currentOption,
                    currentSelect: [...prevState.currentSelect, ...action.data.currentSelect]
                }
            case "extra":
                return {...prevState, ...action.data}
            default:
                return prevState
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const {channel} = useParams()
    const {infoUser} = useUser(true)
    const history = useHistory()
    const optionsRef = useRef([])
    const configData = useSelector((state: RootStateOrAny) => state.app.configData)
    const fileList = useRef([])

    let normal = {
        top: "请上传含有企业名称及职业信息的个人有效工作证件", bottom: "如：工牌、名片、在职证明、工资单企业微信or钉钉名片等"
    }
    let free = {
        top: "请上传含有职业信息相关证明的图像或视频资料", bottom: "如：委托合同，自媒体主页，作品，工作照等"
    }

    const formatBackendDataForUI = useCallback((data) => {
        const tempItems = []
        data.forEach((item, _) => {
            tempItems.push({
                label: item.name,
                value: item.id,
                id: item.id,
                hasChildren: item.has_children,
                children: item.has_children ? [] : undefined
            })
        })
        return tempItems
    }, [])

    const fetchData = useCallback(async (pid = 0) => {
        const data = await ApiPositions(pid)
        if (data) {
            return formatBackendDataForUI(data)
        }
        return []
    }, [formatBackendDataForUI])

    const updateMultiLOption = useCallback((data, values, level = 0, children = []) => {
        if (values.length === 0) {
            return children
        }
        for (let i = 0; i < data.length; i++) {
            if (values[level] !== data[i].value) {
                continue
            }
            if (data[i].hasChildren === false) {
                break
            }
            if (values.length === level + 1) {
                data[i].children = children
                break
            }
            data[i].children = updateMultiLOption(data[i].children, values, level + 1, children)
        }
        return data
    }, [])

    const handleLevelData = useCallback(async (pid = 0, currentSelect) => {
        const data = await fetchData(pid)
        optionsRef.current = updateMultiLOption(optionsRef.current, currentSelect, 0, data)
        dispatch({
            type: "currentAppend", data: {currentOption: data[0], currentSelect: [data[0].id]}
        })
    }, [fetchData, updateMultiLOption])

    useEffect(() => {
        if (state.currentOption.hasChildren && state.currentOption.children.length === 0) {
            handleLevelData(state.currentOption.value, state.currentSelect)
        }
    }, [handleLevelData, state.currentOption.children, state.currentOption.hasChildren, state.currentOption.value, state.currentSelect])

    useEffect(() => {
        dispatch({
            type: "isShow", data: Number(channel) === 1 ? 1 : Number(channel) === 2 ? 2 : 0
        })
    }, [channel])

    useEffect(() => {
        dispatch({
            type: "setDetail", data: {
                profession_type: Number(channel) === 1 ? 0 : Number(channel) === 2 ? 1 : -1
            }
        })
    }, [channel])

    const searchMultiLOption = useCallback((data, values, level = 0) => {
        let tempOption = {}
        for (let i = 0; i < data.length; i++) {
            if (values[level] !== data[i].value) {
                continue
            }
            tempOption = data[i]
            if (data[i].hasChildren === false) {
                break
            }
            if (data[i].children.length === 0) {
                break
            }
            return searchMultiLOption(data[i].children, values, level + 1)
        }
        return tempOption
    }, [])

    const handleOptionSelect = async (values) => {
        const tempSelect = []
        for (let i = 0; i < values.length; i++) {
            if (values[i] === null) {
                break
            }
            tempSelect.push(values[i])
        }
        const tempOption = searchMultiLOption(optionsRef.current, values.filter((item) => !!item))
        dispatch({
            type: "extra", data: {
                currentSelect: tempSelect, currentOption: tempOption
            }
        })
        dispatch({type: "setDetail", data: {profession: tempOption.label}})
    }

    const getWorkType = (data) => {
        if (data === 0) {
            history.push("/certificate/profession/1")
        } else {
            history.push("/certificate/profession/2")
        }
    }

    const handleSubmit = async () => {
        if (Number(channel) === 1 && !state.form.detail.company) {
            Toast.show({
                content: "企业名称不能为空",
            })
            return
        }
        if (!state.form.detail.profession) {
            Toast.show({
                content: "职业不能为空",
            })
            return
        }
        if (fileList.current.length === 0) {
            Toast.show({
                content: "请上传凭证",
            })
            return
        }
        if (Number(channel) === 2 && !state.form.remark) {
            Toast.show({
                content: "请补充说明",
            })
            return
        }
        if (!state.agreed) {
            Toast.show({
                content: "请勾选用户信息授权协议",
            })
            return
        }
        const data = await ApiCertificate({...state.form, attachments: fileList.current})
        if (data) {
            return Toast.show({
                icon: 'success',
                content: '提交认证信息成功',
                afterClose: () => {
                    window.$wx.miniProgram.navigateBack({delta: 1})
                }
            })
        }
    }

    const handleUpload = async (file) => {
        const data = await ApiUpload({directory: "user_header", "uid": infoUser.uid}, file)
        if (data) {
            fileList.current = [data]
        }
        return {
            url: getImageSrc("data", configData.static_url)
        }
    }

    const renderUpdateElement = (text) => {
        return (<div className="work-prove-types">
            <div className="work-prove-types-type">
                <div className="work-prove-types-type-left">
                    职业类型
                </div>
                <Button
                    className="work-prove-types-type-right"
                    onClick={() => dispatch({type: "visible", data: true})}
                >
                    {state.form.detail.profession ? state.form.detail.profession : "请选择职业类型"}
                </Button>
                <div className="work-prove-types-type-icon">
                    <Image src={IconRight}/>
                </div>
            </div>
            <CascadePicker
                title='级联选择'
                options={optionsRef.current}
                visible={state.visible}
                value={state.currentSelect}
                onClose={() => dispatch({type: "visible", data: false})}
                onConfirm={(val) => handleOptionSelect(val)}
                onSelect={(val) => handleOptionSelect(val)}
            />
            <div className="work-prove-types-upload">
                <div className="work-prove-types-upload-left">
                    上传凭证
                </div>
                <div className="work-prove-types-upload-main">
                    <ImageUploader
                        maxCount={1}
                        style={{'--cell-size': '140px'}}
                        value={fileList.current}
                        upload={handleUpload}
                        onChange={(e) => {
                            fileList.current = e
                        }}
                    >
                        {
                            fileList.current.length === 0 &&
                            <div className="work-prove-types-upload-main-text">
                                <div className="work-prove-types-upload-main-text-icon">
                                    <Image src={IconPlus}/>
                                </div>
                                <div className="work-prove-types-upload-main-text-item">
                                    {text.top}
                                </div>
                                <div className="work-prove-types-upload-main-text-item">
                                    {text.bottom}
                                </div>
                            </div>
                        }
                    </ImageUploader>
                </div>
            </div>
        </div>)
    }

    return (<Layout>
        <div className="work">
            {state.isShow === 0 ? (<div className="work-header">
                <div className="work-header-top">
                    <div className="work-header-top-left">
                        <div className="work-header-top-left-icon">
                            <Image src={IconWork}/>
                        </div>
                        <div className="work-header-top-left-text">
                            职业认证
                        </div>
                    </div>
                    <div className="work-header-top-right">
                        仅作审核，腾讯云加密存储
                    </div>
                </div>
                <div className="work-header-bottom">
                    认证声明：为保障真实、安全社交环境，所有用户须进行职业认证。仅作认证，企业信息不会被展示。
                </div>
            </div>) : (<div className="work-typeheader">
                <div className="work-typeheader-top">
                    <div className="work-typeheader-top-left">
                        <div className="work-typeheader-top-left-icon">
                            <Image src={IconWork}/>
                        </div>
                        <div className="work-typeheader-top-left-text">
                            {state.isShow === 1 && "在职员工职业认证"}
                            {state.isShow === 2 && "自由职业者职业认证"}
                        </div>
                    </div>
                    <div className="work-typeheader-top-right">
                        仅作审核，腾讯云加密存储
                    </div>
                </div>
            </div>)}
            {state.isShow === 0 && (<div className="work-form">
                <div className="work-form-title">
                    请选择您的认证方式
                </div>
                <div className="work-form-way">
                    <div
                        className="work-form-way-item"
                        onClick={() => getWorkType(0)}
                    >
                        在职员工
                    </div>
                    <div
                        className="work-form-way-item"
                        onClick={() => getWorkType(1)}>
                        自由职业
                    </div>
                </div>
            </div>)}
            {state.isShow === 1 && (<div className="work-normal">
                <div className="work-normal-name">
                    <div className="work-normal-name-left">
                        企业名称
                    </div>
                    <Input
                        className="work-normal-name-right"
                        placeholder="输入企业名称"
                        onChange={(e) => dispatch({
                            type: "setDetail", data: {
                                company: e.trim()
                            }
                        })}
                    />
                </div>

                {renderUpdateElement(normal)}

                <div className="work-normal-submit">
                    <div className="work-normal-submit-tips">
                        注：若为个体工商或企业主，请上传营业执照
                    </div>
                    <div className="work-normal-submit-action">
                        <Button
                            className="work-normal-submit-action-btn"
                            disabled={!state.agreed}
                            onClick={handleSubmit}
                        >
                            提交认证
                        </Button>
                    </div>
                </div>
            </div>)}

            {state.isShow === 2 && (<div className="work-free">
                {renderUpdateElement(free)}
                <div className="work-free-illustrate">
                    <div className="work-free-illustrate-title">补充说明</div>
                    <TextArea
                        className="work-free-illustrate-content"
                        placeholder="请针对上传的凭证资料，对其来源及内容进行详细描述"
                        onChange={(e) => dispatch({type: "form", data: {remark: e.trim()}})}
                    />
                </div>
                <div className="work-free-illustrate-action">
                    <Button
                        className="work-free-illustrate-action-btn"
                        disabled={!state.agreed}
                        onClick={handleSubmit}
                    >
                        提交认证
                    </Button>
                </div>
            </div>)}

            <div className="work-below">
                {state.isShow !== 0 && (<div className="work-below-check">
                    <div
                        className="work-below-check-radio"
                        onClick={() => {
                            dispatch({
                                type: "agreed", data: !state.agreed
                            })
                        }}

                    >
                        {state.agreed && <div className="work-below-check-radio-active"></div>}
                    </div>
                    <div className="work-below-check-text">我已查看并同意 <a
                        href="/protocol.html">《用户信息授权协议》</a></div>
                </div>)}
                <div className="work-below-official">
                    <div className="work-below-official-icon">
                        <Image src={IconService}/>
                    </div>
                    <div className="work-below-official-text">
                        联系客服
                    </div>
                </div>
            </div>
        </div>
    </Layout>)
}

export default Work
