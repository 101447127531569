import React, {useState, FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView} from 'antd-mobile'
import "./index.less"

const Things: FC = ({visible, callback, close}) => {
    const [checkvisible, setchechVisible] = useState(false)
    const [value, setValue] = useState(['M'])
    const handleClose = () => {
        close()
    }
    const basicColumns = [
        [
            {label: '校园散步', value: '校园散步'},
            {label: '食堂吃饭', value: '食堂吃饭'},
            {label: '图书馆学习', value: '图书馆学习'},
            {label: '打游戏', value: '打游戏'},
            {label: '去逛街', value: '去逛街'},
            {label: '刷剧', value: '刷剧'},
            {label: '参加派对', value: '参加派对'},
            {label: '看电影', value: '看电影'},
            {label: '看演唱会', value: '看演唱会'},
        ],

    ]
    const handlePickerChange = (value) => {
        setValue(value)
    }
    const handleThings = () => {
        callback(value)
        close()
    }
    return (
        <Dialog display={visible} clickMask={() => handleClose()}
                background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="things">
                <div className="things-item">
                    <div className="things-item-title">
                        想和对方做什么事
                    </div>
                    <div className="things-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setchechVisible(false)
                            }}
                            value={value}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="things-item-btn" onClick={() => handleThings()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Things
