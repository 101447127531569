import React, {FC, useCallback, useEffect, useState} from "react";
import url from "url";
import {useLocation, useParams} from "react-router-dom";
import Layout from "@/layout";
import {ApiLandingPage} from "@/api/wechat";
import ImageThree from '../assets/itemthree.png'
import './index.less'

const Three: FC = () => {
    const [location, setLocation] = useState('')
    const {channel} = useParams()

    const {search} = useLocation()
    const {query} = url.parse(search, true)


    const fetchData = useCallback(async () => {
        const params = {
            path: "/pages/activity/school/index", activity_id: 173, origin_code: "school", ad_proxy: 'richer'
        }
        if (channel) {
            params['channel_code'] = channel
        }
        if (query.hasOwnProperty("clickid")) {
            params['request_id'] = query.clickid
        }
        const data = await ApiLandingPage(params)
        setLocation(data)
    }, [channel, query])

    const handleClick = () => {
        window.location.href = location
    }

    useEffect(() => {
        !location && fetchData()
    }, [fetchData, location])

    return (<Layout needLogin={false} needWechat={false} needCopyRight={true}>
        <div className='three' onClick={() => handleClick()}>
            <img src={ImageThree} alt=''/>
        </div>
    </Layout>)
}
export default Three

