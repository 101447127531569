import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Image} from "antd-mobile";
import Layout from "@/layout";
import ImageBackgroundMain from "./assets/background-main.png"
import Warning from "./components/warning"
import "./index.less"

export default function Shopping() {
    const [warningVisible, setWarningVisible] = useState(false)
    const history = useHistory()

    return (<Layout
        bgcolor="radial-gradient(52% 23% at 100% 62%, #CEFDFF 0%, rgba(255,255,255,0.00) 100%), radial-gradient(45% 30% at 4% 34%, #FFE7BE 0%, rgba(255,255,255,0.00) 100%), radial-gradient(59% 40% at 0% 0%, #F2FBE3 0%, rgba(254,251,239,0.00) 100%), radial-gradient(29% 11% at 16% 7%, #FEFBEF 0%, rgba(248,249,252,0.00) 99%), radial-gradient(110% 87% at 100% -21%, #FFEDD2 0%, #F8F9FC 99%)"
    >
        <div className="mbti-start">
            <div className="mbti-start-header">
                <Image src={ImageBackgroundMain}/>
            </div>
            <div className="mbti-start-button" onClick={() => setWarningVisible(true)}>
                <div className="mbti-start-button-text">
                    开始测试
                </div>
                <div className="mbti-start-button-tips">
                    {Math.round(Math.random() * 10000 + 10000)}人已测试
                </div>
            </div>
        </div>
        <Warning
            visible={warningVisible}
            close={() => setWarningVisible(false)}
            callback={() => {
                setWarningVisible(false)
                history.push("/mbti/option")
            }}
        />
    </Layout>)
}
