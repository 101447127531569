import React, {useState, FC, useEffect} from "react";
import {Selector} from 'antd-mobile'
import Dialog from "@/components/dialog";
import "./index.less"

const options = {
    male: ['开朗', '阳光', '幽默', '大方', '睿智', '直爽', '绅士', '老实', '稳重'],
    female: ['贤惠', '温柔', '可爱', '善良', '甜美', '文静', '脱俗', '活泼', '率直']
}

const Personality: FC = ({sex, limit = 3, visible, callback, close}) => {
    const [selects, setSelects] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [currentOptions, setCurrentOptions] = useState([])

    useEffect(() => {
        const tempOptions: any[] = []
        options[sex === 1 ? "female" : "male"].forEach((item, index) => {
            tempOptions.push({label: item, value: index, disabled: false})
        })
        setCurrentOptions(tempOptions)
    }, [sex])

    const handlePickerChange = (val, extend) => {
        setSelects(val)
        setCurrentItems(extend.items)
        const tempOptions = Array.from(currentOptions)
        for (const curKey in tempOptions) {
            if (val.length >= limit) {
                tempOptions[curKey] = {...tempOptions[curKey], disabled: val.indexOf(tempOptions[curKey].value) < 0}
            } else {
                tempOptions[curKey] = {...tempOptions[curKey], disabled: false}
            }
        }
        setCurrentOptions(tempOptions)
    }
    const handleSubmit = () => {
        callback(currentItems);
        close();
    }
    return (<Dialog display={visible} clickMask={() => close()}
                    background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
        <div className="hisCharacter">
            <div className="hisCharacter-item">
                <div className="hisCharacter-item-title">
                    TA的性格
                </div>
                <div className="hisCharacter-item-sub">
                    选择三个标签
                </div>
                <div className="hisCharacter-item-check">
                    <Selector
                        className="hisCharacter-item-check-select"
                        columns={3}
                        value={selects}
                        options={currentOptions}
                        multiple={true}
                        onChange={(arr, extend) => handlePickerChange(arr, extend)}
                    />
                </div>
                <div className="hisCharacter-item-btn" onClick={() => handleSubmit()}>
                    {selects.length}/{limit}确定
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Personality
