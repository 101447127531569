import React from "react"
import Mask from '@/components/mask'
import './index.less'


export default function Dialog({children, clickMask, display, background = "#fff", contentWidth = "80vw"}) {
    return (<div className={'dialog ' + (display ? 'dialog-slide-in' : 'dialog-slide-out')}>
        <Mask visible={display} close={() => clickMask && clickMask()}/>
        <div className="dialog-content" style={{background: background, width: contentWidth}}
        >
            {children}
        </div>
    </div>)
}
