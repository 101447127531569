import React, {FC, useCallback, useEffect, useState} from 'react'
import Layout from "@/layout"
import {ApiOfficialCode} from "@/api/wechat"
import ImageMysterySubscribe from "../assets/mystery-subscribe.png"
import IconFingerprint from "../assets/fingerprint-white.png"
import './index.less'
import {Toast} from "antd-mobile";

const Page: FC = () => {
    const [qrcode, setQrcode] = useState("")

    const fetchQrcode = useCallback(async () => {
        const data = await ApiOfficialCode({
            page: encodeURIComponent('pages/laboratory/special/index'), origin_code: "mystery"
        })
        setQrcode(data)
    }, [])

    const getWechatObject = useCallback(() => {
        fetchQrcode()
        Toast.show({
            icon: "loading", content: "加载中…", duration: 0,
        })
        window.$wx.ready(() => Toast.clear())
    }, [fetchQrcode])

    useEffect(() => {
        getWechatObject()
    }, [getWechatObject])

    const previewQrcode = () => {
        window.$wx.previewImage({
            current: qrcode, urls: [qrcode]
        })
    }

    return (<Layout bgcolor="linear-gradient(180deg, #FF94E1 0%, #FFCD8F 100%)" needLogin={false}>
        <div className="page-feature-subscribe">
            <div className="page-feature-subscribe-header">
                <img src={ImageMysterySubscribe} alt=""/>
            </div>

            <div className="page-feature-subscribe-box">
                <div className="page-feature-subscribe-box-cards">
                    <div className="page-feature-subscribe-box-cards-qrcode">
                        <div className="page-feature-subscribe-box-cards-qrcode-image" onClick={() => previewQrcode()}>
                            <img src={qrcode} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="page-feature-subscribe-box-mask">
                    <div className="page-feature-subscribe-box-mask-triangle"/>
                    <div className="page-feature-subscribe-box-mask-content">
                        <div className="page-feature-subscribe-box-mask-content-icon">
                            <img src={IconFingerprint} alt=""/>
                        </div>
                        <div className="page-feature-subscribe-box-mask-content-text">
                            识别二维码并“关注”
                        </div>
                    </div>
                </div>
                <div className="page-feature-subscribe-box-background"/>
            </div>
            <div className="page-feature-subscribe-tips">
                <div className="page-feature-subscribe-tips-card">
                    <div className="page-feature-subscribe-tips-card-label">
                        小TIPS
                    </div>
                    <div className="page-feature-subscribe-tips-card-list">
                        <div className="page-feature-subscribe-tips-card-list-item">
                            <div className="page-feature-subscribe-tips-card-list-item-num">
                                ①
                            </div>
                            <div className="page-feature-subscribe-tips-card-list-item-text">
                                关注服务号可及时获取活动进度及安排
                            </div>
                        </div>
                        <div className="page-feature-subscribe-tips-card-list-item">
                            <div className="page-feature-subscribe-tips-card-list-item-num">
                                ②
                            </div>
                            <div className="page-feature-subscribe-tips-card-list-item-text">
                                及时获得异性消息回复
                            </div>
                        </div>
                        <div className="page-feature-subscribe-tips-card-list-item">
                            <div className="page-feature-subscribe-tips-card-list-item-num">
                                ③
                            </div>
                            <div className="page-feature-subscribe-tips-card-list-item-text">
                                最新脱单咨询，更有惊喜福利
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>)
}

export default Page
