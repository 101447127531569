import React, { FC, useState } from "react";
import {Image} from 'antd-mobile'
import Dialog from "@/components/dialog";
import { options } from "../../../options";
import ImageNightTitle from '../assets/nightLife-title.png'
import "./index.less";

const NightLife: FC = ({ visible, callback }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const maxSelectableOptions = 1;

    const handleOptionClick = (index) => {
        if (selectedOptions.includes(index)) {
            setSelectedOptions((prevSelected) =>
                prevSelected.filter((item) => item !== index)
            );
        } else {
            let tempSelect = [...selectedOptions];
            if (tempSelect.length < maxSelectableOptions) {
                tempSelect = [...tempSelect, index];
                setSelectedOptions(tempSelect);
                if (tempSelect.length >= maxSelectableOptions) {
                    callback(tempSelect);
                }
            }
        }
    };

    const isOptionSelected = (index) => {
        return selectedOptions.includes(index);
    };

    return (
        <Dialog
            display={visible}
            background={"linear-gradient(143deg, #FFB6BD -1%, #B2EBBD 43%, #A8C0F0 93%)"}
        >
            <div className="nightLife">
                <div className="nightLife-item">
                    <div className="nightLife-item-title">
                        <Image src={ImageNightTitle}></Image>
                    </div>
                    <div className="nightLife-item-check">
                        {options.nightLife.map((item, index) => (
                            <div
                                key={index}
                                className={`nightLife-item-check-state ${
                                    isOptionSelected(index) ? "selected" : ""
                                }`}
                                onClick={() => handleOptionClick(index)}
                            >
                                <Image src={item.image} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default NightLife;
