import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { checkLogin } from "@/model/user"

import { getUserInfo } from "@/store/actions/getUserInfo";

/**
 *
 * @param refreshUser
 *
 * @return {infoToken,infoUser,isLogin}
 */
export function useUser (refreshUser = false) {
    const storeUser = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        checkLogin()
    }, [storeUser.infoToken])

    useEffect(() => {
        refreshUser && dispatch(getUserInfo())
    }, [dispatch, refreshUser])

    return { ...storeUser }
}
