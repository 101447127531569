import React, {FC, useState, useEffect, useCallback} from "react";
import { Picker} from 'antd-mobile'
import Layout from "@/layout"
import {useParams} from "react-router-dom";
import {ApiLandingPage} from "@/api/wechat";
import {heightOptions} from "@/pages/shopping/options";
import Popup from "@/components/popup";
import Man from './assets/man.png'
import Woman from './assets/woman.png'
import './index.less'
const LoadingPage:FC=()=>{
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleOpenPopup1 = () => {
        setShowPopup1(true);
    };

    const handleClosePopup1 = () => {
        setShowPopup1(false);
    };
    const [form, setForm] = useState({
      height: 0,
    })
    const [heightVisible, setHeightVisible] = useState(false)
    const heights = heightOptions()
    const {channel} =useParams()
    const [location,setLocation]=useState({})


    const fetch = useCallback(async ()=>{
        const params = {
            path: "/pages/laboratory/special/index",
            origin_code: "mystery"
        }
        if(channel){
            params['channel_code'] = channel
        }
            const data=await ApiLandingPage(params)
            setLocation(data)

    },[channel])

    const fetchData=()=>{
        window.location.href=location
    }

   useEffect(()=>{
       fetch()
   },[fetch])


    return(<Layout bgcolor="linear-gradient(180deg, #FF3737 0%, #FF991E 100%)" >
        <button onClick={()=>fetchData()}>提交</button>
        <button onClick={handleOpenPopup}>Open Popup</button>
        <button onClick={handleOpenPopup1}>Open Popup2</button>
        <button onClick={()=>setHeightVisible(true)}>打开身高</button>
            <div className='loading'>
                <Picker
                    title='请选择身高'
                    columns={heights}
                    visible={heightVisible}
                    onClose={() => {
                        setHeightVisible(false)
                    }}
                    value={[form.height]}
                    onConfirm={v => setForm({...form, height: v[0]})}
                />
            </div>
        <Popup display={showPopup} close={handleClosePopup}>
            <div className='loading-sex'>
                <h3>请选择你的性别</h3>
                <div className='loading-sex-avatar'>
                    <img src={Man} alt=""/>
                    <img src={Woman} alt=""/>
                </div>
            </div>
            <div className='loading-btn'>
                <div className='loading-btn-aa'>
                    <div className='loading-btn-aa-item'>确认</div>
                </div>

            </div>
        </Popup>
        <Popup display={showPopup1} close={handleClosePopup1} radius={true}>
            <div className='loading-input'>
                <div className='loading-input-text'>请登录</div>
                <input type="text" className='loading-input-tel' placeholder='请输入电话号码'/>
                <input type="text" className='loading-input-tel' style={{marginTop:"10px"}}/>
            </div>
        </Popup>
    </Layout>)
}

export default LoadingPage
