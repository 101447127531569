import React from "react";
import Layout from "@/layout";
import "./index.less"

export default function Index() {
    return (<Layout needLogin={false} needWechat={false}>
        <div className="home-index">
            <div className="home-index-card">
                <div className="home-index-card-content">
                    所有有趣而孤单的单身灵魂都可以来到这儿，让紧张的心情在这里散步；或者，跟随我们去遇见同类。
                </div>
            </div>
        </div>
    </Layout>)
}
