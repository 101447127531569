import React, {memo, useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Image, Swiper, Toast} from "antd-mobile"
import Popup from "@/components/popup"
import VipSwiperItem from "@/components/paidVip/suit/vipSwiperItem"
import {ApiAssetVip, ApiOpenVip} from "@/api/asset"
import {calculateDiscount, filterPrice, getImageSrc} from "@/filters/modules/system"
import {useUser} from "@/hooks/useUser"
import {invokePayment} from "@/plugin/wechat/official"
import IconVip from "@/assets/icon/vip.png"
import "./index.less"
import {getUserInfo} from "@/store/actions/getUserInfo";

const CompPaidVip = ({visible, close}) => {
    const [currentId, setCurrentId] = useState(0)
    const [vipOptions, setVipOptions] = useState([])

    const {infoUser} = useUser()
    const configData = useSelector((state) => state.app.configData)
    const dispatch = useDispatch()
    const fetchData = useCallback(async () => {
        const data = await ApiAssetVip()
        if (data) {
            setVipOptions(data)
            setCurrentId(data[1].id)
        }
    }, [])

    useEffect(() => {
        visible && fetchData()
    }, [fetchData, visible])

    const handleOrdered = useCallback(async (args) => {
        const data: { order_union_sn: string, has_paid: number } = await ApiOpenVip(args)
        if (data) {
            if (args.paid_type === "wechat") {
                invokePayment(data, () => {
                    dispatch(getUserInfo())
                    close()
                    Toast.show({content: '充值成功', icon: 'success'})
                }, () => {
                    Toast.show({content: '支付取消'})
                })
            }
        }
    }, [close, dispatch])

    const handleSubmit = async (e = {}) => {
        Toast.show({
            content: "支付中",
            icon: "loading"
        })
        handleOrdered({
            id: currentId,
            paid_type: "wechat"
        })
    }

    const renderSwiperItemBackAvatar = (item, index) => {
        // eslint-disable-next-line default-case
        switch (index) {
            case 0:
                return (
                    <div className="suit-like-checker-main">
                        <div className="suit-like-checker-main-back">
                            <Image
                                src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-like-checker-main-avatar">
                            <Image
                                src={getImageSrc(infoUser.avatar, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-like-checker-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className="suit-all-visitors-main">
                        <div className="suit-all-visitors-main-outer"></div>
                        <div className="suit-all-visitors-main-inner"></div>
                        <div className="suit-all-visitors-main-back">
                            <Image
                                src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-all-visitors-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="suit-senior-screen-main">
                        <div className="suit-senior-screen-main-back">
                            <Image
                                src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-senior-screen-main-avatar">
                            <Image
                                src={getImageSrc(infoUser.avatar, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-senior-screen-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="suit-daily-recommend-main">
                        <div className="suit-daily-recommend-main-back">
                            <Image
                                src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-daily-recommend-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="suit-paper-message-main">
                        <div className="suit-paper-message-main-back">
                            <div className="suit-paper-message-main-back-left">
                                <Image
                                    src={getImageSrc(infoUser.avatar, configData.static_url)}
                                    mode="widthFix"
                                />
                            </div>
                            <div className="suit-paper-message-main-back-right">
                                <Image
                                    src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                    mode="widthFix"
                                />
                            </div>
                        </div>
                        <div className="suit-paper-message-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className="suit-look-back-main">
                        <div className="suit-look-back-main-back">
                            <div className="suit-look-back-main-back-right">
                                <Image
                                    src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                    mode="widthFix" className="img"
                                />
                            </div>
                        </div>
                        <div className="suit-look-back-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className="suit-privacy-main">
                        <div className="suit-privacy-main-avatar">
                            <Image
                                src={getImageSrc(infoUser.avatar, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-privacy-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
            case 7:
                return (
                    <div className="suit-first-main">
                        <div className="suit-first-main-back">
                            <Image
                                src={getImageSrc(infoUser.sex === 1 ? item.swiperImage.female : item.swiperImage.male, configData.static_url)}
                                mode="widthFix" className="img"
                            />
                        </div>
                        <div className="suit-first-main-avatar">
                            <Image
                                src={getImageSrc(infoUser.avatar, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                        <div className="suit-first-main-icon">
                            <Image
                                src={getImageSrc(item.icon, configData.static_url)}
                                mode="widthFix"
                            />
                        </div>
                    </div>
                )
        }
    }

    return (
        <Popup display={visible} radius close={() => close()}>
            <div className="comp-vip">
                <div className="header">
                    <div className="header-title">
                        <div className="header-title-icon">
                            <Image src={IconVip} mode="heightFix"/>
                        </div>
                        <div className="header-title-text">
                            心动会员
                        </div>
                    </div>
                    {
                        Object.keys(configData).length > 0 &&
                        <div className="header-tips">
                            开通即享{configData.vip_privileges.length}大权益
                        </div>
                    }
                </div>
                {
                    Object.keys(configData).length > 0 &&
                    <div className="privileges">
                        <Swiper
                            className="privileges-slider"
                            indicatorDots
                            indicatorColor="rgba(255, 147, 74, 0.3)"
                            indicatorActiveColor="#FF934A"
                            circular
                        >
                            {
                                configData.vip_privileges.map((item, index) => (
                                    <Swiper.Item key={index}>
                                        <VipSwiperItem boldText={item.label} thinText={item.tips}>
                                            <div>
                                                {renderSwiperItemBackAvatar(item, index)}
                                            </div>
                                        </VipSwiperItem>
                                    </Swiper.Item>
                                ))
                            }
                        </Swiper>
                    </div>
                }
                <div className="options">
                    {
                        vipOptions.map((item, index) => (
                            <div
                                key={index}
                                className={"options-item " + (item.id === currentId ? "active" : '')}
                                onClick={() => setCurrentId(item.id)}
                            >
                                {
                                    item.origin_price > item.price &&
                                    <div className="options-item-header">
                                        限时{calculateDiscount(item.origin_price, item.price)}折
                                    </div>
                                }
                                <div className="options-item-title">
                                    {item.title}
                                </div>
                                <div className="options-item-price">
                                    ¥{filterPrice(item.price)}
                                </div>
                                <div className="options-item-tips">
                                    {item.tips}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="bottom-actions">
                    <Button className="bottom-actions-renew" onClick={() => handleSubmit()}>
                        立即开通
                    </Button>
                </div>
            </div>
        </Popup>
    )
}

export default memo(CompPaidVip)
