import React, {FC} from "react";
import Dialog from "@/components/dialog";
import "./index.less"

const Warning: FC = ({visible, callback, close}) => {
    const tipItems = [
        "28道测试题，请尽量于5分钟内答完。",
        "请在自身状态良好的情况下答题，无对错之分，如实回答即可。",
        "如果你没有恋爱经验，请按照自己的认知，以最可能应对爱情所持有的反应回答。",
        "中途退出后，下次答题将重新开始。",
    ]
    const handleClose = () => {
        close()
    }
    return (<Dialog
        display={visible}
        clickMask={() => handleClose()}
        background="radial-gradient(70% 70% at 0% 0%, #F2FBE3 0%, rgba(254,251,239,0.00) 100%), radial-gradient(51% 51% at 57% 24%, #FEFBEF 0%, rgba(248,249,252,0.00) 99%), radial-gradient(73% 73% at 100% 1%, #FFEDD2 0%, #F8F9FC 99%)"
    >
        <div className="mbti-warning">
            <div className="mbti-warning-title">
                测试须知
            </div>
            <div className="mbti-warning-subtitle">
                答题过程中请注意以下几点
            </div>
            <div className="mbti-warning-list">
                {tipItems.map((item, index) => (<div key={index} className="mbti-warning-list-item">
                    <div className="mbti-warning-list-item-num">
                        {index + 1}.
                    </div>
                    <div className="mbti-warning-list-item-text">
                        {item}
                    </div>
                </div>))}
            </div>
            <div className="mbti-warning-btn" onClick={() => callback()}>
                开始答题
            </div>
        </div>
    </Dialog>)
}
export default Warning
