import React from "react"
import Dialog from "@/components/dialog"
import "./index.less"


export default function StopTips({visible, callback, close}) {
    const handleSubmit = () => {
        callback()
    }
    const handleClose = () => {
        close()
    }

    return (<Dialog display={visible} clickMask={() => handleClose()}>
        <div className="choose-tips">
            <div className="choose-tips-title">
                确认出站吗？
            </div>
            <div className="choose-tips-content">
                答题越多，越能精准匹配
            </div>
            <div className="choose-tips-button" onClick={() => handleSubmit()}>
                确认出站
            </div>
            <div className="choose-tips-button" onClick={() => close()}>
                继续旅途
            </div>
        </div>
    </Dialog>)
}
