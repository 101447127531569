import React from "react";
import "./index.less"
import Layout from "@/layout";

export default function Index() {
    const clearLocalStorage = () => {
        window.localStorage.clear()
    }
    return (<Layout needLogin={false} needWechat={false}>
        <div className="home-index">
            <div className="home-index-card">
                <div className="home-index-card-content" onClick={() => clearLocalStorage()}>
                    点击此处清理当前页面所有缓存
                </div>
            </div>
        </div>
    </Layout>)
}
