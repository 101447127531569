import React,{useState,FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView } from 'antd-mobile'
import "./index.less"

const Education:FC=({visible, callback, close,sendEducation})=>{
    const [checkvisible, setchechVisible] = useState(false)
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedArray, setSelectedArray] = useState([]);
    const handleClose=()=>{
        close()
    }
    const basicColumns = [
        [
            { label: '高中及以下', value: '高中及以下' },
            { label: '大学专科', value: '大学专科' },
            { label: '大学本科', value: '大学本科' },
            { label: '硕士研究生', value: '硕士研究生' },
            { label: '博士研究生', value: '博士研究生' },
        ],

    ]
    const handlePickerChange = (value) => {
        setSelectedValue(value.join(', '));
        setSelectedArray(value);
    };

    const handleEducation = () => {
        sendEducation(selectedValue);
        close();
    };

    return(
        <Dialog display={visible} clickMask={() => handleClose()} background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="education">
                <div className="education-item">
                    <div className="education-item-title">
                       你的学历
                    </div>
                    <div className="education-item-check">
                        <PickerView
                            columns={basicColumns}
                            visible={checkvisible}
                            onClose={() => {
                                setchechVisible(false)
                            }}
                            value={selectedArray}
                            onChange={handlePickerChange}
                        />
                    </div>
                    <div className="education-item-btn" onClick={()=>handleEducation()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Education
