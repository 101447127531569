import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import url from "url";
import Layout from "@/layout";
import {ApiDetail} from "@/api/group";
import {filterTime} from "@/filters/modules/system";
import {useUser} from "@/hooks/useUser";
import "./index.less"
import {Image} from "antd-mobile";
import ImagePostPackage from "@/pages/shopping/assets/post-package.png";
import IconClock from "@/pages/shopping/assets/icon-clock.png";
import IconCopy from "@/pages/shopping/assets/icon-copy.png";
import IconSpeaker from "@/pages/shopping/assets/icon-speaker.png";


export default function Status() {
    const [detail, setDetail] = useState({
        info: {
            match_time: 0
        }
    })

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            setDetail(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleWaiting = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.navigateBack({delta: 1})
    }

    return (<Layout bgcolor="linear-gradient(180deg, #9E4FF8 0%, #B53EF0 100%)">
        <div className="shopping-status">
            <div className="shopping-status-card">
                <div className="shopping-status-card-header">
                    <Image src={ImagePostPackage}/>
                </div>
                <div className="shopping-status-card-code">
                    <div className="shopping-status-card-code-icon">
                        <Image src={IconClock}/>
                    </div>
                    <div className="shopping-status-card-code-label">
                        送达时间
                    </div>
                    <div className="shopping-status-card-code-text">
                        {filterTime(detail.info.match_time)}
                    </div>
                </div>
                <div className="shopping-status-card-contact">
                    <div className="shopping-status-card-contact-wechat">
                        <div className="shopping-status-card-contact-wechat-text">
                            等待揭晓
                        </div>
                        <div className="shopping-status-card-contact-wechat-btn">
                            <div className="wechat-btn-icon">
                                <Image src={IconCopy}/>
                            </div>
                            <div className="wechat-btn-text">
                                复制
                            </div>
                        </div>
                    </div>
                    <div className="shopping-status-card-contact-tips">
                        <div className="shopping-status-card-contact-tips-icon">
                            <Image src={IconSpeaker}/>
                        </div>
                        <div className="shopping-status-card-contact-tips-text">
                            包裹可以晚点到，但爱情却是不行的
                        </div>
                    </div>
                </div>
            </div>
            <div className="shopping-status-button" onClick={() => handleWaiting()}>
                我知道了
            </div>
        </div>
    </Layout>)
}
