import React,{useState,FC,useEffect} from "react";
import Dialog from "@/components/dialog";
import {Selector } from 'antd-mobile'
import "./index.less"
const options=['音乐','游戏','跳舞','电影','追剧','运动','看书','旅游','派对']
const Hobby:FC=({visible, limit=3,callback, close})=>{
    const [selects, setSelects] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [currentOptions, setCurrentOptions] = useState([])
    const handleClose=()=>{
        close()
    }
    useEffect(()=>{
        const temOptions:any[]=[]
        options.forEach((item,index)=>{
            temOptions.push({label:item,value:index,disable:false})
        })
        setCurrentOptions(temOptions)
    },[])
    const handlePickerChange = (val, extend) => {
        setSelects(val)
        setCurrentItems(extend.items)
        const tempOptions = Array.from(currentOptions)
        for (const curKey in tempOptions) {
            if (val.length >= limit) {
                tempOptions[curKey] = {...tempOptions[curKey], disabled: val.indexOf(tempOptions[curKey].value) < 0}
            } else {
                tempOptions[curKey] = {...tempOptions[curKey], disabled: false}
            }
        }
        setCurrentOptions(tempOptions)
    }

    const handleHobby =()=>{
        callback(currentItems)
        close()
    }
    return(
        <Dialog display={visible} clickMask={() => handleClose()} background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="hobby">
                <div className="hobby-item">
                    <div className="hobby-item-title">
                        你的性格
                    </div>
                    <div className="hobby-item-sub">
                        选择三个标签
                    </div>
                    <div className="hobby-item-check">
                        <Selector
                            value={selects}
                           className="hobby-item-check-select"
                            columns={3}
                            options={currentOptions}
                            multiple={true}
                            onChange={(val, extend) =>handlePickerChange(val, extend)}
                        />
                    </div>
                    <div className="hobby-item-btn" onClick={()=>handleHobby()}>
                        {selects.length}/{limit}确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Hobby
