import React, {FC, useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import {useSelector} from "react-redux";
import 'animate.css'
import {Button, ImageUploader, Input} from 'antd-mobile'
import url from "url";
import Layout from "@/layout";
import {ApiUpload} from "@/api/file";
import {ApiDetail} from "@/api/group";
import {getImageSrc} from '@/filters/modules/system'
import {useUser} from '@/hooks/useUser'
import {updateAttrs, updateInfo} from "@/model/user";
import Warning from './components/warning'
import College from "./components/college";
import Year from "./components/year";
import Education from "./components/education";
import Birthday from "./components/birthday";
import TextImage from "./assets/text.png";
import ImageData from './assets/data.png'
import ImageTitle from './assets/title.png'
import ImageEntrance from './assets/entrance.png'
import ImagePhoto from './assets/photo.png'
import ImageGo from '@/pages/school/assets/go.png'
import "./index.less"


const School: FC = () => {
    const [infoForm, setInfoForm] = useState({
        realname: '', sex: 0, birthday: '', avatar: '',
    })
    const [attrForm, setAttrForm] = useState({
        college: "", college_year: "", college_dept: "", college_major: "", education: "",
    })

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const history = useHistory()
    const {infoUser, infoAttr, isLogin} = useUser(true)
    const [returnCss, setReturnCss] = useState(false)
    const [CollegeVisible, setCollegeVisible] = useState(false)
    const [YearVisible, setYearVisible] = useState(false)
    const [EducationVisible, setEducationVisible] = useState(false)
    const [birthdayVisible, setBirthdayVisible] = useState(false)
    const [unfinishedVisible, setunfinishedVisible] = useState(false)

    const configData = useSelector((state) => state.app.configData)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            if (data.is_finish === 1) {
                history.push("/school/status?group_id=" + query.group_id)
            }
        }
    }, [history, isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (Object.keys(infoUser).length) {
            let infoTempForm = {
                realname: '', sex: 0, birthday: '', avatar: '',
            }
            const infoKeys = Object.keys(infoUser)
            infoKeys.forEach((item) => {
                if (infoTempForm.hasOwnProperty(item) && infoUser[item]) {
                    infoTempForm[item] = infoUser[item]
                }
            })
            setInfoForm(infoTempForm)
        }
        if (infoAttr.hasOwnProperty('base')) {
            const attrTempForm = {
                college: "", college_year: "", college_dept: "", college_major: "", education: "",
            }
            const attrKeys = Object.keys(infoAttr.base)
            attrKeys.forEach((item) => {
                if (attrTempForm.hasOwnProperty(item)) {
                    attrTempForm[item] = infoAttr.base[item].value
                }
            })
            setAttrForm(attrTempForm)
        }
    }, [infoAttr, infoUser])

    const handleSubmit = () => {
        if (!attrForm.education || !attrForm.college || !attrForm.college_major || !attrForm.college_year || !attrForm.college_dept || !infoForm.realname || !infoForm.sex || !infoForm.birthday || !infoForm.avatar) {
            setunfinishedVisible(true);
        } else {
            setReturnCss(!returnCss);
            updateInfo(infoForm, "base")
            updateAttrs(attrForm, "base")
            history.push('/school/meet?group_id=' + query.group_id);
        }
    }

    const go = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.navigateBack({delta: 1})
    }
    const handleUpload = async (file) => {
        // console.log(file)
        const data = await ApiUpload({directory: "user_header", "uid": infoUser.uid}, file)
        if (data) {
            setInfoForm({...infoForm, avatar: data})
        }
        return {
            url: getImageSrc("data", configData.static_url)
        }
    }

    return (<Layout bgcolor="#FDF9F5">
        <div className="entrance">
            <div className="entrance-text">
                <img src={TextImage} alt=""/>
            </div>
            <div className="entrance-go animate__animated animate__fadeInRight" onClick={() => go()}>
                <img src={ImageGo} alt=""/>
            </div>
            <div className="entrance-top animate__animated animate__fadeInRightBig">
                <div className="entrance-top-logo">
                    <img src={ImageTitle} alt="" className="img animate__animated  animate__fadeInRight"/>
                    <div className="entrance-top-logo-cont animate__animated  animate__fadeInRightBig">
                        <div className="entrance-top-logo-cont-infor">
                            <div className="entrance-top-logo-cont-infor-title">基础资料</div>
                            <div>
                                <img src={ImageData} alt=""/>
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-detail">
                            <div className="entrance-top-logo-cont-detail-left">
                                <div className="entrance-top-logo-cont-detail-left-data">
                                    <div className="entrance-top-logo-cont-detail-left-data-lable">
                                        姓名
                                    </div>
                                    <div className="entrance-top-logo-cont-detail-left-data-input">
                                        <Input value={infoForm.realname}
                                               onChange={(val) => setInfoForm({...infoForm, realname: val})}/>
                                    </div>
                                </div>
                                <div className="entrance-top-logo-cont-detail-left-data">
                                    <div className="entrance-top-logo-cont-detail-left-data-lable">
                                        性别
                                    </div>
                                    <div className="entrance-top-logo-cont-detail-left-data-btn">
                                        <Button
                                            onClick={() => setInfoForm({...infoForm, sex: 1})}
                                            className={"entrance-top-logo-cont-detail-left-data-btn-item" + (infoForm.sex === 1 ? "active" : "")}>
                                            男
                                        </Button>
                                        <Button
                                            onClick={() => setInfoForm({...infoForm, sex: 2})}
                                            className={"entrance-top-logo-cont-detail-left-data-btn-item" + (infoForm.sex === 2 ? "active" : "")}>
                                            女
                                        </Button>
                                    </div>
                                </div>
                                <div className="entrance-top-logo-cont-detail-left-data">
                                    <div className="entrance-top-logo-cont-detail-left-data-lable">
                                        生日
                                    </div>
                                    <div className="entrance-top-logo-cont-detail-left-data-input"
                                         onClick={() => setBirthdayVisible(true)}>
                                        {infoForm.birthday}
                                    </div>
                                </div>
                            </div>
                            <div className="entrance-top-logo-cont-detail-right">
                                <ImageUploader renderItem={() => null} upload={handleUpload}>
                                    {!infoForm.avatar && infoAttr.hasOwnProperty('base') &&
                                        <div className="entrance-top-logo-cont-detail-right-photo">
                                            <img src={ImagePhoto} alt=""/>
                                            <div className="entrance-top-logo-cont-detail-right-photo-text">
                                                证件照
                                            </div>
                                        </div>}
                                    {infoForm.avatar && Object.keys(configData).length &&
                                        <div className="entrance-top-logo-cont-detail-right-hanvephoto">
                                            <img src={getImageSrc(infoForm.avatar, configData.static_url)} alt=""/>
                                        </div>}
                                </ImageUploader>
                                <div className='entrance-top-logo-cont-detail-right-text'>
                                    点击图片重新上传
                                </div>
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-infor">
                            <div className="entrance-top-logo-cont-infor-title">入学信息</div>
                            <div>
                                <img src={ImageEntrance} alt=""/>
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-data">
                            <div className="entrance-top-logo-cont-data-lable">
                                入学院校
                            </div>
                            <div className="entrance-top-logo-cont-detail-left-data-input"
                                 onClick={() => setCollegeVisible(true)}>
                                {attrForm.college}
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-item">
                            <div className="entrance-top-logo-cont-item-left">
                                <div className="entrance-top-logo-cont-item-left-lable">
                                    入学年份
                                </div>
                                <div className="entrance-top-logo-cont-item-right-input"
                                     onClick={() => setYearVisible(true)}>
                                    {attrForm.college_year}
                                </div>
                            </div>
                            <div className="entrance-top-logo-cont-item-right">
                                <div className="entrance-top-logo-cont-item-right-lable">
                                    学历
                                </div>
                                <div className="entrance-top-logo-cont-item-right-input"
                                     onClick={() => setEducationVisible(true)}>
                                    {attrForm.education}
                                </div>
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-item">
                            <div className="entrance-top-logo-cont-item-left">
                                <div className="entrance-top-logo-cont-item-left-lable">
                                    院系
                                </div>
                                <div className="entrance-top-logo-cont-item-left-input">
                                    <Input value={attrForm.college_dept}
                                           onChange={(val) => setAttrForm({...attrForm, college_dept: val})}/>
                                </div>
                            </div>
                            <div className="entrance-top-logo-cont-item-right">
                                <div className="entrance-top-logo-cont-item-right-lable">
                                    专业
                                </div>
                                <div className="entrance-top-logo-cont-item-right-input">
                                    <Input value={attrForm.college_major}
                                           onChange={(val) => setAttrForm({...attrForm, college_major: val})}/>
                                </div>
                            </div>
                        </div>
                        <div className="entrance-top-logo-cont-font">
                            注：点击空留区域完成填写
                        </div>
                    </div>

                </div>
            </div>
            <div className='entrance-btn animate__animated animate__fadeInUp' onClick={() => handleSubmit()}>
                <div className='entrance-btn-title'>
                    添加完毕
                </div>
                <div className="entrance-btn-subtitle">
                    FILL IN
                </div>
            </div>
        </div>

        <College
            visible={CollegeVisible}
            close={() => setCollegeVisible(false)}
            callback={(college) => setAttrForm({...attrForm, college: college})}
        />
        <Year
            visible={YearVisible}
            close={() => setYearVisible(false)}
            sendYear={(year) => setAttrForm({...attrForm, college_year: year})}
        />
        <Education
            visible={EducationVisible}
            close={() => setEducationVisible(false)}
            sendEducation={(education) => setAttrForm({...attrForm, education: education})}
        />
        <Birthday
            visible={birthdayVisible}
            close={() => setBirthdayVisible(false)}
            sendBirthday={(birthday) => setInfoForm({...infoForm, birthday: birthday})}
        />
        <Warning
            message={{
                title: "你还没有完成申请表", subTitle: "请完成入学申请表，进入下一环节", btnText: "继续填写"
            }}
            visible={unfinishedVisible}
            close={() => setunfinishedVisible(false)}
        />
    </Layout>)
}
export default School
