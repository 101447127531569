import React from "react"
import qs from "qs"
import axios from "axios"
import { Toast } from "antd-mobile"
import { HeartOutline } from "antd-mobile-icons"
import { setLogout } from "@/store/actions/user"
import store from "@/store/instance"
import { userLogin } from "@/model/user"

const BaseUrl = process.env.NODE_ENV === "development" ? "https://api.personals.top/" : "https://api.heart-dating.top/"


const service = axios.create({
    baseURL: BaseUrl, timeout: 60000,
},)

service.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"
service.interceptors.request.use(config => {
    // 设置服务provider
    config.headers["X-DATING-PROVIDER"] = process.env.PROVIDER
    config.headers["X-DATING-PLATFORM"] = process.env.PLATFORM
    // 判断用户是否登陆
    const tokenInfo = store.getState().user.infoToken
    if (tokenInfo instanceof Object && tokenInfo.hasOwnProperty("access_token")) {
        config.headers["access-token"] = tokenInfo.access_token
    }
    // 处理post参数
    if (config.method === "post" && config.headers["Content-Type"] !== 'multipart/form-data') {
        config.data = qs.stringify(config.params)
        config.params = []
    }
    // 返回 request config
    return config
}, error => Promise.resolve(error))

service.interceptors.response.use(response => {
    if (!(response.data instanceof Object) && !response.data.hasOwnProperty("code")) {
        return { data: null }
    }
    if (response.data.code !== 0 && response.data.msg) {
        if (response.data.code === -32000) {
            // return Toast.show({
            //     icon: <HeartOutline/>, content: response.data.msg, maskClickable: false, duration: 1000
            // })
            return { data: null }
        }
        return { data: null }
    }
    return response.data
}, error => {
    if (Object.keys(error).length > 0) {
        if (error.hasOwnProperty("response") && error.response.status === 401) {
            store.dispatch(setLogout())
            Toast.show({
                icon: <HeartOutline />, content: "登陆中...", maskClickable: false, duration: 1000, afterClose: () => {
                    userLogin()
                },
            })
        }
    }
},)

export function fetchFile ({ url, params, file, filename = "file" }) {
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append(filename, file)
        service({
            url, method: 'post', params: params, data: formData, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((res) => {
            if (res && res.hasOwnProperty("data") && res.data !== null) {
                return resolve(res.data)
            } else {
                return reject(res)
            }
        }).catch((e) => {
            return reject(e)
        })
    })
}

export default function fetch ({ url, params = {}, method = "get" }) {
    return new Promise((resolve, reject) => {
        service({ url, params, method })
            .then((res) => {
                if (res && res.hasOwnProperty("data") && res.data !== null) {
                    return resolve(res.data)
                } else {
                    return reject(res)
                }
            })
            .catch((e) => {
                return reject(e)
            })
    })
}
