import React, {FC} from "react";
import {Image} from 'antd-mobile'
import Layout from "@/layout";
import useData from "../hooks/useData";
import Map2 from './assets/map2.png'
import Map3 from './assets/map3.png'
import Map4 from './assets/map4.png'
import Map5 from './assets/map5.png'
import Map6 from './assets/map6.png'
import Map7 from './assets/map7.png'
import Map8 from './assets/map8.png'
import Map9 from './assets/map9.png'
import Map10 from './assets/map10.png'
import Map11 from './assets/map11.png'
import Map12 from './assets/map12.png'
import Map13 from './assets/map13.png'
import Map14 from './assets/map14.png'
import Map15 from './assets/map15.png'
import Map16 from './assets/map16.png'
import Map17 from './assets/map17.png'
import Map18 from './assets/map18.png'
import Map19 from './assets/map19.png'
import Map20 from './assets/map20.png'
import Map21 from './assets/map21.png'
import Map22 from './assets/map22.png'
import Map23 from './assets/map23.png'
import Map24 from './assets/map24.png'
import Map25 from './assets/map25.png'
import Map26 from './assets/map26.png'
import Map27 from './assets/map27.png'
import ImageHeart from './assets/heart.png'
import ImageCoffee from './assets/coffee.png'
import ImageCbd from './assets/CBD.png'
import ImageShop from './assets/shop.png'
import ImageRoadVer from './assets/road-ver.png'
import ImageRoadCross from './assets/road-cross.png'
import ImageHome from './assets/home.png'
import ImageSubway from './assets/subway.png'
import ImageAvatar from './assets/avatar.png'
import Time from './components/time'
import Vehicle from "./components/vehicle";
import WorkState from "./components/workState";
import Habit from './components/habit'
import Nightlife from "./components/nightlife";
import Commitment from "./components/commitment";
import './index.less'


const Map: FC = () => {
    const {
        currentPosition,
        modalWeekUp,
        modalWorkHow,
        modalWorkAttitude,
        modalHabit,
        modalAfterWork,
        modalCommit,
        handleCloseModal,
        handleItemSubmit
    } = useData(true, {
        left: 21,
        top: 36.8
    })


    return (<Layout needCopyRight={false}>
        <div className="map">
            {/*第一层*/}
            <div className="map-item1">
                <Image src={Map2}/>
            </div>
            {/*第二层*/}
            <div className="map-item2">
                <div className="map-item2-home">
                    <Image src={Map3}/>
                </div>
                <div className="map-item2-home">
                    <Image src={ImageHome}/>
                </div>
                <div className="map-item2-home">
                    <Image src={Map4}/>
                </div>
            </div>
            {/*第三层*/}
            <div className="map-item3">
                <div className="map-item3-left">
                    <div className="map-item3-left-main">
                        <Image src={Map5}/>
                        <Image src={Map9}/>
                    </div>
                    <div className="map-item3-left-box">
                        <div className="map-item3-left-box-top">
                            <div className="map-item3-left-box-top-road">
                                {
                                    [...Array(5).keys()].map(index => (
                                        <Image key={index} src={ImageRoadVer} className="road"/>
                                    ))
                                }
                            </div>
                            <div className="map-item3-left-box-top-photo">
                                <Image src={Map6}/>
                            </div>
                        </div>
                        <div className="map-item3-left-box-bottom">
                            {
                                [...Array(9).keys()].map(index => (
                                    <Image key={index} src={ImageRoadCross} className="road"/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="map-item3-subway">
                    <div>
                        <Image src={ImageSubway}/>
                    </div>
                    <div>
                        <Image src={Map7}/>
                        <Image src={Map8}/>
                    </div>

                </div>
            </div>
            {/*第四层*/}
            <div className="map-item4">

                <Image src={Map10}/>

                <div className="map-item4-road">
                    {
                        [...Array(14).keys()].map(index => (
                            <Image key={index} src={ImageRoadVer} className="road"/>
                        ))
                    }

                </div>

                <Image src={Map11}/>

            </div>
            {/*第五层*/}
            <div className="map-item5">
                <div>
                    <Image src={Map12}/>
                </div>
                <div>
                    <Image src={ImageCbd}/>
                </div>
                <div>
                    <Image src={Map13}/>
                </div>
            </div>
            {/*第六层*/}
            <div className="map-item6">
                <Image src={Map14}/>
                <Image src={ImageShop}/>
                <Image src={Map15}/>
                <div className="map-item6-road">
                    {
                        [...Array(6).keys()].map(index => (
                            <Image src={ImageRoadVer} className="road" key={index}/>
                        ))
                    }
                </div>
                <div>
                    <Image src={Map16}/>
                </div>
            </div>
            {/*第七层*/}
            <div className="map-item7">
                <div className="map-item7-left">
                    <Image src={Map17}/>
                </div>
                <div className="map-item7-road">
                    {
                        [...Array(13).keys()].map(index => (
                            <Image src={ImageRoadCross} key={index} className="road"/>
                        ))
                    }
                </div>
                <div className="map-item7-right">
                    <Image src={Map18}/>
                </div>
            </div>
            {/*第八层*/}
            <div className="map-item8">

                <Image src={Map19}/>

                <div className="map-item8-road">
                    {
                        [...Array(18).keys()].map(index => (
                            <Image src={ImageRoadVer} key={index} className="road"/>
                        ))
                    }
                </div>
                <Image src={Map20}/>
            </div>
            {/*第九层*/}
            <div className="map-item9">
                <Image src={Map21}/>
                <Image src={ImageCoffee}/>
                <Image src={Map22}/>
            </div>
            {/*第十层*/}
            <div className="map-item10">
                <Image src={Map23}/>
                <div className="map-item10-road">
                    {
                        [...Array(13).keys()].map(index => (
                            <Image src={ImageRoadCross} key={index} className="road"/>
                        ))
                    }
                </div>
                <Image src={Map24}/>
            </div>
            {/*第十一层*/}
            <div className="map-item11">
                <Image src={Map25}/>
                <Image src={ImageHeart}/>
                <Image src={Map26}/>
            </div>
            {/*第十二层*/}
            <div className="map-item12">
                <Image src={Map27}/>
            </div>
            <div className="map-avatar" style={{left: currentPosition.left + "vw", top: currentPosition.top + "vw"}}>
                <Image src={ImageAvatar} fit='cover'/>
            </div>
        </div>

        <Time
            visible={modalWeekUp}
            callback={(val) => {
                handleItemSubmit({type: "objectWeekUp", data: val})
                handleCloseModal('modalWeekUp')
            }}
        />

        <Vehicle
            visible={modalWorkHow}
            callback={(val) => {
                handleItemSubmit({type: "objectWorkHow", data: val})
                handleCloseModal('modalWorkHow')
            }}
        />
        <WorkState
            visible={modalWorkAttitude}
            callback={(val) => {
                handleItemSubmit({type: "objectWorkAttitude", data: val})
                handleCloseModal('modalWorkAttitude')
            }}

        />
        <Habit
            visible={modalHabit}
            callback={(val) => {
                handleItemSubmit({type: "objectHabit", data: val})
                handleCloseModal('modalHabit')
            }}
        />
        <Nightlife
            visible={modalAfterWork}
            callback={(val) => {
                handleItemSubmit({type: "objectAfterWork", data: val})
                handleCloseModal('modalAfterWork')
            }}
        />

        <Commitment
            visible={modalCommit}
            callback={()=>{
                handleCloseModal('modalCommit')
            }}
        />
    </Layout>)
}

export default Map
