import ImageState1 from './map/assets/state1.png'
import ImageState2 from './map/assets/state2.png'
import ImageState3 from './map/assets/state3.png'
import ImageState4 from './map/assets/state4.png'
import ImageState5 from './map/assets/state5.png'
import ImageState6 from './map/assets/state6.png'
import ImageHabit1 from './map/assets/habit1.png'
import ImageHabit2 from './map/assets/habit2.png'
import ImageHabit3 from './map/assets/habit3.png'
import ImageHabit4 from './map/assets/habit4.png'
import ImageHabit5 from './map/assets/habit5.png'
import ImageHabit6 from './map/assets/habit6.png'
import ImageNightLife1 from './map/assets/nightlife1.png'
import ImageNightLife2 from './map/assets/nightlife2.png'
import ImageNightLife3 from './map/assets/nightlife3.png'
import ImageNightLife4 from './map/assets/nightlife4.png'
import ImageNightLife5 from './map/assets/nightlife5.png'
import ImageNightLife6 from './map/assets/nightlife6.png'
export const options={
    state:[
        {
            image:ImageState1,
            value:1

        },
        {
            image: ImageState2,
            value: 2
        },
        {
            image: ImageState3,
            value: 3
        },
        {
            image: ImageState4,
            value: 4
        },
        {
            image: ImageState5,
            value: 5
        },
        {
            image: ImageState6,
            value: 6
        }
    ],
    habit:[
        {
            image:ImageHabit1,
            value:1
        },
        {
            image:ImageHabit2,
            value: 2
        },
        {
            image:ImageHabit3,
            value: 3
        },
        {
            image:ImageHabit4,
            value: 4
        },
        {
            image:ImageHabit5,
            value: 5
        },
        {
            image:ImageHabit6,
            value: 6
        }
    ],
    nightLife:[
        {
            image:ImageNightLife1,
            value:1
        },
        {
            image: ImageNightLife2,
            value: 2
        },
        {
            image:ImageNightLife3,
            value: 3
        },
        {
            image:ImageNightLife4,
            value: 4
        },
        {
            image:ImageNightLife5,
            value: 5
        },
        {
            image: ImageNightLife6,
            value: 6
        }
    ],
    education:[
        {
            label:'高中及以下',
            value:'高中及以下'
        },
        {
            label: '大学专科',
            value: '大学专科'
        },
        {
            label: '大学本科',
            value: '大学本科'
        },
        {
            label:'研究生',
            value: '研究生'
        },
        {
            label: '硕士',
            value: '硕士'
        },
        {
            label: '博士',
            value: '博士'
        }
    ]
}
