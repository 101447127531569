import { ApiInfo } from "@/api/user";
import { getUserAttr, setUserInfo } from "@/store/actions/user";

export const getUserInfo = () => {
    return dispatch => {
        (async () => {
            const data = await ApiInfo()
            if (data) {
                dispatch(setUserInfo(data))
                dispatch(getUserAttr(data))
            }
        })()
    }
}
