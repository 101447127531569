import React, {FC, useState} from 'react'
import {Image} from 'antd-mobile'
import Layout from '@/layout'
import PaidPoint from "@/components/paidPoint"
import PaidVip from "@/components/paidVip"
import IconVip from "@/assets/icon/vip.png"
import IconCoin from "@/assets/icon/coin.png"
import ImgVipMain from "@/assets/image/vip-main.png"
import ImgCoinMain from "./assets/Img-coin-main.png"
import './index.less'

const Property: FC = () => {
    const [showPaidVip, setShowPaidVip] = useState(false)
    const [showPaidPoint, setShowPaidPoint] = useState(false)
    return (
        <Layout bgcolor="#fff">
            <div className="property-box">
                <div className="property-box-title">
                    请选择您需要的服务
                </div>
                <div className="property-box-vip" onClick={() => setShowPaidVip(true)}>
                    <div className="property-box-vip-title">
                        <div className="property-box-vip-title-icon">
                            <Image src={IconVip}/>
                        </div>
                        <div className="property-box-vip-title-text">心动会员</div>
                    </div>
                    <div className="property-box-vip-bottom">
                        <div className="property-box-vip-bottom-one">
                            <div className="property-box-vip-bottom-one-active">八大</div>
                            会员专享权益
                        </div>
                        <div className="property-box-vip-bottom-two">助你
                            <div className="property-box-vip-bottom-one-active">脱单</div>
                            快人一步
                        </div>
                        <div className="property-box-vip-bottom-three">
                            点击立即开通 >
                        </div>
                    </div>
                    <div className="property-box-vip-left">
                        <Image src={ImgVipMain}/>
                    </div>
                </div>
                <div className="property-box-coin" onClick={() => setShowPaidPoint(true)}>
                    <div className="property-box-coin-title">
                        <div className="property-box-vip-title-icon">
                            <Image src={IconCoin}/>
                        </div>
                        <div className="property-box-vip-title-text">心动币充值</div>
                    </div>
                    <div className="property-box-coin-bottom">
                        <div className="property-box-coin-bottom-one">八会员专享权益</div>
                        <div className="property-box-coin-bottom-two">助你脱单快人一步</div>
                        <div className="property-box-coin-bottom-three">
                            点击充值 >
                        </div>
                    </div>
                    <div className="property-box-coin-back">
                        <div className="property-box-coin-back-left">
                            <Image src={ImgCoinMain}/>
                        </div>
                    </div>
                </div>
            </div>
            <PaidPoint visible={showPaidPoint} close={() => setShowPaidPoint(false)}/>
            <PaidVip visible={showPaidVip} close={() => setShowPaidVip(false)}/>
        </Layout>
    )
}

export default Property
