import React, {FC} from "react";
import {useSelector} from "react-redux";
import url from "url";
import {Image, Toast} from "antd-mobile";
import {InformationCircleOutline} from "antd-mobile-icons";
import {birthDetail, getImageSrc} from "@/filters/modules/system";
import Layout from "@/layout";
import {ApiSetFinish} from "@/api/group";
import {useUser} from '@/hooks/useUser'
import {useHistory, useLocation} from 'react-router-dom'
import ImageText from '@/pages/school/assets/text.png'
import ImageGo from "@/pages/school/assets/go.png";
import ImageEditor from './assets/editor.png'
import ImageLogo from './assets/logo.png'
import './index.less'


const ArchivesCard: FC = () => {
    const {infoUser, infoAttr} = useUser(false)
    const history = useHistory()
    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const locationInfo = useSelector(state => state.app.locationInfo);
    const configData = useSelector((state) => state.app.configData)
    const go = () => {
        history.push('/school/current?group_id=' + query.group_id)
    }

    const handleSubmit = async () => {
        const data = await ApiSetFinish({group_id: query.group_id})
        if (!data) {
            return Toast.show({
                icon: <InformationCircleOutline/>, content: "引力消失了～", maskClickable: false, duration: 1000
            })
        }
        history.push('/school/status?group_id=' + query.group_id)
    }
    const goHome = () => {
        history.push('/school?group_id=' + query.group_id)
    }

    const renderIntro = () => {
        let introString = "我来自"
        if (infoUser.province && infoUser.city) {
            introString += infoUser.province + "-" + infoUser.city + "，现在"
        } else {
            introString += "--，现在"
        }
        if (infoAttr.hasOwnProperty("base") && infoAttr.base.hasOwnProperty("marital_status")) {
            introString += infoAttr.base.marital_status.value + "，在"
        } else {
            introString += "单身，在"
        }
        if (locationInfo.province && locationInfo.city) {
            introString += locationInfo.province + "-" + locationInfo.city + "做"
        } else {
            introString += "-- 做"
        }
        if (infoAttr.hasOwnProperty("base") && infoAttr.base.hasOwnProperty("work")) {
            if ((typeof infoAttr.base.work.value) === "object") {
                introString += infoAttr.base.work.value[infoAttr.base.work.value.length - 1] + "工作"
            } else {
                introString += infoAttr.base.work.value + "工作"
            }
        } else {
            introString += "- 工作"
        }
        return introString
    }

    const rendWish = () => {
        return "我希望和ta在" + infoAttr.matched['temp@school@place'].value + "认识，是" + infoAttr.matched['temp@school@pursue'].value + "，ta是" + (infoAttr.matched.personality.value).join('，') + "的人，想和ta一起在" + infoAttr.matched['temp@school@do'].value + "。"
    }

    return (<Layout bgcolor='#FDF9F5'>
        <div className="archivesCard">
            <div className="archivesCard-text">
                <Image src={ImageText}/>
            </div>
            <div className="archivesCard-go animate__animated animate__fadeInRight" onClick={() => go()}>
                <Image src={ImageGo}/>
            </div>
            <div className="archivesCard-editor" onClick={() => goHome()}>
                <Image src={ImageEditor}/>
            </div>
            <div className="archivesCard-content animate__animated animate__fadeInRight">
                <div className="archivesCard-content-logo">
                    <Image src={ImageLogo}/>
                </div>
                <div className="archivesCard-content-main">
                    <div className="archivesCard-content-main-left">
                        <div className="archivesCard-content-main-left-item">
                            <div className="archivesCard-content-main-left-item-lable">
                                姓名
                            </div>
                            <div className="archivesCard-content-main-left-item-text">
                                {infoUser.realname}
                            </div>
                        </div>
                        <div className="archivesCard-content-main-left-item">
                            <div className="archivesCard-content-main-left-item-lable">
                                性别
                            </div>
                            <div className="archivesCard-content-main-left-item-text">
                                {infoUser.sex === 1 ? '男' : '女'}
                            </div>
                        </div>
                        <div className="archivesCard-content-main-left-item">
                            <div className="archivesCard-content-main-left-item-lable">
                                生日
                            </div>
                            <div className="archivesCard-content-main-left-item-text">
                                {infoUser.birthday}-{birthDetail(infoUser.birthday).constellation}
                            </div>
                        </div>
                        <div className="archivesCard-content-main-left-item">
                            <div className="archivesCard-content-main-left-item-lable">
                                院校
                            </div>
                            <div className="archivesCard-content-main-left-item-text">
                                {infoAttr.base.college.value}-{infoAttr.base.college_year.value}
                            </div>
                        </div>
                        <div className="archivesCard-content-main-left-item">
                            <div className="archivesCard-content-main-left-item-lable">
                                喜欢
                            </div>
                            <div className="archivesCard-content-main-left-item-text">
                                {infoAttr && infoAttr.base.hobby.value ? ((typeof infoAttr.base.hobby.value) === "object" ? infoAttr.base.hobby.value.join("，") : infoAttr.base.hobby.value) : "你没选择兴趣爱好"}
                            </div>
                        </div>
                    </div>
                    <div className="archivesCard-content-main-right">
                        <div className="archivesCard-content-main-right-img">
                            <Image src={getImageSrc(infoUser.avatar, configData.static_url)}/>
                        </div>
                        <div className="archivesCard-content-main-right-label">
                            人物性格
                        </div>
                        {Object.keys(infoAttr).length > 0 && <div className="archivesCard-content-main-right-text">
                            【{infoAttr.base.personality.value}】
                        </div>}
                    </div>
                </div>
                {Object.keys(infoAttr).length > 0 && <div className="archivesCard-content-myself">
                    <div className="archivesCard-content-myself-item">
                        <div className="archivesCard-content-myself-item-label">
                            自我介绍
                        </div>
                        <div className="archivesCard-content-myself-item-text">
                            {renderIntro()}
                        </div>
                    </div>
                    <div className="archivesCard-content-myself-item">
                        <div className="archivesCard-content-myself-item-label">
                            愿望清单
                        </div>
                        <div className="archivesCard-content-myself-item-text">
                            {rendWish()}
                        </div>
                    </div>
                </div>}
            </div>
            <div className="archivesCard-btn animate__animated animate__fadeInUp" onClick={() => handleSubmit()}>
                <div className='archivesCard-btn-title'>
                    前往匹配
                </div>
                <div className="archivesCard-btn-subtitle">
                    ENROL
                </div>
            </div>
        </div>
    </Layout>)
}
export default ArchivesCard
