import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Image, Toast} from "antd-mobile";
import {CheckOutline} from "antd-mobile-icons";
import copy from 'copy-to-clipboard';
import url from "url";
import Layout from "@/layout";
import {useUser} from "@/hooks/useUser";
import {ApiMatchResult} from "@/api/group";
import ImageDeliveryPackage from "../assets/delivery-package.png";
import ImageLostPackage from "../assets/lost-package.png";
import ImageFeelingBottom from "../assets/feeling-bottom.png";
import IconHeart from "../assets/icon-heart.png";
import IconSpeaker from "../assets/icon-speaker.png";
import IconCopy from "../assets/icon-copy.png";
import "./index.less"

export default function Result() {
    const [matchWechat, setMatchWechat] = useState([])

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiMatchResult({group_id: query.group_id})
            setMatchWechat(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const copyWechat = (wechat) => {
        copy(wechat)
        Toast.show({
            icon: <CheckOutline/>, content: "复制成功", maskClickable: false, duration: 1000, afterClose: () => {
                window.$wx.miniProgram.navigateBack({delta: 1})
            },
        })
    }

    const handleClick = () => {
        window.$wx.miniProgram.navigateBack({delta: 1})
    }

    return (<Layout bgcolor="linear-gradient(180deg, #9E4FF8 0%, #B53EF0 100%)">
        <div className="shopping-result">
            <div className="shopping-result-box">
                <div className="shopping-result-box-pink"/>
                <div className="shopping-result-box-blue"/>
                <div className="shopping-result-box-card">
                    <div className="shopping-result-box-card-header">
                        <Image src={matchWechat.length > 0 ? ImageDeliveryPackage : ImageLostPackage}/>
                    </div>
                    {matchWechat.length > 0 && <div className="shopping-result-box-card-code">
                        <div className="shopping-result-box-card-code-icon">
                            <Image src={IconHeart}/>
                        </div>
                        <div className="shopping-result-box-card-code-label">
                            心动暗号：
                        </div>
                        <div className="shopping-result-box-card-code-text">
                            “心动即可行动”
                        </div>
                    </div>}
                    {matchWechat.length === 0 && <div className="shopping-result-box-card-code">
                        <div className="shopping-result-box-card-code-icon">
                            <Image src={IconHeart}/>
                        </div>
                        <div className="shopping-result-box-card-code-text">
                            不要灰心，Ta就在下次等你
                        </div>
                    </div>}
                    {matchWechat.length > 0 && <div className="shopping-result-box-card-contact">
                        {matchWechat.map((item, index) => (
                            <div key={index} className="shopping-result-box-card-contact-wechat">
                                <div className="shopping-result-box-card-contact-wechat-text">
                                    {item.wechat}
                                </div>
                                <div className="shopping-result-box-card-contact-wechat-btn"
                                     onClick={() => copyWechat(item.wechat)}
                                >
                                    <div className="wechat-btn-icon">
                                        <Image src={IconCopy}/>
                                    </div>
                                    <div className="wechat-btn-text">
                                        复制
                                    </div>
                                </div>
                            </div>))}
                        <div className="shopping-result-box-card-contact-tips">
                            <div className="shopping-result-box-card-contact-tips-icon">
                                <Image src={IconSpeaker}/>
                            </div>
                            <div className="shopping-result-box-card-contact-tips-text">
                                添加微信，友善的交流，是爱情的起始
                            </div>
                        </div>
                    </div>}
                    {matchWechat.length === 0 && <div className="shopping-result-box-card-contact">
                        <div className="shopping-result-box-card-contact-back">
                            <div className="shopping-result-box-card-contact-back-btn" onClick={() => handleClick()}>
                                看看其他活动
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="shopping-result-bottom">
                <Image src={ImageFeelingBottom}/>
            </div>
        </div>
    </Layout>)
}
