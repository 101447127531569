import CharacterFemale1 from "./assets/character-female-1.png"
import CharacterFemale2 from "./assets/character-female-2.png"
import CharacterFemale3 from "./assets/character-female-3.png"
import CharacterFemale4 from "./assets/character-female-4.png"
import CharacterFemale5 from "./assets/character-female-5.png"
import CharacterMale1 from "./assets/character-male-1.png"
import CharacterMale2 from "./assets/character-male-2.png"
import CharacterMale3 from "./assets/character-male-3.png"
import CharacterMale4 from "./assets/character-male-4.png"
import CharacterMale5 from "./assets/character-male-5.png"

export const options = {
    characters: {
        male: [{
            image: CharacterMale1, label: "阳光帅气"
        }, {
            image: CharacterMale2, label: "全能管家"
        }, {
            image: CharacterMale3, label: "外冷内热"
        }, {
            image: CharacterMale4, label: "暖男代表"
        }, {
            image: CharacterMale5, label: "幽默风趣"
        }], female: [{
            image: CharacterFemale1, label: "邻家清秀"
        }, {
            image: CharacterFemale2, label: "反差宅女"
        }, {
            image: CharacterFemale3, label: "精英女神"
        }, {
            image: CharacterFemale4, label: "聪慧少女"
        }, {
            image: CharacterFemale5, label: "甜美可爱"
        }]
    },

    ages: ["1980-1983", "1984-1986", "1987-1989", "1990-1993", "1994-1996", "1997-1999",],

    heights: [{
        label: "XXXS", value: "150-155cm"
    }, {
        label: "XXS", value: "155-160cm"
    }, {
        label: "XS", value: "160-165cm"
    }, {
        label: "S", value: "165-170cm"
    }, {
        label: "M", value: "170-175cm"
    }, {
        label: "L", value: "175-180cm"
    }, {
        label: "XL", value: "180-185cm"
    }, {
        label: "XXL", value: "185-190cm"
    }, {
        label: "XXXL", value: "190cm以上"
    },],
}

export const heightOptions = () => {
    const heights = []
    for (let i = 150; i < 220; i++) {
        heights.push({label: i.toString() + "cm", value: i})
    }
    return [heights]
}
