import React, {FC, useState} from "react";
import Dialog from "@/components/dialog";
import {updateInfo} from "@/model/user";
import ImageIntentionLogo from '../../assets/intention-logo.svg'
import ImageSeal from '../../assets/seal.svg'
import './index.less'

const Intention: FC = ({visible,close,callback}) => {
    const [success,setSuccess]=useState(false)
    const handleSubmit = (data) => {
        updateInfo(data, "base")
        if (data.hasOwnProperty("is_single") && data.is_single===1){
            setSuccess(true)
        }else{
            close()
            callback()
        }
        if (data.hasOwnProperty("accept_service")){
            close()
            callback()
        }
    }

    return (<Dialog display={visible} clickMask={() => close()}>
        {   !success&&
            <div className='intention'>
            <div className='intention-logo'>
                <img src={ImageIntentionLogo} alt=""/>
                <div className='intention-logo-bg'></div>
            </div>
            <div className='intention-content'>
                <div className='intention-content-title'>单身承诺函</div>
                <div className='intention-content-body'>本人在此做出单身承诺，现在婚姻状况未婚单身，如有不实愿意承担一切法律责任。

                </div>
                <div className='intention-content-seal'>
                    <img src={ImageSeal} alt=""/>
                </div>
            </div>
                <div className='intention-single'>
                    <button className='intention-single-btn intention-single-botton'>
                        <div className='intention-single-botton-text' onClick={() => handleSubmit({is_single:1})}>我是单身</div>
                    </button>
                    <button className='intention-single-btn intention-single-unbotton'>
                            <div className='intention-single-unbotton-text' onClick={() => handleSubmit({is_single:2})}>非单身</div>
                    </button>
                </div>

            <div className='intention-annotation'>
                注：选择按钮后即代表签署承诺。
            </div>
        </div>
        }
        { success&&
            <div className='intention'>
                <div className='intention-logo'>
                    <img src={ImageIntentionLogo} alt=""/>
                    <div className='intention-logo-bg'></div>

                </div>
                <div className='intention-content'>
                    <div className='intention-content-title'>服务意向调研</div>
                    <div className='intention-content-body'>您是否愿意接受线下婚恋红娘服务；如一对一推荐、情感咨询等
                    </div>

                </div>
                <div className='intention-single'>
                    <button className='intention-single-btn intention-single-botton'>
                        <div className='intention-single-botton-text' onClick={() => handleSubmit({accept_service:1})}>愿意</div>
                    </button>
                    <button className='intention-single-btn intention-single-unbotton'>
                        <div className='intention-single-unbotton-text' onClick={() => handleSubmit({accept_service:2})}>不愿意</div>
                    </button>
                </div>
            </div>
        }
    </Dialog>)
}
export default Intention
