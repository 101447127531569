import React, {useState, FC, useCallback, useEffect} from "react";
import {CascaderView} from 'antd-mobile'
import {ApiIndustries} from "@/api/school";
import {transferCascaderOptions, updateCascaderSelected} from "@/filters/modules/system";
import Dialog from "@/components/dialog";

import "./index.less"


const Job: FC = ({visible, callback, close}) => {
    const [options, setOptions] = useState([]);
    const [selects, setSelects] = useState([]);
    const [job, setJob] = useState([])

    const fetchData = async (pid = 0) => {
        const data = await ApiIndustries({parent_id: pid});
        if (data) {
            return transferCascaderOptions(data);
        }
        return []
    }

    const initData = useCallback(async () => {
        const data = await fetchData();
        setOptions(data)
    }, [])

    useEffect(() => {
        initData()
    }, [initData]);

    const handleChange = async (values, extend) => {
        const currentVal = values[values.length - 1]
        const currentItem = extend.items[extend.items.length - 1]
        let tempOptions = Array.from(options)
        if (currentItem.has_children) {
            if (currentItem.children.length === 0) {
                const childrenOptions = await fetchData(currentVal)
                updateCascaderSelected(tempOptions, currentVal, {children: childrenOptions})
            }
        } else {
            updateCascaderSelected(tempOptions, currentVal, {children: null})
        }
        const tempJob = []
        for (const pidKey in values) {
            tempJob.push(extend.items[pidKey].label)
        }
        setSelects(values)
        setJob(tempJob)
        setOptions(tempOptions);
    };
    const handleSubmit = () => {
        callback(job);
        close();
    }
    return (
        <Dialog display={visible} clickMask={() => close()}
                background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="job">
                <div className="job-item">
                    <div className="job-item-title">
                        你的职业
                    </div>
                    <div className="job-item-check">
                        {options.length > 0 && <CascaderView
                            visible={options.length > 0}
                            options={options}
                            value={selects}
                            cols={selects.length}
                            onChange={(val, extend) => handleChange(val, extend)}
                        />}
                    </div>
                    <div className="job-item-btn" onClick={handleSubmit}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Job;
