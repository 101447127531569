import React from "react"
import Dialog from "@/components/dialog"
import "./index.less"


export default function ChooseTips({visible, close, title, content}) {
    return (<Dialog display={visible} clickMask={() => close()}>
        <div className="status-check">
            <div className="status-check-title">
                {title}
            </div>

            <div className="status-check-content">
                {content}
            </div>

            <div
                className="status-check-button"
                onClick={() => window.$wx.miniProgram.navigateTo({
                    url: '/pages/home/index'
                })}
            >
                去首页
            </div>
            <div className="status-check-tips" onClick={() => close()}>
                稍后再说
            </div>
        </div>
    </Dialog>)
}
