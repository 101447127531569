import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import url from "url";
import Layout from "@/layout";
import {ApiDetail} from "@/api/group";
import {filterTime} from "@/filters/modules/system";
import {useUser} from "@/hooks/useUser";
import "./index.less"


export default function Status() {
    const [detail, setDetail] = useState({})

    const {search} = useLocation()
    const {query} = url.parse(search, true)
    const {isLogin} = useUser(true)

    const fetchData = useCallback(async () => {
        if (query.group_id && isLogin) {
            const data = await ApiDetail({group_id: query.group_id})
            setDetail(data)
        }
    }, [isLogin, query.group_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleWaiting = () => {
        window.$wx.closeWindow()
        window.$wx.miniProgram.navigateBack({delta: 1})
    }

    return (<Layout bgcolor="#441d81">
        <div className="spaceship-status">
            {Object.keys(detail).length > 0 && <div className="spaceship-status-card">
                <div className="spaceship-status-card-header">
                    你在经历了
                    <font className="spaceship-status-card-header-num">
                        {Object.keys(detail.selecteds).length}
                    </font>
                    个探索后，停止了前进
                </div>
                <div className="spaceship-status-card-station">
                    共 <font className="spaceship-status-card-station-num">{detail.info.num_join}</font> 人抵达此站
                </div>
                <div className="spaceship-status-card-reason">
                    <div>无论是处于什么原因</div>
                    <div>我们相信你做的所有决定都在跟随心走</div>
                    <div>接下来，请你耐心等待</div>
                </div>
                <div className="spaceship-status-card-bottom">
                    <div className="spaceship-status-card-bottom-label">
                        匹配结果揭晓时间：
                    </div>
                    <div className="spaceship-status-card-bottom-timing">
                        {filterTime(detail.info.match_time)}
                    </div>
                </div>
            </div>}
            <div className="spaceship-status-button">
                <div className="spaceship-status-button-begin" onClick={() => handleWaiting()}>
                    等待匹配中，点击返回
                </div>
            </div>
        </div>
    </Layout>)
}
