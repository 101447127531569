import React, {FC, useState} from "react";
import Dialog from "@/components/dialog";
import {PickerView,Image,Toast} from "antd-mobile";
import ImageWeekUp from '../assets/time-weekup.png'
import ImageTitle from '../assets/time-title.png'
import './index.less'

const Time:FC=({visible, callback})=>{
    const [value, setValue] = useState(['08', '00']);

    const generateTimeOptions = (maxValue, labelSuffix) => {
        const options = [];
        for (let i = 0; i <= maxValue; i++) {
            const label = `${i < 10 ? '0' + i : i}${labelSuffix}`;
            const value = i < 10 ? '0' + i : String(i);
            options.push({ label, value });
        }
        return options;
    };

    const basicColumns = [
        generateTimeOptions(23, '时'),
        generateTimeOptions(59, '分'),
    ];

    const handleConfirm = () => {
        if (value === null) {
            Toast.show({content: '请选择起床时间',duration: 1000,})
        }  else {
            callback(value);
        }
    };

    return( <Dialog display={visible} background={"linear-gradient(143deg, #FFB6BD -1%, #B2EBBD 43%, #A8C0F0 93%)"}>
        <div className="time">
            <div className="time-item">
                <div className="time-item-title">
                    <Image src={ImageTitle}></Image>
                </div>
                <PickerView
                    style={{'--height':'10rem'}}
                    columns={basicColumns}
                    visible={visible}
                    value={value}
                        onChange={(val, extend) => {
                            setValue(val)
                    }}
                />
                <div className="time-item-btn" onClick={()=>handleConfirm()}>
                    <Image src={ImageWeekUp} alt=""/>
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Time
