import React, {FC, useState} from "react";
import {Image} from 'antd-mobile'
import Dialog from "@/components/dialog";
import {options} from "../../../options";
import ImageWorkTitle from '../assets/workstate-title.png'
import "./index.less";

const WorkState: FC = ({visible, callback}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const maxSelectableOptions = 1;

    const handleOptionClick = (index) => {
        if (selectedOptions.includes(index)) {
            setSelectedOptions((prevSelected) =>
                prevSelected.filter((item) => item !== index)
            );
        } else {
            let tempSelect = [...selectedOptions];
            if (tempSelect.length < maxSelectableOptions) {
                tempSelect = [...tempSelect, index];
                setSelectedOptions(tempSelect);
                if (tempSelect.length >= maxSelectableOptions) {
                    callback(tempSelect);
                }
            }
        }
    };


    const isOptionSelected = (index) => {
        return selectedOptions.includes(index);
    };

    return (<Dialog
        display={visible}
        background={"linear-gradient(143deg, #FFB6BD -1%, #B2EBBD 43%, #A8C0F0 93%)"}
    >
        <div className="workstate">
            <div className="workstate-item">
                <div className="workstate-item-title">
                    <Image src={ImageWorkTitle}></Image>
                </div>
                <div className="workstate-item-check">
                    {options.state.map((item, index) => (<div
                        key={index}
                        className={`workstate-item-check-state ${isOptionSelected(index) ? "selected" : ""}`}
                        onClick={() => handleOptionClick(index)}
                    >
                        <Image src={item.image}/>
                    </div>))}
                </div>
            </div>
        </div>
    </Dialog>);
};

export default WorkState;
