import React, {useState, FC} from "react";
import Dialog from "@/components/dialog";
import {PickerView} from 'antd-mobile'
import "./index.less"

const Emotion: FC = ({visible, callback, close}) => {
    const [checkVisible, setCheckVisible] = useState(false)
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedArray, setSelectedArray] = useState([]);
    const handleClose = () => {
        close()
    }
    const basicColumns = [[{label: '单身', value: '单身'}, {label: '离异', value: '离异'}, {
        label: '丧偶', value: '丧偶'
    },],

    ]
    const handlePickerChange = (value) => {
        setSelectedValue(value.join(', ')); //将选中的数组变成字符串
        setSelectedArray(value); //保留选中的数组
    };

    const handleEmotion = () => {
        callback(selectedValue);
        close();
    };

    return (<Dialog display={visible} clickMask={() => handleClose()}
                    background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
        <div className="emotion">
            <div className="emotion-item">
                <div className="emotion-item-title">
                    你的性格
                </div>
                <div className="emotion-item-check">
                    <PickerView
                        columns={basicColumns}
                        visible={checkVisible}
                        value={selectedArray}
                        onChange={handlePickerChange}
                        onClose={() => {
                            setCheckVisible(false)
                        }}
                    />
                </div>
                <div className="emotion-item-btn" onClick={() => handleEmotion()}>
                    确定
                </div>
            </div>
        </div>
    </Dialog>)
}
export default Emotion
