import React,{useState,useCallback,FC} from "react";
import {DatePickerView} from 'antd-mobile'
import Dialog from "@/components/dialog";
import "./index.less"
import {formatDate, getDateObject} from "@/filters/modules/system";

const Birthday:FC=({visible, callback, close,sendBirthday})=>{

    const handleClose=()=>{
        close()
    }
    const [value, setValue] = useState('')
    const [birthdayVisible, setBirthdayVisible] = useState(false)
    const labelRenderer = useCallback((type, data) => {
        switch (type) {
            case 'year':
                return data + '年'
            case 'month':
                return data + '月'
            case 'day':
                return data + '日'
            default:
                return data
        }
    }, [])
      const handleBirthday=()=>{
        sendBirthday(formatDate(value))
        close()
    }

    return(
        <Dialog display={visible} clickMask={() => handleClose()} background={"linear-gradient(143deg, #FFB6E7 -1%, #FFC6AC 43%, #B2FFE7 93%)"}>
            <div className="birthday">
                <div className="birthday-item">
                    <div className="birthday-item-title">
                        你的生日
                    </div>
                        <DatePickerView
                            title='时间选择'
                            visible={birthdayVisible}
                            onClose={() => {
                                setBirthdayVisible(false)
                            }}
                            value={value}
                            min={getDateObject("1970-01-01")}
                            max={new Date()}
                            renderLabel={labelRenderer}
                            onChange={(val)=>setValue(val)}/>

                    <div className="birthday-item-btn" onClick={()=>handleBirthday()}>
                        确定
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Birthday
