import React, {FC} from 'react'
import Layout from "@/layout"
import {useUser} from "@/hooks/useUser"
import store from "@/store/instance"
import ImageResultSuccess from "../assets/result-succes.png"
import ImageResultVip from "../assets/result-vip.png"
import './index.less'

const Page: FC = () => {
    const {infoUser} = useUser()
    const locationInfo = store.getState().app.locationInfo

    return (<Layout bgcolor="linear-gradient(180deg, #FF3737 0%, #FF991E 100%)">
        <div className="page-offlineVip-result">
            <div className="page-offlineVip-result-box">
                <img src={ImageResultSuccess} alt=''/>
            </div>
            {Object.keys(infoUser).length > 0 && <div className="page-offlineVip-result-card">
                <div className="page-offlineVip-result-card-header">
                    会员ID：{infoUser.nickname}
                </div>
                <div className="page-offlineVip-result-card-content">
                    <div className="page-offlineVip-result-card-content-tips">
                        <div className="page-offlineVip-result-card-content-tips-label">
                            您已获得权益：
                        </div>
                        <div className="page-offlineVip-result-card-content-tips-text">
                            免费一次情感咨询服务
                        </div>
                        <div className="page-offlineVip-result-card-content-tips-text">
                            免费一次婚恋红娘一对一服务
                        </div>
                    </div>
                    <div className="page-offlineVip-result-card-content-icon">
                        <img src={ImageResultVip} alt=''/>
                    </div>
                </div>
                <div className="page-offlineVip-result-card-icon">
                    <div className="page-offlineVip-result-card-icon-one"/>
                    <div className="page-offlineVip-result-card-icon-two"/>
                    <div className="page-offlineVip-result-card-icon-three"/>
                </div>
            </div>}

            <div className="page-offlineVip-result-tips">
                <div className="page-offlineVip-result-tips-card">
                    <div className="page-offlineVip-result-tips-card-label">
                        线下VIP卡的使用方法
                    </div>
                    <div className="page-offlineVip-result-tips-card-list">
                        <div className="page-offlineVip-result-tips-card-list-text">
                            领取VIP卡后，工作人员会在３－５个工作日内和您取得联系。
                        </div>
                        {
                            (Object.keys(locationInfo).length > 0 && locationInfo.city.indexOf("成都") === 0) &&
                            <div className="page-offlineVip-result-tips-card-list-address">
                                <div className="page-offlineVip-result-tips-card-list-address-label">
                                    服务门店：
                                </div>
                                <div className="page-offlineVip-result-tips-card-list-address-text">
                                    成都市锦江区新光华街7号航天科技大厦1807A
                                </div>
                                <div className="page-offlineVip-result-tips-card-list-address-text">
                                    爱呗
                                </div>
                            </div>
                        }
                        {
                            (Object.keys(locationInfo).length > 0 && locationInfo.city.indexOf("成都") === 0) &&
                            <div className="page-offlineVip-result-tips-card-list-tel">
                                <div className="page-offlineVip-result-tips-card-list-tel-label">
                                    联系电话：
                                </div>
                                <div className="page-offlineVip-result-tips-card-list-tel-text">
                                    028-60132613
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <button
                className="page-offlineVip-result-home"
                onClick={() => window.$wx.miniProgram.switchTab({url: "/pages/home/index"})}
            >
                {"我知道了，去首页转转 >>"}
            </button>
        </div>
    </Layout>)
}

export default Page
