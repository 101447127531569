import React, {FC, useCallback, useEffect, useState} from 'react'
import Layout from "@/layout"
import Avatar from "@/components/avatar"
import {ApiSpecialResult} from "@/api/activity"
import {birthDetail, getAge} from "@/filters/modules/system"
import {useUser} from "@/hooks/useUser"
import {generateSessionKey} from "@/plugin/easemob/Func"
import Intention from "@/pages/mystery/result/model";
import ImageMysteryResult from "../assets/mystery-result.png"
import IconTalk from "../assets/talk.png"
import './index.less'

const Page: FC = () => {
    const [matched, setMatched] = useState({})
    const {infoUser} = useUser()
    const [intentionVisible, setIntentionVisible] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await ApiSpecialResult({origin_code: "mystery"})
        setMatched(data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const getLocationString = (message) => {
        if (!message.city && !message.province && !message.country) {
            return "心动星球"
        }
        if (!message.city && !message.province && message.country) {
            return message.country
        }
        if (!message.city && message.province && message.country) {
            return message.country + '·' + message.province
        }
        if (message.city && message.province) {
            return message.province + '·' + message.city
        }
    }

    const checkConfirm = () => {
        const age = getAge(infoUser.birthday);
        if (age > 23) {
            setIntentionVisible(true)
        } else {
            goToChat()
        }
    }

    const goToChat = () => {
        if (infoUser.uid && matched.uid) {
            window.$wx.miniProgram.navigateTo({url: "/pages/chat/index?targetId=" + matched.uid + "&sessionKey=" + generateSessionKey(infoUser.uid, matched.uid)})
        }
    }

    return (<Layout bgcolor="linear-gradient(180deg, #FF94E1 0%, #FFCD8F 100%)">
        <div className="page-mystery-result">
            <div className="page-mystery-result-box">
                <img src={ImageMysteryResult} alt=''/>
            </div>
            {Object.keys(matched).length > 0 && <div className="page-mystery-result-card">
                <div className="page-mystery-result-card-user">
                    <Avatar avatar={matched.avatar} uid={matched.uid} size={56} isVip={matched.is_vip > 0}/>
                    <div className="page-mystery-result-card-user-info">
                        <div className="page-mystery-result-card-user-info-nickname">
                            {matched.nickname}
                        </div>
                        <div className="page-mystery-result-card-user-info-detail">
                            <div className="page-mystery-result-card-user-info-detail-age">
                                {birthDetail(matched.birthday).age}
                            </div>
                            <div className="page-mystery-result-card-user-info-detail-location">
                                {getLocationString(matched)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-mystery-result-card-mask">
                    <div className="page-mystery-result-card-mask-triangle"/>
                    <div className="page-mystery-result-card-mask-content">
                        已为你们创建聊天窗口，快去聊天吧
                    </div>
                </div>
            </div>}
            {Object.keys(matched).length > 0 &&
                <button className="page-mystery-result-button" onClick={() => checkConfirm()}>
                    <div className="page-mystery-result-button-background"/>
                    <div className="page-mystery-result-button-main">
                        <div className="page-mystery-result-button-main-content text-shake">
                            <div className="page-mystery-result-button-main-content-icon">
                                <img src={IconTalk} alt=""/>
                            </div>
                            <div className="page-mystery-result-button-main-content-text">
                                前去聊聊
                            </div>
                        </div>
                    </div>
                </button>}
            <button
                className="page-mystery-result-home"
                onClick={() => window.$wx.miniProgram.switchTab({url: "/pages/home/index"})}
            >
                {"去首页转转，发现更多心动可能 >>"}
            </button>
        </div>
        <Intention visible={intentionVisible} close={() => setIntentionVisible(false)} callback={() => goToChat()}/>
    </Layout>)
}

export default Page
